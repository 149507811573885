import React, { useState, useEffect } from 'react';
import DatePicker from './DatePicker';

const ApprovedModal = () => {
  // State variables
  const [invoiceAmt, setInvoiceAmt] = useState(61740.0); // Example initial invoice amount
  const [paidAmt, setPaidAmt] = useState(0.0); // Initially paid amount
  const [balanceAmt, setBalanceAmt] = useState(61740.0); // Initial balance
  const [amountToPay, setAmountToPay] = useState(balanceAmt); // User input

  // Update the `amountToPay` with the current balanceAmt when the component mounts
  useEffect(() => {
    setAmountToPay(balanceAmt);
  }, [balanceAmt]);

  // Handle user input for amount to be paid
  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setAmountToPay(value);
  };

  // Handle payment logic
  const handlePay = () => {
    const newPaidAmt = paidAmt + parseFloat(amountToPay || 0);
    const newBalanceAmt = invoiceAmt - newPaidAmt;

    if (newBalanceAmt >= 0) {
      setPaidAmt(newPaidAmt);
      setBalanceAmt(newBalanceAmt);
      setAmountToPay(''); // Clear input after payment
    } else {
      alert('Amount exceeds the balance!');
    }
  };

  return (
    <div>
      <div
        className="modal fade pcdModal producSecMAin cusmodl"
        id="approvedModal"
        tabIndex={-1}
        aria-labelledby="approvedModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body pb-0">
              <DatePicker />
              <div className="border-top border-bottom py-10">
                <div className="d-flex justify-content-between align-items-center font-14 text-neutral-400">
                  <span className="text">Invoice Amt.</span>
                  <span className="amount">₹{invoiceAmt.toFixed(2)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center font-14 text-neutral-400">
                  <span className="text">Paid Amt.</span>
                  <span className="amount">- ₹{paidAmt.toFixed(2)}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center font-14 fw-bold text-neutral-900 py-10">
                <span className="text">Balance Amt.</span>
                <span className="amount">₹{balanceAmt.toFixed(2)}</span>
              </div>
              <div className="enteramont">
                <label className="form-label">Enter Amt. to be paid</label>
                <div className="icon-field has-validation">
                  <span className="icon">
                    <iconify-icon
                      icon="material-symbols:currency-rupee-rounded"
                      width="20"
                      height="20"
                    ></iconify-icon>
                  </span>
                  <input
                    type="text"
                    className="form-control text-end"
                    placeholder="Enter Amt."
                    value={amountToPay}
                    onChange={handleAmountChange}
                    required
                  />
                  <div className="invalid-feedback">Please provide an amount.</div>
                </div>
              </div>
              <div className="py-10 position-sticky bottom-0 z-2">
                <button
                  type="button" data-bs-dismiss="modal"
                  className="btn btn-primary-600 radius-8 px-20 py-16 w-100"
                  onClick={handlePay}
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedModal;
