import React, { useRef, useEffect, useState } from 'react';
import DeliveriesDetails from './DeliveriesDetails';
import HSNSearchDropdown from './HSNSearchDropdown';
import config from "src/config";
import { Link } from 'react-router-dom';
import { postRequest } from 'src/utils/RequestUtil';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import FormikSelect from 'src/components/FormikSelect';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextarea from 'src/components/formik/FormikTextarea';
import DatePicker from 'react-flatpickr';
import { costFormat, utilMonthOptions } from 'src/utils/CommonUtils';
import { toast } from 'react-toastify';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate hook
import { clientInvoiceSearchAction } from './actions/ClientInvoiceActions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import GstStateList from 'src/utils/GstStateList';
import Select from 'react-select';
// Custom Formik Select component
const CustomFormikSelect = ({ items, name, placeholder, onChange, ...props }) => {
    const CustomSelect = ({ field, form }) => {
        const handleChange = (selectedOption) => {
            const value = selectedOption ? selectedOption.value : null;
            form.setFieldValue(field.name, value);
            if (onChange) {
                onChange(selectedOption, form.setFieldValue);
            }
        };

        return (
            <Select
                options={items}
                name={name}
                placeholder={placeholder}
                value={items.find(option => option.value === field.value) || null}
                onChange={handleChange}
                isSearchable // Enable search
                {...props}
            />
        );
    };

    return <Field name={name} component={CustomSelect} />;
};

const CreateInvoice = () => {
    const navigate = useNavigate(); // Initialize the navigate function
    const { invoiceId, d } = useParams();


    const location = useLocation();

    const isDuplicateEdit = location.pathname.endsWith('/d'); // Check if `/d` exists

    const dispatch = useDispatch();

    const {
        search,
    } = useSelector((state) => state.ClientInvoiceReducer, shallowEqual);

    const clientInvoiceSearch = () => {
        const payload = {
            where,
            autopopulate: true,
            fullTextSearch: true,
        }
        dispatch(clientInvoiceSearchAction(payload));
    }

    const [where, setWhere] = useState({
        _id: invoiceId
    });
    useEffect(() => {
        if (invoiceId !== undefined) {
            clientInvoiceSearch();
        } else {
            setWhere({});
        }
    }, [invoiceId])

    const organizationTypeOptions = [
        { label: 'Government', value: 'Government' },
        { label: 'Non-Government', value: 'Non-Government' },
    ];

    const selectDaysOptions = [
        { label: 5, value: 5 },
        { label: 7, value: 7 },
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 30, value: 30 },
        { label: 45, value: 45 },
        { label: 60, value: 60 },
        { label: 90, value: 90 },
    ];

    const [uomData, setUomData] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [sacData, setSacData] = useState([]);
    const [currencyData, setCurrency] = useState([]);

    const [deliveries, setDeliveries] = useState([]);
    const [deliveryKey, setDeliveryKey] = useState(null);
    const indianCurrency = '₹'
    const [selectedCurrency, setSelectedCurrency] = useState(indianCurrency);

    const fetchData = async () => {
        const url2 = `${config.baseUrl}clientInvoice/staticData`;
        try {
            const static_data = await postRequest({
                url: url2,
                // data: { "yearmonth": yearmonth },  // Adjust this as per your data structure
            });
            // console.log("log data", static_data.data.data)
            setUomData(static_data?.data?.data?.uom)
            setProjectData(static_data?.data?.data?.projects)
            setSacData(static_data?.data?.data?.sac)
            setCurrency(static_data?.data?.data?.currency)
        } catch (error) {
            console.error("Error in fetching data", error);
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const currencies = currencyData.map(item => ({
        name: item.currencyTitle,
        symbol: item.symbol
    }));

    const sacOptions = sacData.map(item => ({
        code: String(item.SAC_Code),
        description: item.description,
        id: item._id
    }));

    const projectOptions = projectData
        ?.sort((a, b) => a.projectCode.localeCompare(b.projectCode))
        ?.map(item => ({
            label: item.projectCode,
            value: item.projectId
        }));

    const uomOptions = uomData.map(item => ({
        label: item.uomTitle,
        value: item.uomTitle
    }));

    const currentYear = new Date().getFullYear();
    const startYear = 2019;

    // Generate the array of objects
    const yearOptions = Array.from(
        { length: currentYear - startYear + 1 },
        (_, i) => {
            const year = currentYear - i;
            return { label: year, value: year };
        }
    );

    // Function to add a new payment item
    const addItem = () => {
        setInitialValues((prevState) => ({
            ...prevState,
            deliveryItems: [...prevState.deliveryItems, { id: Date.now(), amount: '', deliveredYear: '', deliveredMonth: '' }],
        }));
    };

    // Function to remove a payment item by id
    const removeItem = (id) => {
        setInitialValues((prevState) => ({
            ...prevState,
            deliveryItems: prevState.deliveryItems.filter((item) => item.id !== id),
        }));
    };

    // Function to handle amount change in a specific item
    const handleAmountChange = (id, value) => {
        setInitialValues((prevState) => ({
            ...prevState,
            deliveryItems: prevState.deliveryItems.map((item) =>
                item.id === id ? { ...item, amount: value } : item
            ),
        }));
    };

    const handleInvoiceDeliveriesUpdate = (addedDeliveryDetails) => {
        const totalCost = addedDeliveryDetails?.subItems.reduce(
            (total, item) =>
                total + (item?.categories?.unitCost || 0) * (Number(item?.deliveredInvoice) || 0),
            0
        );

        setInitialValues((prevState) => ({
            ...prevState,
            deliveryItems: prevState.deliveryItems.map((item) =>
                item.id === deliveryKey
                    ? { ...item, amount: totalCost, subItems: addedDeliveryDetails?.subItems }
                    : item
            ),
        }));
        setDeliveryKey(null)
    };

    const initialItem = { description: '', uom: '', sacCode: '', quantity: 1, price: 0, gstRate: 0 }
    const defaultInitialValues = {
        _id: undefined,
        projectId: '',
        organizationType: '',
        clientName: '',
        invoiceTitle: '',
        serialNo: '',
        clientGstVat: '',
        // stateCode: '',
        invoiceDate: '',
        selectDays: '',
        currency: indianCurrency,
        dueDate: '',
        clientAddress: '',
        invoiceItems: [
            initialItem,
        ],
        deliveryItems: [{ id: Date.now(), amount: '', deliveredYear: '', deliveredMonth: '' }],
        showBankDetails: "yes",
    };


    const [initialValues, setInitialValues] = useState(defaultInitialValues);

    useEffect(() => {
        if (invoiceId === undefined) {
            setInitialValues(defaultInitialValues);
        } else if (Array.isArray(search?.data?.data) && search?.data?.data.length > 0) {
            const invoice = search?.data?.data[0]
            invoice.invoiceItems = invoice.invoiceItems.map(item => {
                return {
                    ...item,
                    price: String(item.price.$numberDecimal), // Overwrite with numeric value
                };
            });

            if (isDuplicateEdit) {
                invoice._id = undefined
                invoice.status = "Pending"
            }

            // if (isDuplicateEdit) {
            //     invoice.deliveryItems = [{ id: Date.now(), amount: '', deliveredYear: '', deliveredMonth: '' }]

            // } else {
            //     invoice.deliveryItems = invoice?.deliveryItems?.length ? invoice.deliveryItems : [{ id: Date.now(), amount: '', deliveredYear: '', deliveredMonth: '' }]
            // }

            invoice.deliveryItems = isDuplicateEdit
                ? [{ id: Date.now(), amount: '', deliveredYear: '', deliveredMonth: '' }]
                : invoice?.deliveryItems?.length
                    ? invoice.deliveryItems
                    : [{ id: Date.now(), amount: '', deliveredYear: '', deliveredMonth: '' }];

            setInitialValues(invoice);
            setSelectedCurrency(invoice.currency)
        } else {
            setInitialValues(defaultInitialValues); // Or set a default value if needed
        }
    }, [search?.data]);

    const validationSchema = Yup.object({
        projectId: Yup.string().required('Project is required'),
        organizationType: Yup.string().required('Organization type is required'),
        clientName: Yup.string().required('Client name is required'),
        invoiceTitle: Yup.string().required('Invoice title is required'),
        // serialNo: Yup.string().required('Serial No is required'),
        // clientGstVat: Yup.string().matches(
        //     /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
        //     'Invalid GST format'
        // ).required('Client GST is required'),
        // invoiceDate: Yup.date().required('Invoice date is required'),
        // dueDate: Yup.date().required('Due date is required'),
        selectDays: Yup.string().required('Select Days is required'),
        clientAddress: Yup.string().required('Client address is required'),
        // currency: Yup.string().required('Currency is required'),

        invoiceItems: Yup.array().of(
            Yup.object({
                description: Yup.string().required('Required'),
                uom: Yup.string().required('Required'),
                quantity: Yup.number().min(1, 'Must be at least 1').required('Required'),
                price: Yup.number().min(0.001, 'Price must be greater than 0').required('Required'),
                gstRate: Yup.number()
                    .min(0, 'Must be non-negative')
                    .max(100, 'Must be at most 100')
                    .test(
                        'is-required-if-inr',
                        'GST Rate is required for INR',
                        function (value) {
                            const { parent } = this; // Accessing the current object
                            return selectedCurrency === indianCurrency ? value !== undefined && value !== null && value !== '' : true;
                        }
                    ),
            })
        ),
        showBankDetails: Yup.string().required("Please select a bank details option"),
    });


    const handleSubmit = async (values, { setFieldValue }) => {
        // Log the form data after updating the values
        values.currency = selectedCurrency;
        const matchedCost = currencyData.filter(item => item.symbol === selectedCurrency)
            .map(item => item.costINR);

        //store value in inr 
        values.totalCostInr =
            (selectedCurrency !== indianCurrency) ?
                (matchedCost.length > 0 ? values.grandTotal * parseFloat(matchedCost[0]["$numberDecimal"]) : values.grandTotal)
                : values.grandTotal;
        ;

        // console.log('Form data:', values);

        const save_url = `${config.baseUrl}clientInvoice/save`;
        try {
            const result2 = await postRequest({
                url: save_url,
                data: values,
            });
            // console.log("log data", result2.data.data)
            toast.success(result2.data.message);
            navigate('/invoice/pending');
        } catch (error) {
            toast.error('Failed to submit form.');
        }
    };

    // Add Days Helper
    function addDaysToDate(dateString, daysToAdd) {
        if (!dateString) {
            return false; // Empty or null/undefined string
        }
        const date = new Date(dateString); // Attempt to parse the string new Date(date).toISOString()
        if (!isNaN(date.getTime())) {
            const date = new Date(dateString); // Parse the given ISO string
            date.setDate(date.getDate() + Number(daysToAdd) + Number((invoiceId === undefined) ? 1 : 0)); // Add the days
            return date.toISOString(); // Return the updated date as an ISO string
        }
    }
    const handleProject = (_pid, setFieldValue) => {
        const projectItem = projectData.find((project) => project.projectId === _pid);
        setFieldValue("clientName", projectItem?.ClientRegisteredName || "");
        setFieldValue("clientGstVat", projectItem?.ClientGST || "");
        setFieldValue("clientAddress", projectItem?.ClientAddress || "");
    }

    const handleBackClick = () => {
        navigate(-1);  // -1 goes back one step in the browser history (like the browser back button)
    }


    const getdeliveries = async (key, itemId, projectId, year, month, addedDeliveryItems, values) => {
        setDeliveryKey(itemId);
        setInitialValues(values)
        console.log("addedDeliveryItems 00 ", addedDeliveryItems?.subItems);

        const url2 = `${config.baseUrl}clientPaymentDetails/findDeliveries`;

        try {
            const static_data = await postRequest({
                url: url2,
                data: { key, projectId, year, month },
            });
            const delevery = static_data?.data?.data;
            console.log("addedDeliveryItems 01 ", delevery);
            const updateDelveryDetails2 = delevery.map((item) => {
                const matchingSubItem = addedDeliveryItems?.subItems?.find(
                    (subItem) => subItem.id === item.id
                );
                if (matchingSubItem && matchingSubItem.hasOwnProperty("deliveredInvoice")) {
                    return {
                        ...item,
                        deliveredInvoice: Number(matchingSubItem.deliveredInvoice),
                    };
                }
                return item;
            });
            setDeliveries(updateDelveryDetails2);
        } catch (error) {
            console.error("Error in fetching data", error);
        }
    }


    return (
        <>
            <div className="card h-100 p-0 radius-12 creatdivner001">
                <div className="card-body">
                    <div class="d-flex justify-content-between align-items-center border-bottom  pb-16 mb-16">
                        <div class="">
                            <h2 class="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                                <button onClick={handleBackClick}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button>
                                {invoiceId === undefined ? "Create" : isDuplicateEdit ? "Duplicate" : "Edit"} Invoice</h2>
                            <ul class="d-flex align-items-center gap-2">

                                <li class="fw-medium"><a href="/" class="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Special Access</a></li>
                                <li>-</li>
                                <li class="fw-medium"><a href="/" class="d-flex align-items-center gap-1 hover-text-primary">Accounts</a></li>
                                <li>-</li>
                                <li class="fw-medium">{invoiceId === undefined ? "Create" : "Edit"} Invoice</li>

                            </ul>
                        </div>
                        <Link to="/invoice/pending" className="btn btn-outline-primary-600 radius-8 px-20 py-9 gap-2">
                            View Pending
                        </Link>
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => {
                            const { subTotal, totalSumGST, sumGrandTotal } = values.invoiceItems.reduce(
                                (acc, item) => {
                                    // Calculate total amount
                                    const totalAmount = Number(((item.price || 0) * (item.quantity || 0)).toFixed(2));

                                    // Calculate GST value
                                    if (selectedCurrency === indianCurrency) {
                                        const gstValue = Number((totalAmount * (item.gstRate || 0) / 100).toFixed(2));
                                        acc.totalSumGST += gstValue; // Add GST value to totalSumGST
                                        acc.sumGrandTotal += totalAmount + gstValue; // Add grand total to sumGrandTotal
                                    } else {
                                        acc.sumGrandTotal += totalAmount; // Add grand total to sumGrandTotal
                                    }

                                    // Accumulate totalSumGST and sumGrandTotal
                                    acc.subTotal += totalAmount; // Add subtotal

                                    return acc; // Return the accumulator object with updated values
                                },
                                {
                                    subTotal: 0, // Initialize totalSumGST to 0
                                    totalSumGST: 0, // Initialize totalSumGST to 0
                                    sumGrandTotal: 0, // Initialize sumGrandTotal to 0
                                }
                            );

                            values.subTotal = subTotal
                            values.grandTotal = sumGrandTotal
                            const resultDate = addDaysToDate(values?.invoiceDate, Number(values?.selectDays));
                            values.dueDate = resultDate
                            console.log("teste projectId", values);

                            return (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="mb-20">
                                                        <label for="country" className="form-label fw-semibold text-primary-light text-sm mb-8">Select Project <span className="text-danger-600">*</span> </label>
                                                        {/* <FormikSelect
                                                            items={projectOptions}
                                                            name="projectId"
                                                            id="projectId"
                                                            className="form-control radius-8 form-select"
                                                            placeholder="Select Project"
                                                            onChange={(param) => {
                                                                handleProject(param?.value || param, setFieldValue)
                                                            }}
                                                        /> */}
                                                        <CustomFormikSelect
                                                            items={projectOptions}
                                                            name="projectId"
                                                            placeholder="Search and Select a Project"
                                                            onChange={(param) => {
                                                                handleProject(param?.value || param, setFieldValue)
                                                                // console.log("Selected:", selectedOption);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-20 fontset001">
                                                        <label for="city" className="form-label fw-semibold text-primary-light text-sm mb-8">Organization Type <span className="text-danger-600">*</span> </label>
                                                        <FormikSelect
                                                            items={organizationTypeOptions}
                                                            name="organizationType"
                                                            id="organizationType"
                                                            className="form-control radius-8 form-select"
                                                            placeholder="Select Organization Type" 
                                                        />  

                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="mb-20 fontset001">
                                                        <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Client Name <span className="text-danger-600">*</span></label>
                                                        <FormikInput
                                                            type="text"
                                                            name="clientName"
                                                            id="clientName"
                                                            className="form-control radius-8"
                                                            placeholder="Enter Client Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="mb-20 fontset001">
                                                        <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Invoice Title <span className="text-danger-600">*</span></label>
                                                        <FormikInput
                                                            type="text"
                                                            name="invoiceTitle"
                                                            id="invoiceTitle"
                                                            className="form-control radius-8"
                                                            placeholder="Enter Invoice Title"
                                                        />
                                                    </div>
                                                </div>
                                                {initialValues?.clientInvoiceNo && !isDuplicateEdit &&
                                                    <div className="col-sm-6">
                                                        <div className="mb-20 fontset001">
                                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Client Invoice No  <span className="text-danger-600">*</span></label>
                                                            <input type="text" disabled className="form-control radius-8" placeholder="Client Invoice No" value={initialValues?.clientInvoiceNo} />
                                                        </div>
                                                    </div>
                                                }
                                                <div className={`col-sm-${selectedCurrency === indianCurrency ? 8 : 6}`}>
                                                    <div className="mb-20 fontset001">
                                                        <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Client {selectedCurrency === indianCurrency ? 'GST' : 'VAT'} </label>
                                                        <FormikInput
                                                            type="text"
                                                            name="clientGstVat"
                                                            id="clientGstVat"
                                                            className="form-control radius-8"
                                                            placeholder="22AAAAA0000A1Z5"
                                                        />
                                                    </div>
                                                </div>
                                                {/* {selectedCurrency === indianCurrency && (
                                                    <div className="col-sm-4">
                                                        <div className="mb-20 fontset001">
                                                            <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">State Code</label>
                                                            <FormikSelect
                                                                items={GstStateList()}
                                                                name="stateCode"
                                                                id="stateCode"
                                                                className="form-control radius-8 form-select"
                                                                placeholder="State Code"
                                                            />
                                                        </div>
                                                    </div>
                                                )} */}

                                                <div className="col-sm-4">
                                                    <div className="mb-20 fontset001">
                                                        <label htmlFor="invoiceDate" className="form-label">
                                                            Invoice Date <span className="text-danger-600">*</span>
                                                        </label>
                                                        <div className="position-relative">
                                                            <DatePicker
                                                                id="invoiceDate"
                                                                name="invoiceDate"
                                                                type="date"
                                                                placeholder="MM/DD/YYYY"
                                                                className="form-control radius-8 bg-base"
                                                                value={initialValues?.invoiceDate}
                                                                options={{
                                                                    dateFormat: "m/d/Y", // Flatpickr format for MM/DD/YYYY
                                                                    timezone: "local"
                                                                }}
                                                                onChange={(date) => setFieldValue('invoiceDate', date)}
                                                                selected={values.invoiceDate ? new Date(values.invoiceDate) : null} // Ensure a valid Date object
                                                            />

                                                            <span
                                                                className="position-absolute end-0 top-50 translate-middle-y me-12"
                                                                onClick={() => document.getElementById('invoiceDate').focus()} // Focus input on icon click
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="mb-20 fontset001">
                                                        <label for="text" className="form-label fw-semibold text-primary-light text-sm mb-8">Select Days <span className="text-danger-600">*</span></label>
                                                        <FormikSelect
                                                            items={selectDaysOptions}
                                                            name="selectDays"
                                                            id="selectDays"
                                                            className="form-control radius-8 form-select"
                                                            placeholder="Select Days"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Due Date */}
                                                <div className="col-sm-4">
                                                    <div className="mb-20 fontset001">
                                                        <label htmlFor="dueDate" className="form-label">
                                                            Due Date <span className="text-danger-600">*</span>
                                                        </label>
                                                        <div className="position-relative">
                                                            {values?.dueDate && typeof values.dueDate === 'string' &&
                                                                <span>{values.dueDate.split("T")[0].split("-").reverse().join("/").replace(/^(\d{2})\/(\d{2})\/(\d{4})$/, '$1/$2/$3')}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="mb-20 fontset001">
                                                        <label for="clientAddress" className="form-label fw-semibold text-primary-light text-sm mb-8"> Client Address <span className="text-danger-600">*</span></label>
                                                        <FormikTextarea
                                                            name="clientAddress"
                                                            id="clientAddress"
                                                            className="form-control"
                                                            placeholder="Enter Client Address"
                                                            defaultValue={""}
                                                        />
                                                    </div>
                                                </div>


                                                {/* Edit Items Section */}

                                                <FieldArray name="invoiceItems">
                                                    {({ push, remove }) => (
                                                        <div className="addmoreItemsDescption">

                                                            <label className="form-label fw-semibold text-primary-light text-md mb-8">Edit Items</label>
                                                            {values.invoiceItems.map((item, index) => {
                                                                const totalAmount = Number(((item.price || 0) * (item.quantity || 0)).toFixed(2)); // Calculate total amount
                                                                const gstValue = Number((totalAmount * (item.gstRate || 0) / 100).toFixed(2)); //Calculate gst value
                                                                const grandTotal = totalAmount + gstValue;
                                                                return (
                                                                    <>
                                                                        <div className="mb-10">
                                                                            <label className="form-label fw-semibold text-primary-light text-sm mb-8 d-flex align-items-center gap-2">
                                                                                <span className="countNumber badge text-sm fw-semibold w-28-px h-28-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600 text-white">{index + 1}</span>
                                                                                Item Description <span className="text-danger-600">*</span>
                                                                            </label>
                                                                            <Field
                                                                                name={`invoiceItems[${index}].description`}
                                                                                type="text"
                                                                                className="form-control radius-8"
                                                                                placeholder="Enter Item Description"
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`invoiceItems[${index}].description`}
                                                                                component="div"
                                                                                style={{ color: 'red', fontSize: '12px' }}
                                                                            />
                                                                        </div>

                                                                        <div className="d-flex gap-2">
                                                                            <div className="mb-10 fontset001">
                                                                                <label className="form-label fw-semibold text-primary-light text-sm mb-8">Unit of Measurement <span className="text-danger-600">*</span></label>
                                                                                <FormikSelect
                                                                                    items={uomOptions}
                                                                                    name={`invoiceItems[${index}].uom`}
                                                                                    id={`invoiceItems[${index}].uom`}
                                                                                    className="form-control radius-8 form-select"
                                                                                    placeholder="Select UOM"
                                                                                />
                                                                            </div>
                                                                            {selectedCurrency === indianCurrency && (
                                                                                <div className="mb-10">
                                                                                    <label className="form-label fw-semibold text-primary-light text-sm mb-8">HSN/SCN Code </label>
                                                                                    <HSNSearchDropdown
                                                                                        name={`invoiceItems[${index}].sacCode`} // Make sure this matches
                                                                                        setFieldValue={setFieldValue} // Passing setFieldValue to child component
                                                                                        index={index} // Pass index to the component for dynamic updates
                                                                                        hsnData={sacOptions}
                                                                                        value={values.invoiceItems[index].sacCode}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        <div className="gridContainer">
                                                                            <div className="mb-10 grifsetp">
                                                                                <label className="form-label fw-semibold text-primary-light text-sm mb-8">Price <span className="text-danger-600">*</span></label>
                                                                                <div className="icon-field">
                                                                                    <span className="icon top-6">{selectedCurrency}</span>
                                                                                    <Field
                                                                                        name={`invoiceItems[${index}].price`}
                                                                                        type="number"
                                                                                        className="form-control text-end"
                                                                                        step="0.01"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name={`invoiceItems[${index}].price`}
                                                                                        component="div"
                                                                                        style={{ color: 'red', fontSize: '12px' }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="grifsetp">
                                                                                <label className="form-label fw-semibold text-primary-light text-sm mb-8">Qty. <span className="text-danger-600">*</span></label>
                                                                                <Field
                                                                                    name={`invoiceItems[${index}].quantity`}
                                                                                    className="form-control px-2 text-center"
                                                                                    type="number"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name={`invoiceItems[${index}].quantity`}
                                                                                    component="div"
                                                                                    style={{ color: 'red', fontSize: '12px' }}
                                                                                />
                                                                            </div>

                                                                            <div className="grifsetp">
                                                                                <label className="form-label fw-semibold text-primary-light text-sm mb-8">Total Amount <span className="text-danger-600">*</span></label>
                                                                                <div className="icon-field">
                                                                                    <span className="icon top-6">{selectedCurrency}</span>
                                                                                    <div
                                                                                        className="form-control text-end  text-sm disabledDv" >{totalAmount}</div>
                                                                                </div>
                                                                            </div>
                                                                            {selectedCurrency === indianCurrency && (
                                                                                <>
                                                                                    <div className="grifsetp">
                                                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">GST Rate % <span className="text-danger-600">*</span></label>
                                                                                        <Field
                                                                                            name={`invoiceItems[${index}].gstRate`}
                                                                                            type="number"
                                                                                            className="form-control text-end text-sm"
                                                                                            step="0.01"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name={`invoiceItems[${index}].gstRate`}
                                                                                            component="div"
                                                                                            style={{ color: 'red', fontSize: '12px' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="grifsetp">
                                                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">GST Value <span className="text-danger-600">*</span></label>
                                                                                        <div className="icon-field">
                                                                                            <span className="icon top-6">{selectedCurrency}</span>
                                                                                            <div className="form-control text-end text-sm disabledDv"
                                                                                            >{gstValue}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="grifsetp">
                                                                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">Grand Total <span className="text-danger-600">*</span></label>
                                                                                        <div className="icon-field">
                                                                                            <span className="icon top-6">{selectedCurrency}</span>
                                                                                            <div
                                                                                                className="form-control text-end text-sm disabledDv"
                                                                                            >{grandTotal}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        {1 < values.invoiceItems.length ? (
                                                                            <button
                                                                                type="button"
                                                                                className="d-flex align-items-center gap-2 text-red ms-auto justify-content-end"
                                                                                onClick={() => remove(index)}
                                                                            >
                                                                                Remove <i className="mdi mdi-delete mdi-18px"></i>
                                                                            </button>
                                                                        ) : ''}
                                                                    </>
                                                                )
                                                            })}
                                                            <button
                                                                type="button"
                                                                className='d-flex align-items-center gap-2 text-dblue ms-auto justify-content-end'
                                                                onClick={() =>
                                                                    push(initialItem)
                                                                }
                                                            >
                                                                <i className="mdi mdi-plus"></i> Add more
                                                            </button>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="posistickyNpayment max-w-634-px ms-auto">
                                                <label className="form-label fw-semibold text-primary-light text-md mb-8">Payment Details</label>
                                                <div className="border radius-10 p-16">
                                                    {values?.deliveryItems.map((item, index) => (
                                                        <div key={item.id} className="addmoreItemsDescption">
                                                            <div className="mb-10">
                                                                <div className="form-label fw-semibold text-primary-light text-sm mb-4 d-flex justify-content-between align-items-center gap-2">
                                                                    <span className="countNumber badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-neutral-900 text-white">
                                                                        {index + 1}
                                                                    </span>

                                                                    <div className="ymdsenpdrl d-flex align-items-center gap-2">
                                                                        <div className="year">
                                                                            <FormikSelect
                                                                                items={yearOptions}
                                                                                name={`deliveryItems[${index}].deliveredYear`}
                                                                                id={`deliveryItems[${index}].deliveredYear`}
                                                                                className="form-control w-100 radius-8 form-select"
                                                                                placeholder="Year"
                                                                                onChange={(param) => {
                                                                                    // setItems(param?.value || param, setFieldValue)
                                                                                    // setItems(items.map(ele => ele.id === item.id ? { ...ele, deliveredYear: param?.value || param } : ele));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="month">
                                                                            <FormikSelect
                                                                                items={utilMonthOptions()}
                                                                                // name={`deliveredMonth`}
                                                                                name={`deliveryItems[${index}].deliveredMonth`}
                                                                                id={`deliveryItems[${index}].deliveredMonth`}
                                                                                className="form-control w-100 radius-8 form-select"
                                                                                placeholder="Month"
                                                                                onChange={(param) => {
                                                                                    // const newValue = param?.value || param;
                                                                                    // setItems(items.map(ele => ele.id === item.id ? { ...ele, deliveredMonth: newValue } : ele));
                                                                                    // setFieldValue(`deliveryItems[${index}].deliveredMonth`, newValue);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="deliveriesDetails">
                                                                            <button type="button"
                                                                                onClick={() =>
                                                                                    getdeliveries(
                                                                                        index,
                                                                                        item.id,
                                                                                        values?.projectId,
                                                                                        item?.deliveredYear,
                                                                                        item?.deliveredMonth,
                                                                                        item,
                                                                                        values
                                                                                    )
                                                                                }

                                                                                disabled={
                                                                                    !values.projectId ||
                                                                                    !item ||
                                                                                    !item?.deliveredYear ||
                                                                                    !item?.deliveredMonth
                                                                                }
                                                                                className="btn btn-outline-primary-600 radius-8 text-sm px-10 py-9 d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#DeliveriesDetailsModal">
                                                                                Deliveries Details <i className="mdi mdi-chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-end">
                                                                    <span className="text-sm text-secondary-light fw-normal mb-16">
                                                                        {item?.subItems?.reduce((total, item) => total + (Number(item?.deliveredInvoice) || 0), 0) || 0} Invoice Deliveries added</span>
                                                                </div>
                                                                <div className="icon-field">
                                                                    <span className="icon top-6">{selectedCurrency}</span>
                                                                    <input
                                                                        type="text"
                                                                        disabled
                                                                        className="form-control w-100 text-end"
                                                                        placeholder="0"
                                                                        value={item?.subItems?.reduce(
                                                                            (total, item) =>
                                                                                total + (item?.categories?.unitCost || 0) * (Number(item?.deliveredInvoice) || 0),
                                                                            0
                                                                        )}
                                                                        onChange={(e) => handleAmountChange(item.id, e.target.value)}
                                                                        aria-label="Enter payment amount"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {index === values?.deliveryItems?.length - 1 ? (
                                                                <button
                                                                    type="button"
                                                                    className="d-flex align-items-center gap-2 text-dblue ms-auto justify-content-end"
                                                                    onClick={addItem}
                                                                >
                                                                    <i className="mdi mdi-plus"></i> Add more
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    className="d-flex align-items-center gap-2 text-red ms-auto justify-content-end mb-16"
                                                                    onClick={() => removeItem(item.id, index)}
                                                                >
                                                                    Remove <i className="mdi mdi-delete mdi-18px"></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))}

                                                    <div className="mt-0">
                                                        <div className="d-flex justify-content-between align-items-center border-bottom py-10">
                                                            <div className="Text text-neutral-900">Subtotal</div>
                                                            <div className="Price text-neutral-900">{costFormat(subTotal, selectedCurrency)}</div>
                                                        </div>

                                                        {selectedCurrency === indianCurrency && (
                                                            <div className="d-flex justify-content-between align-items-center py-10">
                                                                <div className="Text text-neutral-900">Total GST </div>
                                                                <div className="Price">{costFormat(totalSumGST, selectedCurrency)}</div>
                                                            </div>
                                                        )}
                                                        <div className="d-flex justify-content-between align-items-center bg-neutral-200 px-12 py-8 w-100 radius-8 text-secondary-light">
                                                            <div className="Text fw-semibold text-neutral-900">Grand Total</div>
                                                            <div className="dropdown">
                                                                <button className="dropdown-btn dropdown-toggle" data-bs-toggle="dropdown">
                                                                    {selectedCurrency}
                                                                </button>
                                                                <div className="dropdown-menu mx-hight200px">
                                                                    {currencies.map((currency, idx) => (
                                                                        <div
                                                                            key={idx}
                                                                            className="dropdown-item text-sm cursor-pointer"
                                                                            onClick={() => setSelectedCurrency(`${currency.symbol}`)}
                                                                        >
                                                                            <span className="currency-circle">{currency.symbol}</span> {currency.name}
                                                                        </div>
                                                                    ))}


                                                                </div>
                                                            </div>
                                                            <div className="Price fw-medium text-neutral-900 text-xl">{costFormat(sumGrandTotal, selectedCurrency)}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center py-10">
                                                            <div className="Text">Display Bank Details</div>
                                                            <div className="">
                                                                <div className="d-flex align-items-center flex-wrap gap-28">
                                                                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                                        <Field
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="showBankDetails" // This corresponds to the Formik field name
                                                                            value="yes" // Value for the "Yes" option
                                                                            id="BankDetails1"
                                                                        />
                                                                        <label
                                                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                                            htmlFor="BankDetails1"
                                                                        >
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                                        <Field
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="showBankDetails" // Same Formik field name as above
                                                                            value="no" // Value for the "No" option
                                                                            id="BankDetails2"
                                                                        />
                                                                        <label
                                                                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                                            htmlFor="BankDetails2"
                                                                        >
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                    <ErrorMessage
                                                                        name="showBankDetails"
                                                                        component="div"
                                                                        style={{ color: 'red', fontSize: '12px' }}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="savecontinu">
                                                    <button type="submit" className="btn btn-info-600 radius-8 px-20 py-11 w-100">
                                                        Save and Continue
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>

            <DeliveriesDetails deliveredData={deliveries} onInvoiceDeliveriesUpdate={handleInvoiceDeliveriesUpdate} />
        </>
    )
}

export default CreateInvoice
