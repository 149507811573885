import React, { useEffect, useRef, useState } from 'react';
import SearchInput from '../SearchInput';
import YearDropdown from './YearDropdown';
import VendorInvoiceTable from './VendorInvoiceTable';  
import ApprovedModal from './ApprovedModal';
import CancelModal from './CancelModal'; 
import VendorLedger from './VendorLedger';
const VendorInvoice = () => { 

  // Sample filtered data
  const filteredData = [
    { status: 'Pending Invoice', totalInvoice: 10 },
    { status: 'Paid Invoice', totalInvoice: 5 },
    { status: 'Cancelled Invoice', totalInvoice: 3 },
    { status: 'Vendor Ledger', totalInvoice: '' },
  ];

  // State to keep track of the active tab, defaulting to the first tab
  const [activeTab, setActiveTab] = useState(filteredData[0]?.status || '');

  // Handle tab click and update the active tab
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // Sample columns
  const columns = [
    { id: "sNo", label: "S.No.", visible: true },
    { id: "vendor", label: "Vendor/Freelancer Name", visible: true },
    { id: "invoiceNumber", label: "Invoice Number", visible: true },
    { id: "invoiceDate", label: "Invoice Date", visible: true },
    { id: "baseAmount", label: "Base Amount", visible: true },
    { id: "gstAmount", label: "GST Amount", visible: true },
    { id: "totalAmount", label: "Total Amount", visible: true },
    { id: "tds", label: "TDS", visible: true },
    { id: "netPayable", label: "Net Payable", visible: true },
    { id: "partiallyPaid", label: "Partially Paid", visible: true },
    { id: "balanceAmt", label: "Balance Amt.", visible: true },
    { id: "projectCode", label: "Project Code", visible: true },
    { id: "projectManager", label: "Project Manager", visible: true },
    { id: "customerName", label: "Customer Name", visible: true },
    { id: "customerInvoiceNo", label: "Cust. Invoice No.", visible: true },
    { id: "currency", label: "Currency", visible: true },
    { id: "customerInvoiceAmount", label: "Cust. Invoice Amt.", visible: true },
    { id: "viewDetails", label: "View Details", visible: true },
    { id: "viewInvoice", label: "View Invoice", visible: true },
    { id: "action", label: "Action", visible: true }, // For Pending Invoice only
    { id: "paidDate", label: "Paid Date", visible: true }, // For Paid Invoice only
    { id: "cancelledDate", label: "Cancelled Date", visible: true }, // For Cancelled Invoice only
    { id: "reasons", label: "Reasons", visible: true }, // For Cancelled Invoice only
  ]; 

  // Sample data
  const data = [
    {
      id: 1,
      vendor: "Nshkfc (Raghu)",
      invoiceNumber: "Acad/24-25/351",
      invoiceDate: "16 Oct 2024",
      baseAmount: "₹10",
      gstAmount: "₹0",
      totalAmount: "₹61740.00",
      tds: "₹0",
      netPayable: "₹61740.00",
      partiallyPaid: "₹61740.00",
      balanceAmt: "₹61740.00",
      projectCode: "Project 224A",
      projectManager: "Amrik Singh",
      customerName: "Odama",
      customerInvoiceNo: "Acad/24-25/351",
      currency: "INR",
      customerInvoiceAmount: "61740.00",
      paidDate: "16 Oct 2024",
      cancelledDate: "18 Oct 2024",
      reasons: "Stated that she will share the concern person details",
    }, 
     
  ];



  // Sample columns
  const columns2 = [
    { id: "sNo", label: "S.No.", visible: true }, 
    { id: "invoiceNumber", label: "Invoice Number", visible: true },
    { id: "invoiceDate", label: "Invoice Date", visible: true },
    { id: "baseAmount", label: "Base Amount", visible: true },
    { id: "gstAmount", label: "GST Amount", visible: true },
    { id: "totalAmount", label: "Total Amount", visible: true },
    { id: "tds", label: "TDS", visible: true },
    { id: "netPayable", label: "Net Payable", visible: true },
    { id: "partiallyPaid", label: "Partially Paid", visible: true },
    { id: "balanceAmt", label: "Balance Amt.", visible: true },
    { id: "projectCode", label: "Project Code", visible: true },
    { id: "projectManager", label: "Project Manager", visible: true },
    { id: "customerName", label: "Customer Name", visible: true },
    { id: "customerInvoiceNo", label: "Cust. Invoice No.", visible: true },
    { id: "currency", label: "Currency", visible: true },
    { id: "customerInvoiceAmount", label: "Cust. Invoice Amt.", visible: true },
    { id: "viewDetails", label: "View Details", visible: true },
    { id: "viewInvoice", label: "View Invoice", visible: true },
    { id: "action", label: "Action", visible: true }, // For Pending Invoice only
    { id: "paidDate", label: "Paid Date", visible: true }, // For Paid Invoice only
    { id: "cancelledDate", label: "Cancelled Date", visible: true }, // For Cancelled Invoice only
    { id: "reasons", label: "Reasons", visible: true }, // For Cancelled Invoice only
  ]; 

  // Sample data
  const data2 = [
    {
      id: 1, 
      invoiceNumber: "Acad/24-25/351",
      invoiceDate: "16 Oct 2024",
      baseAmount: "₹10",
      gstAmount: "₹0",
      totalAmount: "₹61740.00",
      tds: "₹0",
      netPayable: "₹61740.00",
      partiallyPaid: "₹61740.00",
      balanceAmt: "₹61740.00",
      projectCode: "Project 224A",
      projectManager: "Amrik Singh",
      customerName: "Odama",
      customerInvoiceNo: "Acad/24-25/351",
      currency: "INR",
      customerInvoiceAmount: "61740.00",
      paidDate: "16 Oct 2024",
      cancelledDate: "18 Oct 2024",
      reasons: "Stated that she will share the concern person details",
    }, 
     
  ];


  // Function to get the columns based on the active tab
  const getFilteredColumns = () => {
    if (activeTab === 'Pending Invoice') {
      return columns.filter(col => col.id !== 'paidDate' && col.id !== 'cancelledDate' && col.id !== 'reasons');
    }
    if (activeTab === 'Paid Invoice') {
      return columns.filter(col => col.id !== 'action' && col.id !== 'cancelledDate' && col.id !== 'reasons');
    }
    if (activeTab === 'Cancelled Invoice') {
      return columns.filter(col => col.id !== 'action' && col.id !== 'paidDate');
    } 
    if (activeTab === 'Vendor Ledger') {
      return columns2.filter(col => col.id !== 'action' && col.id !== 'cancelledDate' && col.id !== 'reasons');
    } 
    return columns;
  };
 

  return (
    <div>
      <div className="row gy-4 mt-1">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body">
              <div className="mb-0 pb-16">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <h2 className="fw-semibold mb-16 font-24">
                      <button>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                          <g data-name="20-Arrow Left">
                            <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0z" />
                            <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                          </g>
                        </svg>
                      </button>{" "}
                      Vendor Invoice
                    </h2>
                    <ul className="d-flex align-items-center gap-2">
                      <li className="fw-medium">
                        <a href="/" className="d-flex align-items-center gap-1 hover-text-primary">
                          <iconify-icon
                            icon="solar:home-smile-angle-outline"
                            className="icon text-lg"
                          ></iconify-icon>
                          Home
                        </a>
                      </li>
                      <li>-</li>
                      <li className="fw-medium">Invoice</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Tabs Buttons */}
              <div className="d-flex flex-wrap align-items-center justify-content-between border-bottom pb-16">
                <div className='d-flex flex-wrap align-items-center gap-1 btnActiveLight'>
                  {filteredData.map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => handleTabClick(item.status)}
                      className={`badge text-sm fw-semibold px-16 py-9 radius-4 text-white d-flex align-items-center gap-2 text-primary-light ${activeTab === item.status ? 'active' : ''}`}
                    >
                      {item.status}
                      {item.totalInvoice && (
                        <span className="badge bg-neutral-900 text-base radius-5 text-xs">
                          {item.totalInvoice}
                        </span>
                      )}
                    </button>
                  ))}
                </div>

                <div className='d-flex flex-wrap align-items-center gap-2'>
                  <SearchInput handleSearchInputChange={() => {}} />
                  <YearDropdown />
                </div>
              </div>

              {/* Tab Content */}
              <div className="tab-content mt-3">
                {activeTab === 'Pending Invoice' && (
                  <div className="tab-pane active">
                    <VendorInvoiceTable columns={getFilteredColumns()} data={data} activeTitle="Pending Invoice Details" />
                  </div>
                )}
                {activeTab === 'Paid Invoice' && (
                  <div className="tab-pane active">
                    <VendorInvoiceTable columns={getFilteredColumns()} data={data} activeTitle="Paid Invoice Details" />
                  </div>
                )}
                {activeTab === 'Cancelled Invoice' && (
                  <div className="tab-pane active">
                    <VendorInvoiceTable columns={getFilteredColumns()} data={data} activeTitle="Cancelled Pending Invoice Details" />
                  </div>
                )}
                {activeTab === 'Vendor Ledger' && (
                  <div className="tab-pane active"> 
                    {/* <VendorInvoiceTable columns={getFilteredColumns()} data={data} activeTitle="Vendor Ledger Details" /> */}
                      <VendorLedger columns={getFilteredColumns()} data={data2} activeTitle="Vendor Ledger Details" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ApprovedModal />
      <CancelModal />
    </div>
  );
};

export default VendorInvoice