import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import { fullMonthName } from 'src/utils/CommonUtils';
import FinancialYearDropdown from 'src/components/FinancialYearDropdown';
import { oadSalesReports } from 'src/api/ReportsAPI';
import ReportTab from '../ReportTab';
Highcharts3D(Highcharts);

const OadSalesReportContainer = () => {
    const [oad, setOad] = useState([]);
    const [searchOadVertical, setSearchOadVertical] = useState('');
    const [selectedYear, setSelectedYear] = useState(''); // Default selected year
    const oadOptions = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 3,
                depth: 0,
                viewDistance: 40,
            },
        },
        title: {
            text: '',
            align: 'left',
            style: {
                float: 'left',
                marginBottom: '20px',
            },
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Month',
            },
        },
        yAxis: {
            title: {
                text: 'Amount',
            },
            min: 0,
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true, // Enables labels on columns
                    format: '{y}', // Format label to show value with "M"
                    style: {
                        fontWeight: 'bold',
                    },
                },
            },
        },
        series: [],
        colors: ['#22C55E', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: 10,
        },
        tooltip: {
            shared: true,
            valueSuffix: ' ',
        },
    };

    const getStartAndEndDates = () => {
        const today = new Date();

        // Get the current year
        const year = today.getFullYear();

        // Create the start date for January 1st of the current year
        const startDate = new Date(Date.UTC(year, 0, 1));  // January is month 0, day 1

        // Create the end date for December 1st of the current year
        const endDate = new Date(Date.UTC(year + 1, 0, 1));  // January 1st of the next year

        // Format dates to match the required format (ISO 8601)
        return {
            startDate: startDate.toISOString(),  // 2024-01-01T00:00:00.000Z
            endDate: endDate.toISOString()       // 2024-12-01T00:00:00.000Z
        };
    };

    const dates = getStartAndEndDates();
    const startDate = dates.startDate;
    // const startDate = "2023-04-01T00:00:00Z"
    const endDate = dates.endDate;
    const [where, setWhere] = useState({
        startDate,
        endDate,
    });

    const convertYearRange = (yearRange) => {
        if (!yearRange)
            return true
        // Split the input string into start and end year
        const [startYear, endYear] = yearRange.split('-');

        // Create start date (1st Jan of the start year)
        const startDate = new Date(Date.UTC(startYear, 3, 1)); // January is month 0, day 1

        // Create end date (1st Jan of the end year)
        const endDate = new Date(Date.UTC(parseInt(endYear), 3, 1)); // January 1st of the next year

        return {
            startDate: startDate.toISOString(),  // 2022-01-01T00:00:00.000Z
            endDate: endDate.toISOString()       // 2023-01-01T00:00:00.000Z
        };
    };

    const apiData = async () => {
        try {
            const resp = await oadSalesReports({ where });
            setOad(resp?.data?.data?.oad)
            console.log("resp", resp?.data?.data);

        } catch (err) {
            console.error('Failed to operation report. ' + err.message);
        }
    }
    useEffect(() => {
        apiData()
    }, [where])

    const groupedOAD = (data) => {
        const grouped = {};

        // Iterate through each data item
        data
            .filter((item) => !searchOadVertical || item.vertical.toLowerCase() === searchOadVertical.toLowerCase())
            .forEach(item => {
                const range = `${fullMonthName(item.month, true)} ${item.year}`;

                // If the range is already in the grouped object, add to totalOAD
                if (grouped[range]) {
                    grouped[range] += item.totalOAD;
                } else {
                    // If the range is not in the grouped object, add it
                    grouped[range] = item.totalOAD;
                }
            });

        // Convert the grouped data to the desired format
        return Object.entries(grouped);
    };
    oadOptions.series = [{ name: 'OAD', data: groupedOAD(oad) },]

    const handleOadVertical = (e) => {
        setSearchOadVertical(e.target.value);
    }

    const handleYearChange = (year) => {
        setSelectedYear(year); // Update selected year
        console.log(year, "yearyear");
        const newDate = convertYearRange(year);

        setWhere(prev => ({
            ...prev,
            startDate: year ? newDate.startDate : startDate,
            endDate: year ? newDate.endDate : endDate
        }));
    }
    return (
        <>
            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                    Excepted Business (OAD)
                </h6>

                <div className="d-flex align-items-center flex-wrap gap-28">
                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="horizontal1"
                            id="horizontal11"
                            defaultChecked={true}
                            value=""
                            onChange={handleOadVertical}
                        />
                        <label
                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                            htmlFor="horizontal11"
                        >
                            {" "}

                            Total  {" "}
                        </label>
                    </div>
                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="horizontal1"
                            id="horizontal44"
                            value='Acadecraft Government'
                            onChange={handleOadVertical}
                        />
                        <label
                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                            htmlFor="horizontal44"
                        >
                            {" "}
                            Acadecraft Government {" "}
                        </label>
                    </div>
                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="horizontal1"
                            id="horizontal33"
                            value='Acadecraft Private'
                            onChange={handleOadVertical}
                        />
                        <label
                            className="form-check-label line-height-1 fw-medium text-secondary-light"
                            htmlFor="horizontal33"
                        >
                            {" "}
                            Acadecraft Private{" "}
                        </label>
                    </div>

                    <div style={{ width: "130px" }}>
                        <FinancialYearDropdown selectedYear={selectedYear} onYearChange={handleYearChange} />
                    </div>
                </div>
            </div>
            <br />
            {/* start Chart  */}
            <div className="mt-2 allchartsCss">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={oadOptions}
                />
            </div>
        </>


    )
}

export default OadSalesReportContainer