import React, { useEffect, useRef, useState } from 'react';
import { vendorDeliveryCreateAPI, vendorDeliveryInvoiceCreateAPI } from 'src/api/VendorDeliveryApi';
import { postRequest } from 'src/utils/RequestUtil';
import { toast } from 'react-toastify';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { formatDate } from 'src/utils/utils';

// Function to get initials from the full name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  const firstInitial = nameParts[0]?.[0] || '';
  const lastInitial = nameParts[nameParts.length - 1]?.[0] || '';
  return `${firstInitial} ${lastInitial}`.toUpperCase();
};

const InvoiceTableModal = ({selectedDelivery,search}) => {
  const [invRows, setInvRows] = useState([]);
  const [clientInvoiceNo, setClientInvoiceNo] = useState('');
  const [date, setDate] = useState(null);
  
  const [sections, setSections] = useState([
    // {
    //   sectionTitle: 'Storyboard Development',
    //   rows: [
    //     { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Mazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 10 },
    //     { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Mazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 20 },
    //   ],
    // },
    // {
    //   sectionTitle: 'Video Editing',
    //   rows: [
    //     { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Gazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 30 },
    //     { date: '28-Jun-2024', uploadedBy: 'Kiran Mazumdar', vendorName: 'Kiran Gazumdar', perUnitCost: 98, totalUnit: 50, paidUnit: 40 },
    //   ],
    // },
  ]);

  const [showCombinedView, setShowCombinedView] = useState(true);

  const calculateTotals = () => {
    return sections.map((section) => {
      const totalPaidUnit = section.rows.reduce((total, row) => total + row.invoicePendingUnit, 0);
      const amount = section.rows.reduce((total, row) => total + row.invoicePendingUnit * row.perUnitCost, 0);
      return { category: section.sectionTitle,perUnitCost:section.perUnitCost, totalPaidUnit, amount };
    });
  };

  const grandTotals = calculateTotals().reduce(
    (totals, item) => {
      totals.totalPaidUnit += item.totalPaidUnit;
      totals.amount += item.amount;
      return totals;
    },
    { totalPaidUnit: 0, amount: 0 }
  );
useEffect(() => {
  // Group data
const groupedData = selectedDelivery.reduce((acc, item) => {
  const sectionTitle = item.moduleId.moduleName;
  const uploadedBy = `${item.userId.firstName} ${item.userId.lastName}`;
  const vendorName = `${item.vendorId.firstName} ${item.vendorId.lastName}`;
  const deliveryId = item._id;

  const formattedDate = new Date(item.date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const row = {
    date: formattedDate,
    deliveryId,
    uploadedBy,
    vendorName,
    perUnitCost: item.perUnitCost,
    totalUnit: item.totalDurations,
    paidUnit: item.totalDurations, // Assuming paidUnit = totalDurations
    invoicePendingUnit:item.invoicePendingUnit
  };

  const existingSection = acc.find((section) => section.sectionTitle === sectionTitle);

  if (existingSection) {
    existingSection.rows.push(row);
  } else {
    acc.push({ sectionTitle, rows: [row] });
  }

  return acc;
}, []);
setSections(groupedData)
}, [selectedDelivery]);
useEffect(() => {
  const rows = sections.flatMap((section) => section.rows);
  setInvRows(rows);
},[sections])
const handleGenerateInvoice=async()=>{
  try {
    if(!date){
      toast.error('Please enter select invoice date!');
      return
    }
    if(clientInvoiceNo===''){
      toast.error('Please enter client invoice number!');
      return
    }
    const updatedQtyDetails = invRows.map((inv)=>({...inv,paidUnit:inv.invoicePendingUnit}));
    const payload = {
      date: date,
      clientInvNo: clientInvoiceNo,
      clientId: selectedDelivery[0].clientId._id,
      projectId: selectedDelivery[0].projectId._id,
      vendorId: selectedDelivery[0].vendorId._id,
      totalUnits: grandTotals.totalPaidUnit,
      totalAmount: grandTotals.amount,
      invoiceDetails: updatedQtyDetails
    }
  const result = await vendorDeliveryInvoiceCreateAPI(payload)
  toast.success('Vendor invoice submitted successfully!');
  search();
} catch (error) {
  toast.error('Failed to submit form! ' + error.message);
} finally {
  
}
}
const dateInputRef = useRef(null);  // Create a ref for the input  
useEffect(() => {
  if (!showCombinedView && dateInputRef.current) {
  // Initialize flatpickr and disable time picker
  flatpickr(dateInputRef.current, {
    enableTime: false,  // Disable time picker
    dateFormat: "Y/m/d",  // Date format MM/DD/YYYY
    onChange: (selectedDates, dateStr) => {
      if (selectedDates.length > 0) {
        const selectedDate = selectedDates[0];
          const date = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
          setDate(date);
    }
  }
  });
}
}, [showCombinedView,dateInputRef.current]);
  return (  
<div className="modal fade cusmodl LeaveDetailsModal" id="combinedinvoiveModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl modal-dialog-scrollable">
    <div className="modal-content">
    {/* <div className="modal-header d-flex justify-content-between pb-0 border-0"><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div> */}
        <div className="modal-body py-0"> 
        {showCombinedView ? (
          <div className="Combinedvset">
            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="section">
                <div className="d-flex align-items-center justify-content-between mb-6 mt-16">
                  <h3 className="text-md">{section.sectionTitle}</h3>
                  <button className="text-dblue fw-medium me-20" onClick={() => {
                    const newSections = sections.filter((_, index) => index !== sectionIndex);
                    setSections(newSections);
                  }}>Delete Section</button>
                </div>
                <table className="table basic-border-table mb-0 table-hover bdrnoneSet">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Uploaded by</th>
                      <th>Vendor Name</th>
                      <th className="text-center">Per Unit Cost</th>
                      <th className="text-center">Total Unit</th>
                      <th className="text-center">Paid Unit</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {section.rows.map((row, rowIndex) => {
                    const tempPending =  row.invoicePendingUnit 
                      return (
                      <tr key={rowIndex}>
                        <td>{rowIndex + 1}</td>
                        <td>{formatDate(row?.date)}</td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="user-initials">{getInitials(row.uploadedBy)}</span>{row.uploadedBy}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span className="user-initials">{getInitials(row.vendorName)}</span>{row.vendorName}
                          </div>
                        </td>
                        <td className="text-center">{row.perUnitCost}</td>
                        <td className="text-center">{row.totalUnit}</td> 
                        <td className="text-center">
                          <input
                            type="number"
                            className="form-control w-110-px text-center mx-auto"
                            value={row.invoicePendingUnit}
                            placeholder="0"
                            onChange={(e) => {
                              let value = Number(e.target.value);
                              // if (value > tempPending) {
                              //     value = tempPending;  
                              // } 
                              const newSections = [...sections];
                              newSections[sectionIndex].rows[rowIndex].invoicePendingUnit = value;
                              setSections(newSections);                             
                            }}                            
                          />
                        </td>
                        <td className="text-center border-0">
                          <button onClick={() => {
                            const newSections = [...sections];
                            newSections[sectionIndex].rows.splice(rowIndex, 1);
                            setSections(newSections);
                          }}>
                            <i className="mdi mdi-delete mdi-24px"></i>
                          </button>
                        </td>
                      </tr>
                    )})}
                  </tbody>
                </table>
              </div>
            ))}
            <div className="text-center py-16">
              <button
                className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2 mx-auto"
                onClick={() => setShowCombinedView(false)}
              >
                <i className="mdi mdi-vector-combine"></i> Combine
              </button>
            </div>
          </div>
        ) : (
          <div className="GenerateCombineInvoice">
            <button
              className="d-flex align-items-center gap-2 btn btn-primary-100 text-primary-600 radius-8 px-20 py-6 mb-16 mt-16"
              onClick={() => setShowCombinedView(true)}
            >
              <i className="mdi mdi-keyboard-backspace mdi-18px"></i> Back
            </button>
            <div className="table-container1">
              <table className="table basic-border-table mb-0 table-hover">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th className="text-center">Total Paid Unit</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {calculateTotals().map((item, index) => (
                    <tr key={index}>
                      <td><div className="d-inline-flex"><span>{index + 1}.</span> {item.category}</div></td>
                      <td className="text-center">{item.totalPaidUnit}</td>
                      <td className="text-end">₹ {item.amount.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th className="text-xl">Total</th>
                    <th className="text-center text-xl">{grandTotals.totalPaidUnit}</th>
                    <th className="text-end text-xl">₹ {grandTotals.amount.toFixed(2)}</th>
                  </tr>
                  <tr>
                    <th className="text-xl"></th>
                    <th className="text-center text-xl">
                        <input
                        className='form-control w-300-px'
                        id="date"
                        type='text'
                        name="date" // Link to Formik state using 'name'
                        placeholder="MM/DD/YYYY"
                        ref={dateInputRef} // Correctly attach ref here
                        onChange={(e) => setDate(e.target.value)}  // Manually update the value
                        />
                    </th>
                    <th className="text-end text-xl">
                      <input type='text' className='form-control w-300-px' placeholder='Enter Client Invoice Number' onChange={(e) => setClientInvoiceNo(e.target.value)} />
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="text-start py-16">
              
              <button className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
              aria-label='Clone'
              data-bs-dismiss="modal"
                onClick={handleGenerateInvoice}>
                <i className="mdi mdi-vector-combine"></i> Generate Combine Invoice
              </button>
            </div>
          </div>
        )}
      
        </div> 
    </div>
</div>
</div>
  );
};

export default InvoiceTableModal;
