import React, { useEffect, useState } from 'react';
import { costFormat } from 'src/utils/CommonUtils';

const DeliveriesDetails = ({ deliveredData, onInvoiceDeliveriesUpdate }) => {
    const [editableFields, setEditableFields] = useState({});
    const [deliveries, setDeliveries] = useState(
        {
            id: '1',
            delivered: 0,
            deliveredInvoice: 0,
            balQty: 0,
            totalCost: '',
            billedAmt: '',
            balAmt: '',
            subItems: [],
        },
    );

    useEffect(() => {
        setDeliveries((prevDeliveries) => ({
            ...prevDeliveries,
            delivered: deliveredData.reduce((sum, item) => sum + item.totalDurations, 0),
            totalCost: deliveredData.reduce((sum, item) => sum + (item.totalDurations * item?.categories?.unitCost), 0),
            subItems: deliveredData // Ensure deliveredData is an array
        }));
    }, [deliveredData]);

    // Toggle edit mode for a specific field
    const toggleEditField = (rowId, itemId = null) => {
        const fieldKey = itemId ? `${rowId}-${itemId}` : rowId;
        setEditableFields((prev) => ({
            ...prev,
            [fieldKey]: !prev[fieldKey],
        }));
    };

    // Update deliveredInvoice value
    const handleInvoiceDeliveriesChange = (deliveryId, itemId, newValue) => {
        setDeliveries((prevDeliveries) =>
            // prevDeliveries.map((delivery) =>
            prevDeliveries.id === deliveryId
                ? {
                    ...prevDeliveries,
                    deliveredInvoice: itemId === null ? newValue : prevDeliveries.deliveredInvoice,
                    subItems: itemId
                        ? prevDeliveries.subItems.map((item) =>
                            item.id === itemId ? { ...item, deliveredInvoice: newValue } : item
                        )
                        : prevDeliveries.subItems,
                }
                : prevDeliveries
        )
        // );
    };

    // Trigger the parent component's callback on button click
    const handleAddAndContinue = () => {
        if (onInvoiceDeliveriesUpdate) {
            onInvoiceDeliveriesUpdate(deliveries);
            // onInvoiceDeliveriesUpdate(deliveredInvoiceTotal);
        }
    };

    return (
        <div>
            <div className="modal fade cusmodl LeaveDetailsModal" id="DeliveriesDetailsModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between border-0">
                            <h6 className="modal-title">Add Deliveries Details</h6>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
                        </div>
                        <div className="modal-body py-0">
                            <div className="table-container accordion-body collapse show">
                                <table className="table basic-border-table mb-0 table-hover">
                                    <thead>
                                        <tr className="infobgblack">
                                            <th>#</th>
                                            <th>Project</th>
                                            <th className="text-center">Unit Cost</th>
                                            <th className="text-center">Delivered</th>
                                            <th className="text-center">Invoice Deliveries</th>
                                            <th className="text-center">Bal. Qty.</th>
                                            <th className="text-center">Total Cost</th>
                                            <th className="text-center">Billed Amt.</th>
                                            <th className="text-end">Bal. Amt.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {deliveries.map((row, index) => ( */}
                                        <React.Fragment key={deliveries?.id}>
                                            <tr>
                                                <td></td>
                                                <td className="text-center">
                                                    {/* {deliveries?.projectCode} */}
                                                </td>
                                                <td className="text-center">
                                                    {deliveries?.unitCost}
                                                </td>
                                                <td className="text-center">{deliveries?.delivered}</td>
                                                <td className="text-center">
                                                    {/* Sum of all item.deliveredInvoice */}
                                                    {deliveries?.subItems.reduce((total, item) => total + (item?.deliveredInvoice || 0), 0)}
                                                </td>
                                                <td className="text-center">
                                                    {parseFloat(
                                                        deliveries?.subItems.reduce((total, item) => total + (item?.totalDurations || 0), 0) -
                                                        deliveries?.subItems.reduce((total, item) => total + (item?.deliveredInvoice || 0), 0)
                                                    ).toFixed(2)}
                                                </td>
                                                <td className="text-center">{costFormat(deliveries?.totalCost)}</td>
                                                <td className="text-center">
                                                    {costFormat(
                                                        deliveries?.subItems.reduce(
                                                            (total, item) =>
                                                                total + item?.categories?.unitCost * (item?.deliveredInvoice || 0),
                                                            0
                                                        )
                                                    )}
                                                </td>
                                                <td className="text-end">
                                                    {costFormat(
                                                        deliveries?.subItems.reduce(
                                                            (total, item) =>
                                                                total +
                                                                item?.categories?.unitCost * (item?.totalDurations || 0) -
                                                                item?.categories?.unitCost * (item?.deliveredInvoice || 0),
                                                            0
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                            {deliveries?.subItems.map((item, index) => (
                                                <tr key={index} className="hiddenRow accordion-body collapse show">
                                                    <td>{index + 1}</td>
                                                    <td className="text-dblue"><div className="tableFixed max-w-150-px">{item?.productionSlug}</div></td>
                                                    <td className="text-center">{costFormat(item?.categories?.unitCost)}</td>
                                                    <td className="text-center">{item?.totalDurations}</td>
                                                    <td className="text-center">
                                                        {editableFields[`${deliveries?.id}-${item.id}`] ? (
                                                            <div className="d-flex align-items-center">
                                                                <input
                                                                    type="number" className="form-control w-80-px mx-auto px-4"
                                                                    value={item?.deliveredInvoice || 0}
                                                                    max={item?.totalDurations || 0}
                                                                    onChange=
                                                                    {(e) => {
                                                                        // Get the input value and clamp it to the max allowed value
                                                                        const inputValue = parseFloat(e.target.value) || 0;
                                                                        const clampedValue = Math.max(0, Math.min(inputValue, item?.totalDurations || 0)); // Enforce positive value
                                                                        handleInvoiceDeliveriesChange(deliveries?.id, item.id, clampedValue);
                                                                    }}
                                                                    onBlur={() => toggleEditField(deliveries?.id, item.id)}
                                                                    autoFocus
                                                                />
                                                                {/* <button className="btn btn-primary btn-sm text-sm mx-2" onClick={() => handleSave(deliveries?.id, item.id)}>Save</button> */}
                                                            </div>
                                                        ) : (
                                                            <span className="border px-10 radius-4 w-80-px form-control mx-auto" onClick={() => toggleEditField(deliveries?.id, item.id)} style={{ cursor: 'pointer' }}>
                                                                {item?.deliveredInvoice || 0}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="text-center">{(item?.totalDurations || 0) - (item?.deliveredInvoice || 0)}</td>
                                                    <td className="text-center">{costFormat(item?.categories?.unitCost * item.totalDurations)}</td>
                                                    <td className="text-center">{costFormat(item?.categories?.unitCost * (item?.deliveredInvoice || 0))}</td>
                                                    <td className="text-end">{costFormat(item?.categories?.unitCost * item.totalDurations - item?.categories?.unitCost * (item?.deliveredInvoice || 0))}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                        {/* // ))} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer border-0">
                            <div className="d-flex justify-content-between gap-4 align-items-center">
                                <div className="text-sm">
                                    <span className="InvoiceDeliveriesCount fw-bold">{deliveries?.subItems.reduce((total, item) => total + (item?.deliveredInvoice || 0), 0)}</span> Invoice Deliveries added
                                </div>
                                <button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={handleAddAndContinue}>Add & Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeliveriesDetails;
