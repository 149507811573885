import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importing Quill's default theme
import FileUpload from './FileUpload';
import ProjectScopeHistory from './ProjectScopeHistory';

const TextEditor = () => {
  const [editorHtml, setEditorHtml] = useState('');
  const [showEditor, setShowEditor] = useState(false); // State to toggle editor visibility
  const [isSaved, setIsSaved] = useState(false); // State to track if the scope has been saved
  const [buttonText, setButtonText] = useState('Save'); // State for button text

  const handleChange = (value) => {
    setEditorHtml(value);
  };

  const handleAddScopeClick = () => {
    setShowEditor(true); // Show the editor container on button click
  };

  const handleSaveClick = () => {
    setIsSaved(true); // Mark as saved
    setButtonText('Modify'); // Change button text to "Modify"
  };

  const handleModifyClick = () => {
    setIsSaved(false); // Mark as unsaved
    setButtonText('Save'); // Change button text back to "Save"
  };

  return (
    <>
      {!showEditor ? (
        <div className="text-center py-32 border-top AddProjectScope">
          <button
            type="button"
            className="btn btn-primary-600 radius-8 px-20 py-11 mb-32"
            onClick={handleAddScopeClick} // Handle button click
          >
            Add Project Scope
          </button>
          <p>No Project Scope Added</p>
        </div>
      ) : (
        <>
          {/* Show these details only after scope is saved */}
          {isSaved && (
            <div className="d-flex justify-content-between align-items-center py-16">
              <div className="">
                Created by
                <div className="d-flex align-items-center gap-2 font-14 text-black fw-medium mt-6">
                  <span className="img imgsSet me-0">
                    <img src="/assets/images/avatar/avatar1.png" />
                  </span> Nidhi Sharma
                </div>
              </div>
              <div className="CreatedDate1">Created Date: August 2025, 11:30:30</div>
            </div>
          )}

          <div className={`editor-container ${isSaved ? 'active' : ''}`}> {/* Add active class based on isSaved */}
            <ReactQuill
              className="border"
              value={editorHtml}
              onChange={handleChange}
              modules={TextEditor.modules}
              placeholder="Write Project Scope here"
              readOnly={isSaved} // If saved, make the editor read-only
            />

            <div className="FileUploadFile">
              <FileUpload />
            </div>
            <div className="SaveDataBtn">
              <button
                type="button"
                className="btn btn-primary-600 radius-8 px-20 py-11"
                onClick={isSaved ? handleModifyClick : handleSaveClick} // Toggle between Save and Modify
              >
                {buttonText}
              </button>
            </div>
          </div>
          {isSaved && (
            <div className="ProjectScopeHistory">
              <ProjectScopeHistory />
            </div>
          )}
        </>
      )}
    </>
  );
};

TextEditor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false], font: [] }], // Header and font dropdown
    [{ size: ['small', 'medium', 'large', 'huge'] }], // Font sizes
    [{ align: [] }], // Alignment
    ['bold', 'italic', 'underline', 'strike'], // Formatting
    ['blockquote', 'code-block'], // Block elements
    [{ list: 'ordered' }, { list: 'bullet' }], // Lists
    [{ indent: '-1' }, { indent: '+1' }], // Indentation
    [{ direction: 'rtl' }], // Text direction
    ['link', 'image', 'video'], // Media links
    [{ color: [] }, { background: [] }], // Colors and background
    [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscript
    ['clean'], // Clear formatting
    ['undo', 'redo'], // Undo and redo buttons
  ],
  history: {
    delay: 1000, // Delay between user actions to group as a single history event
    maxStack: 50, // Maximum number of history states
    userOnly: true, // Whether to track only user changes
  },
};

export default TextEditor;
