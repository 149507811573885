import React, { useState } from "react";
import { costFormat, formatDate } from "src/utils/CommonUtils";

const ViewHistory = ({ actualExpenditures }) => {
    console.log("actualExpenditures4", actualExpenditures);
    const sortedExpenditures = [...actualExpenditures].sort((a, b) => new Date(b.ts).getTime() - new Date(a.ts).getTime());

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleDropdown = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <>
            <div className="offcanvas offcanvas-end ViewHistoryPopup" id="ViewHistoryPopup" aria-labelledby="filterRightLayout">
                <div className="offcanvas-header flex-column border-0 pb-0 text-start align-items-start">
                    <h6 className="modal-title border-bottom border-primary-400 w-100 pb-16 mb-16">View History</h6>
                    <p className="actual-amt w-100 d-flex justify-content-between mb-0 border-bottom pb-16">
                        Actual Amt: <span className="fw-bold text-primary-600">{costFormat(actualExpenditures?.reduce((sum, entry) => sum + parseFloat(entry?.amount?.$numberDecimal || 0), 0))}</span>
                    </p>
                    <button type="button" className="btn-close d-none" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <div className="view-history">
                        <div className="transaction-list">
                            {sortedExpenditures.length === 0 ? (
                                <div className="no-history">
                                    <p>No History</p>
                                </div>
                            ) : (
                                sortedExpenditures.map((exp, index) => (
                                    <div key={index} className="transaction-item">
                                        <div className="transaction-header d-flex align-items-start gap-3">
                                            <div className="">
                                                {exp?.employees?.profilePicture ? (
                                                    <img
                                                        src={exp?.employees?.profilePicture}
                                                        alt={`${exp?.employees?.fullName}'s profile`}
                                                        className="user-initials"
                                                    />
                                                ) : (
                                                    <div className="user-initials">
                                                        {exp?.employees?.fullName.split(" ")[0][0]}
                                                        {exp?.employees?.fullName.split(" ")[1][0]}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="w-100">
                                                <div className="transaction-details d-flex justify-content-between align-items-start">
                                                    <h4 className="font-16 mb-0">{exp?.employees?.fullName}</h4>
                                                    <p className="font-12 mb-0">{formatDate(exp?.ts)}</p>
                                                </div>
                                                <div className={`amount text-success-800`}>
                                                    <span className="text-neutral-900">Add Amt.</span> {costFormat(exp?.amount?.$numberDecimal)}
                                                </div>
                                                <div className="transaction-footer w-100 mb-20">
                                                    <button
                                                        className="toggle-comment w-100 font-14 fw-semibold d-flex justify-content-between align-items-center"
                                                        onClick={() => toggleDropdown(index)}
                                                    >
                                                        Comment <span>{expandedIndex === index ? <i className="mdi mdi-chevron-up mdi-24px text-primary-500"></i> : <i className="mdi mdi-chevron-down mdi-24px"></i>}</span>
                                                    </button>
                                                    {expandedIndex === index && exp?.description && (
                                                        <div className="comment-box border border-primary-400 rounded-3 p-12">
                                                            <p className="mb-0 font-14">{exp?.description}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))}
                        </div>
                    </div>
                </div>
                <div className="offcanvas-footer px-16">
                    <button
                        type="button"
                        className="btn btn-primary radius-6 px-14 py-12 text-md w-100 my-12"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        Close
                    </button>
                </div>
            </div>

        </>
    );
};

export default ViewHistory