import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const clientInvoiceSearchAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/search`;
    return postRequest({
        url,
        data,
    });
};
export const clientPaymentDetailsSearchAPI = function (data) {
    const url = `${config.baseUrl}clientPaymentDetails/search`;
    return postRequest({
        url,
        data,
    });
};
export const getclientInvoiceAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/getclientInvoice`;
    return postRequest({
        url,
        data,
    });
};

export const clientInvoiceCreateAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/save`;
    return postRequest({
        url,
        data,
    });
};

export const clientInvoiceUpdateAPI = function ({
    clientInvoiceId,
    data,
}) {
    const url = `${config.baseUrl}clientInvoice/update/${clientInvoiceId}`;
    return putRequest({
        url,
        data,
    });
};

export const clientInvoiceCostAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/viewclientInvoiceCost`;
    return postRequest({
        url,
        data,
    });
};
export const clientInvoiceOADSaveAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/save-OAD`;
    return postRequest({
        url,
        data,
    });
};
export const clientInvoiceCMDSaveAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/save-CMD`;
    return postRequest({
        url,
        data,
    });
};
export const getclientInvoiceSubCategorySaveAPI = function (categoryId) {
    const url = `${config.baseUrl}clientInvoice/get-clientInvoice-sub-category/${categoryId}`;
    return getRequest({
        url
    });
};
export const clientInvoiceCategorySaveAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/save-clientInvoice-category`;
    return postRequest({
        url,
        data,
    });
};
export const clientInvoiceSubCategorySaveAPI = function (data) {
    const url = `${config.baseUrl}clientInvoice/save-clientInvoice-sub-category`;
    return postRequest({
        url,
        data,
    });
};
