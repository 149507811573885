import React, { useState, useEffect, useRef } from 'react';
import TopTabs from "../TopTabs"
import { useParams } from 'react-router-dom';
import { expendituresProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';
import { shallowEqual, useSelector } from 'react-redux';
import config from "src/config";
import { toast } from 'react-toastify';
import { postRequest } from 'src/utils/RequestUtil';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikTextarea from 'src/components/formik/FormikTextarea';
import FormikInput from 'src/components/formik/FormikInput';
import DatePicker from 'react-flatpickr';
import { costFormat, formatDate } from 'src/utils/CommonUtils';

import ProjectPermissionHOC from 'src/hoc/ProjectPermissionHOC';
import ViewHistory from 'src/containers/project-container/View/Expenditure/ViewHistory';

const ExpenditureContainer = () => {
    const offcanvasRef = useRef(null);
    const { projectId } = useParams();
    const [expenditures, setExpenditures] = useState([])
    const [expenditureId, setExpenditureId] = useState('')
    const [actualExpenditures, setActualExpenditures] = useState([])
    const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)

    const projectCode = (currentRecord?.RefClientId?.ClientProjectCode) ? `${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}` : ''


    const getProjectExpenditures = async () => {
        try {
            const resp = await expendituresProjectSearchAPI({ projectId });
            setExpenditures(resp?.data?.data)
        } catch (error) {
            console.error('error from profit and loss', error);
        }
    };

    useEffect(() => {
        getProjectExpenditures();
    }, []);

    const defaultValues = {
        _id: null,
        title: "",
        date: null,
        planAmount: "",
        description: "",
    }
    const [initialValues, setInitialValues] = useState(defaultValues);
    const validationSchema = Yup.object({
        title: Yup.string().required("Title is required"),
        date: Yup.date().required("Date is required"),
        planAmount: Yup.number().required("Amount is required").min(1, "Amount must be greater than 0"),
        description: Yup.string().required("Description is required"),
    });

    const handleSubmit = async (values, { resetForm, setFieldValue }) => {
        // Log the form data after updating the values
        values.projectId = projectId
        const save_url = `${config.baseUrl}report/saveExpenditures`;
        try {
            const result2 = await postRequest({
                url: save_url,
                data: values,
            });
            resetForm();
            setFieldValue("date", '');
            setInitialValues((prevState) => ({
                ...prevState,
                ...defaultValues,
            }));

            // Manually trigger the close by targeting the offcanvas button
            const closeButton = offcanvasRef.current.querySelector('.btn-close');
            if (closeButton) {
                closeButton.click(); // Trigger the close button's click event
            }

            toast.success(result2.data.message);
            getProjectExpenditures();
        } catch (error) {
            toast.error('Failed to submit form.');
        }
    };

    const handleEdit = (data) => {
        setInitialValues((prevState) => ({
            ...prevState,
            _id: data?._id,
            date: data?.date,
            planAmount: data?.planAmount?.$numberDecimal,
            description: data?.description,
            title: data?.title,
        }));

    }
    const handleOpenModal = () => {
        setInitialValues((prevState) => ({
            ...prevState,
            ...defaultValues,
        }));
    };
    const handleActualHistory = (actualAmount) => {
        // console.log("actualExpenditures", actualAmount);
        setActualExpenditures(actualAmount)
    }


    const actualOffRef = useRef(null);
    const defaultActualValues = {
        amount: "",
        description: "",
    }
    const [initialActualValues, setInitialActualValues] = useState(defaultActualValues);
    const validationActualSchema = Yup.object({
        amount: Yup.number().required("Amount is required").min(1, "Amount must be greater than 0"),
        description: Yup.string().required("Description is required"),
    });
    const handleActualSubmit = async (values, { resetForm, setFieldValue }) => {
        // Log the form data after updating the values
        values.projectId = projectId
        values.expenditureId = expenditureId
        const save_url = `${config.baseUrl}report/saveActualExpenditure`;
        try {
            const result2 = await postRequest({
                url: save_url,
                data: values,
            });
            resetForm();
            setInitialActualValues((prevState) => ({
                ...prevState,
                ...defaultActualValues,
            }));

            // Manually trigger the close by targeting the offcanvas button
            const closeButton = actualOffRef.current.querySelector('.btn-close');
            if (closeButton) {
                closeButton.click(); // Trigger the close button's click event
            }

            toast.success(result2.data.message);
            getProjectExpenditures();
        } catch (error) {
            toast.error('Failed to submit form.');
        }
    };
    const handleActualOpenModal = (expandedId) => {
        setExpenditureId(expandedId)
        setInitialActualValues((prevState) => ({
            ...prevState,
            ...defaultActualValues,
        }));
    };

    return (
        <>
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-24">

                            <BackProjectButton />

                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a
                                        href="/"
                                        className="d-flex align-items-center gap-1 hover-text-primary"
                                    >
                                        <iconify-icon
                                            icon="solar:home-smile-angle-outline"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">P&amp;L </li>
                            </ul>
                            <TopTabs />
                            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        fill="currentColor"
                                        className="bi bi-stack"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                                        <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                                    </svg>{" "}
                                    {projectCode}
                                </h6>

                                <div className='d-flex align-items-center gap-3'>
                                    <ProjectPermissionHOC scope="view_report.expenditure.add">
                                        <button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                                            data-bs-toggle="offcanvas" data-bs-target="#special-access-fil" aria-controls="special-access-fil"
                                            onClick={handleOpenModal}
                                        >
                                            Add
                                        </button>
                                    </ProjectPermissionHOC>
                                </div>

                            </div>
                            <div className="table-responsive table-container" id="table-container">
                                {expenditures.length === 0
                                    ? 'No expenditure'
                                    :
                                    <table className="table basic-border-table mb-0 table-hover">
                                        <thead>
                                            <tr className="dnd-moved">
                                                <th className="w30px dragablefalse" draggable="false">
                                                    <input className="form-check-input" type="checkbox" />{" "}
                                                </th>
                                                <th>S.No.</th>
                                                <th>Name</th>
                                                <th>Plan Amt</th>
                                                <th>Actual Amt</th>
                                                <th>Date</th>
                                                <th>Description</th>
                                                <th className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody id="table-body">

                                            {expenditures.map((item, index) => {
                                                return (
                                                    <tr key={index} className="dnd-moved">
                                                        <td draggable="false">
                                                            <input className="form-check-input" type="checkbox" />
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{costFormat(item?.planAmount?.$numberDecimal || 0)}</td>
                                                        <td><div className='d-flex align-items-center justify-content-between gap-2'>
                                                            <span className='d-flex gap-1 text-nowrap'>
                                                                <ProjectPermissionHOC scope="view_report.expenditure.add">
                                                                    <button data-bs-toggle="offcanvas" data-bs-target="#add-actualModal" aria-controls="add-actualModal"
                                                                        onClick={() => handleActualOpenModal(item._id)}
                                                                    >
                                                                        {/* <i className='mdi mdi-plus text-neutral-600'></i> */}
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path><path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path></svg>
                                                                    </button>
                                                                </ProjectPermissionHOC>
                                                                {/* {costFormat(item?.amount?.$numberDecimal || 0)} */}
                                                                {costFormat(item?.expenditureActuals.reduce((sum, entry) => sum + parseFloat(entry?.amount?.$numberDecimal || 0), 0) || 0)}
                                                            </span>
                                                            <button data-bs-toggle="offcanvas" data-bs-target="#ViewHistoryPopup" aria-controls="ViewHistoryPopup"
                                                                onClick={() => handleActualHistory(item?.expenditureActuals)}
                                                            >
                                                                <i className='mdi mdi-eye mdi-24px text-primary-700'></i>
                                                            </button>
                                                        </div></td>
                                                        <td>{formatDate(item?.date)}</td>
                                                        <td>{item?.description} </td>
                                                        <td className='text-center'>
                                                            <ProjectPermissionHOC scope="view_report.expenditure.add">
                                                                <button onClick={() => handleEdit(item)} data-bs-toggle="offcanvas" data-bs-target="#special-access-fil" aria-controls="special-access-fil"><i className='mdi mdi-pencil text-primary-700 mdi-24px'></i></button>
                                                            </ProjectPermissionHOC>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className='text-end align-middle' colSpan={3}>Total</th>
                                                <th className='text-center align-middle' colSpan={1}><div className='text-primary-700'>{costFormat(expenditures.reduce((sum, exp) => sum + (isNaN(parseFloat(exp?.planAmount?.$numberDecimal)) ? 0 : parseFloat(exp?.planAmount?.$numberDecimal)), 0))}<span className='d-block font-12 text-neutral-400'>Total Plan Amt</span></div></th>
                                                <th className='text-center align-middle' colSpan={1}><div className='text-primary-700'>
                                                    {
                                                        costFormat(
                                                            expenditures.reduce((totalSum, expenditure) => {
                                                                return (
                                                                    totalSum +
                                                                    (expenditure?.expenditureActuals || []).reduce((sum, exp) => {
                                                                        return sum + (isNaN(parseFloat(exp?.amount?.$numberDecimal)) ? 0 : parseFloat(exp?.amount?.$numberDecimal));
                                                                    }, 0)
                                                                );
                                                            }, 0)
                                                        )
                                                    }
                                                    <span className='d-block font-12 text-neutral-400'>Total Actual Amt</span></div></th>
                                            </tr>
                                        </tfoot>

                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >


            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => {
                    return (
                        <Form>
                            <div
                                ref={offcanvasRef}
                                className="offcanvas offcanvas-end"
                                id="special-access-fil"
                                aria-labelledby="filterRightLayout"
                            >
                                <div className="offcanvas-header border-bottom">
                                    <h5 className="offcanvas-title" id="filterRightLayout">
                                        {initialValues?._id ? "Edit" : "Add"} expenditure
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close d-none"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="offcanvas-body">
                                    <div className="assignPopoHeight">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="mb-20 fontset001">
                                                    <label for="title" className="form-label fw-semibold text-primary-light text-sm mb-8"> Title <span className="text-danger-600">*</span></label>
                                                    <FormikInput
                                                        name="title"
                                                        id="title"
                                                        className="form-control"
                                                        placeholder="Enter Title"
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-20 fontset001">
                                                    <label for="date" className="form-label fw-semibold text-primary-light text-sm mb-8"> Date <span className="text-danger-600">*</span></label>
                                                    <DatePicker
                                                        id="date"
                                                        name="date"
                                                        type="date"
                                                        placeholder="MM/DD/YYYY"
                                                        className="form-control radius-8 bg-base"
                                                        value={initialValues?.date}
                                                        options={{
                                                            dateFormat: "m/d/Y", // Flatpickr format for MM/DD/YYYY
                                                            timezone: "local"
                                                        }}
                                                        onChange={(date) => setFieldValue('date', date)}
                                                        selected={values.date ? new Date(values.date) : null} // Ensure a valid Date object
                                                    />
                                                    {errors.date && touched.date && (
                                                        <div className="text-danger mt-1">{errors.date}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-20 fontset001">
                                                    <label for="planAmount" className="form-label fw-semibold text-primary-light text-sm mb-8"> Amount <span className="text-danger-600">*</span></label>
                                                    <FormikInput
                                                        name="planAmount"
                                                        id="planAmount"
                                                        className="form-control"
                                                        placeholder="Enter Amount"
                                                        defaultValue={""}
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-20 fontset001">
                                                    <label for="description" className="form-label fw-semibold text-primary-light text-sm mb-8"> Description <span className="text-danger-600">*</span></label>
                                                    <FormikTextarea
                                                        name="description"
                                                        id="description"
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="offcanvas-footer px-16">
                                    <button
                                        type="submit"
                                        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>

            <ViewHistory actualExpenditures={actualExpenditures} />


            <Formik
                enableReinitialize={true}
                initialValues={initialActualValues}
                validationSchema={validationActualSchema}
                onSubmit={handleActualSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => {
                    return (
                        <Form>
                            <div
                                ref={actualOffRef}
                                className="offcanvas offcanvas-end"
                                id="add-actualModal"
                                aria-labelledby="filterRightLayout2"
                            >
                                <div className="offcanvas-header border-bottom">
                                    <h5 className="offcanvas-title" id="filterRightLayout2">
                                        Add Actual Amount
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close d-none"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="offcanvas-body">
                                    <div className="assignPopoHeight">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="mb-20 fontset001">
                                                    <label for="amount" className="form-label fw-semibold text-primary-light text-sm mb-8"> Amount <span className="text-danger-600">*</span></label>
                                                    <FormikInput
                                                        name="amount"
                                                        id="amountA"
                                                        className="form-control"
                                                        placeholder="Enter Amount"
                                                        defaultValue={""}
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-20 fontset001">
                                                    <label for="descriptionA" className="form-label fw-semibold text-primary-light text-sm mb-8"> Description <span className="text-danger-600">*</span></label>
                                                    <FormikTextarea
                                                        name="description"
                                                        id="descriptionA"
                                                        className="form-control"
                                                        placeholder="Enter Description"
                                                        defaultValue={""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="offcanvas-footer px-16">
                                    <button
                                        type="submit"
                                        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}
export default ExpenditureContainer