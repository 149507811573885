import { useSelector, shallowEqual } from "react-redux"
import ProjectTree from "./ProjectsTree"
import { useState, useEffect } from "react"

const ProjectTreeContainer = ({ onSelectedNoTask }) => {
  const { assignedProjects } = useSelector(state => state.ProductionReducer, shallowEqual)
  const [isShowTree, setIsShowTree] = useState(false); // Initial state is false (closed)

  const handleToggle = () => {
    setIsShowTree((prevState) => !prevState); // Toggle the state
    onSelectedNoTask('')
  };

  useEffect(() => {
    // console.log('isShowTreeisShowTree', isShowTree);
    if (isShowTree) {
      setSelectedNoTask('');

    }

  }, [handleToggle])
  const [selectedNoTask, setSelectedNoTask] = useState(''); // Default value

  const handleNoTaskChange = (event) => {
    // console.log(event.target.value, "event.target.value");
    if (event.target.value) {
      setIsShowTree(false)
    }
    setSelectedNoTask(event.target.value);
    onSelectedNoTask(event.target.value)
  };

  return (

    <div className="accordion acodne110" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          {/* <button
            className={`accordion-button ${isShowTree ? 'collapsed' : ''} acoonm1`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded={isShowTree}
            aria-controls="collapseOne"
          >
            Select Project
          </button> */}
          <button
            className={`accordion-button ${isShowTree ? '' : 'collapsed'} acoonm1`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded={isShowTree}
            aria-controls="collapseOne"
            onClick={handleToggle} // Handle click event
          >
            Select Project
          </button>
        </h2>
        <div
          id="collapseOne"
          className={`accordion-collapse collapse ${isShowTree ? 'show' : 'hide'}`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div
              className="accordion sub-accordion"
              id="sub-accordionExample"
            >
              {
                assignedProjects.map((item, index) => {
                  return (
                    <ProjectTree key={index} item={item} />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div class="form-check checked-primary d-flex align-items-center gap-2">
        <input
          className="form-check-input"
          type="radio"
          name="notask"
          id="notask"
          value="notask"
          checked={selectedNoTask === "notask"}
          onChange={handleNoTaskChange}
        // onChange={(event) => handleNoTaskChange(event, setFieldValue)}
        />
        <label class="form-check-label line-height-1 fw-medium text-secondary-light" for="notask"> No Task  </label>
      </div>
    </div>
  )
}
export default ProjectTreeContainer