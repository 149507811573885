import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { moduleCreateAPI, moduleUploadAPI } from 'src/api/ModuleApi';
import { taskSearchAPI } from 'src/api/TaskApi';
const UpsertPopup = ({ onTaskSaved, projectId }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [moduleName, setModuleName] = useState('');
  const [userId, setUserId] = useState(null);
  const [selectedItem, setSelectedItem] = useState('');
  const [isBulkUpload,setBulkUpload] =useState(false)
  const [file,setFile] = useState([]);
  // console.log('selected Itemssss from dropdown', selectedItem);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUserId(storedUser?._id);
    }
  }, []);

  const searchTask = async () => {
    let body = {
      pageLength: 100,
      where: {
         projectId: projectId,
         taskStatus: 'Approved'
      }
    };
    try {
      const resp = await taskSearchAPI(body);
      console.log('Getting response from Search API', resp);
      setTasks(resp?.data?.data?.data || []);
    } catch (error) {
      console.log('Error fetching task data', error);
    }
  };

  useEffect(() => {
    searchTask();
  }, []);

  const createModule = async () => {
    if(moduleName === '') {
      toast.error('Please enter module name');      
      return
    }
    let body = {
      moduleName: moduleName,
      taskIds: selectedTasks.map(task => task._id), // Array of selected task IDs
      item: selectedItem,
      by: userId,
      projectId: projectId

    };
    try {
      const resp = await moduleCreateAPI(body);
      console.log('response from create module', resp?.data);
      if (onTaskSaved) {
        onTaskSaved();
      }
      setModuleName('')
      setSelectedTasks([''])
      toast.success('Module created successfully!');
    } catch (error) {
      console.log('error from create module', error);
    }
  };


  const handleTaskChange = (task) => {
    if (!selectedTasks.some(t => t._id === task._id)) {
      setSelectedTasks([...selectedTasks, task]);
      setDropdownOpen(false)
    }
  };


  const removeTask = (taskId) => {
    setSelectedTasks(selectedTasks.filter(task => task._id !== taskId));
  };


  const handleItemChange = (e) => {
    setSelectedItem(e.target.value);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const closeOffcanvas = () => {
    const closeButton = document.querySelector('#addModule .btn-close');
    if (closeButton) {
      closeButton.click(); // Trigger the click event on the close button
    }
  };
  const uploadModules = async () => {
    try {
      const formData = new FormData();
      formData.append('csvFile', file);
      formData.append('projectId', projectId);  
      const { data } = await moduleUploadAPI(formData);  
      const { duplicateModules = [], finalData = [] } = data.data || {};  
      // Handle duplicate modules
      if (duplicateModules.length > 0) {
        toast.error(`${duplicateModules.length} Duplicate modules found.`);
      }  
      // Handle successful module creation
      if (finalData.length > 0) {
        closeOffcanvas();
        toast.success(`${finalData.length} Module(s) created successfully!`);
        onTaskSaved();
      }
    } catch (error) {
      console.error('Error uploading modules:', error);
      toast.error('Failed to upload modules. Please try again.');
    }
  };
  
  return (
    <div
      className="offcanvas offcanvas-end filterSecPopup"
      id="addModule"
      aria-labelledby="addModuleSec"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="addModuleSec">
        {`${(!isBulkUpload)?"Add Bulk Module":"Add New Module"}`}
        </h5>
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="text-end px-16">
          <button
          className="text-decoration-underline bg-none border-0 text-primary-700 font-14 fw-bold text-md"
          type='button'
          onClick={()=>{setBulkUpload(!isBulkUpload)}}
          >
          {`${(!isBulkUpload)?"Add Bulk Module":"Create Single Module"}`}
          </button>
      </div>
      <div className="offcanvas-body pt-0 pb-0">
        {
          (!isBulkUpload)?
          <>
          <div className="assignPopoHeight">
          <div className="selectSec pt-0">
            <label htmlFor="">Module</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Module Name"
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
            />
          </div>
          <div className="selectSec pt-2">
            <label htmlFor="">Task</label>
            <div style={styles.customDropdown}>
              <div style={styles.selectedTasks} onClick={toggleDropdown}>
                {selectedTasks.map(task => (
                  <span key={task._id} style={styles.selectedTask}>
                    {task.taskName}
                    <button
                      type="button"
                      style={styles.removeTask}
                      onClick={() => removeTask(task._id)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
                <span style={styles.placeholder}>
                  {selectedTasks.length === 0 && 'Select Task'}
                </span>
              </div>
              {dropdownOpen && (
                <div style={styles.dropdownOptions}>
                  {tasks.map((task) => (
                    <div
                      key={task._id}
                      style={styles.dropdownOption}
                      onClick={() => handleTaskChange(task)}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                    >
                      {task.taskName}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="psstyky">
        <button
          type="button"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
          onClick={createModule}
        >
          Save
        </button>
      </div>
      </>
        :
        <>
         <div className="text-center mt-4">
            <h5 className="font-14 color1a">Import Module</h5>
            <p className="font-12 color99">
                If you have large number of module, you can upload them using a
                csv file
            </p>
            <a className="font-12 border bg-primary-600 py-1 px-16 text-white rounded-1 mb-10"
            href='/assets/doc/modules.csv'
            target='_blank'
            >
            Download a csv Sample{" "}
            <i className="mdi mdi-arrow-down colorb" />
            </a>
          </div>
        <div className="upload-image-wrapper d-flex align-items-center gap-3 w-100">
                <div className="uploaded-img d-none position-relative h-120-px w-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50">
                  <button
                    type="button"
                    className="uploaded-img__remove position-absolute top-0 end-0 z-1 text-2xxl line-height-1 me-8 mt-8 d-flex"
                  >
                    <iconify-icon
                      icon="radix-icons:cross-2"
                      className="text-xl text-danger-600"
                    />
                  </button>
                  <img
                    id="uploaded-img__preview"
                    className="w-100 h-100 object-fit-cover"
                    src="/assets/images/user.png"
                    alt="image"
                  />
                </div>
                <label
                  className="upload-file h-120-px border input-form-light radius-8 overflow-hidden border-dashed bg-neutral-50 bg-hover-neutral-200 d-flex align-items-center flex-column justify-content-center gap-1 w-100"
                  htmlFor="upload-file"
                >
                  <iconify-icon
                    icon="solar:camera-outline"
                    className="text-xl text-secondary-light"
                  />
                  <span className="fw-semibold text-secondary-light">Upload</span>
                  {/* <input id="upload-file" type="file" hidden="" accept='.csv'/> */}
                  <input id="upload-file" className="upload-fileCV" type="file" accept='.csv'
                  onChange={(event) => {
                    setFile(event.currentTarget.files[0]);
                    }}
                  />
                </label>
              </div>
              <div className="psstyky">
              <button
                type="button"
                className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                onClick={uploadModules}
              >
                Upload File
              </button>
            </div>
        </>
        }
      </div>
    </div>
  );
};


// Inline styles
const styles = {
  customDropdown: {
    position: 'relative',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '4px 12px',
    cursor: 'pointer',
  },
  selectedTasks: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '38px',
  },
  selectedTask: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
    padding: '4px 8px',
    marginRight: '4px',
    marginBottom: '4px',
  },
  removeTask: {
    marginLeft: '4px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '12px',
  },
  placeholder: {
    color: '#6c757d',
  },
  dropdownOptions: {
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    zIndex: '1000',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '4px',
  },
  dropdownOption: {
    padding: '8px 12px',
    cursor: 'pointer',
  },
  dropdownOptionHover: {
    backgroundColor: '#f8f9fa',
  }
};

export default UpsertPopup;
