import React, { useState, useEffect, useCallback, useRef } from 'react';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import UpsertPopup from './UpsertPopup';
import TaskModuleTab from '../TaskModuleTab';
import { moduleSearchAPI, moduleUpdateAPI } from 'src/api/ModuleApi';
import EditModule from './EditModule';
import { useParams } from 'react-router-dom';
import BackProjectButton from 'src/components/BackProjectButton';
import DatePickerComponent from 'src/components/DatePickerComponent';
import SearchInput from 'src/components/SearchInput';
import { shallowEqual, useSelector } from 'react-redux';
import { debounce } from 'lodash';



const ModuleContainer = () => {

  const {user} = useSelector((state) => state.auth,shallowEqual)
  const [module, setModule] = useState([])
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const { projectId } = useParams();
  const [popupVisible, setPopupVisible] = useState(false);
  const [taskNames, setTaskNames] = useState([]);
  const [taskNamesToRemove, setTaskNamesToRemove] = useState([]);
  const [modeuleId, setModuleId] = useState(null)
  const [where,setWhere] = useState({projectId:projectId});

  const searchModule = async () => {
    let body = {
      pageLength: 100,
      where
    }
    try {
      const resp = await moduleSearchAPI(body);  
      setModule(resp.data.data.data); 
    } catch (error) {
      console.log('Error fetching task data', error);
    }
  };

  useEffect(() => {
    searchModule();
  }, [where]);


  const handleTaskCountClick = (taskIds, moduleId) => {
    const names = taskIds.map(task => task.taskName);
    setTaskNames(names);
    setTaskNamesToRemove(taskIds.map(task => task._id));
    setModuleId(moduleId); // Capture the moduleId
    setPopupVisible(true);
  };

  const removeTaskName = async (taskIdToRemove) => {

    const updatedTaskNamesToRemove = taskNamesToRemove.filter(id => id !== taskIdToRemove && id !== undefined);
    const updatedTaskNames = taskNames.filter((_, index) => taskNamesToRemove[index] !== taskIdToRemove);
    setTaskNames(updatedTaskNames);
    setTaskNamesToRemove(updatedTaskNamesToRemove);
    const validTaskIds = updatedTaskNamesToRemove.filter(id => id !== undefined);
    const data = {
      taskIds: validTaskIds,
      by: user._id,
      projectId: projectId
    };
    console.log('body of remove data', data);
    try {
      const resp = await moduleUpdateAPI({ moduleId: modeuleId, data });
      console.log('response from the update API', resp?.data?.data?.data);
      searchModule();
    } catch (error) {
      console.log('error from updateTask', error);
    }

  };


  const closePopup = () => {
    setPopupVisible(false);
    setTaskNames([]);
    setTaskNamesToRemove([]);
  };
  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) { 
      setWhere({...where, ts: { $gte: selectedDates[0], $lte: selectedDates[1] } })
    }
  }
  const handleTextSearch = useCallback(
    debounce((searchKey, setWhere) => {
      const whereClauseKeysPaths = ['moduleName'];
      const whereWithOrCondition = {
        $or: whereClauseKeysPaths.map((keyPath) => ({ 
          [keyPath]: {
            $regex: searchKey,
            $options: 'i',
          },
        })),
      }; 
      setWhere({...where,...whereWithOrCondition});
    }, 300),
    [where]
  );
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    handleTextSearch(searchKey, setWhere);
  };

   
 // Table Scroll Code Start
const elementRef = useRef(null);
const [isScrollable, setIsScrollable] = useState(false);
const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

useEffect(() => {
  const handleScroll = () => {
    // Clear the previous timeout to prevent multiple updates
    clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(() => {
      if (elementRef.current) {
        const { top, bottom } = elementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the table <thead> is within the viewport range
        const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
        setIsScrollable(shouldScroll);
      }
    }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
    clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
  };
}, []);
// Table Scroll Code Start


  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              
              <BackProjectButton/> 
               
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="#"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Modual Client Details</li>
              </ul>
              <TaskModuleTab />
              <div className="d-inline-flex gap-2 align-items-center py-16 postionTop-0 bg-white z-3">
                {/* Search start */}
                <SearchInput handleSearchInputChange={handleSearchInputChange}/>
                 
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16  postionTop-0">
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">
                  Module Details
                </h6>
                <div className="d-flex align-items-center gap-2">
                  {/* <a
                    href=""
                    className="btn btn-sm bgpnk"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#addTaskModual"
                    aria-controls="addTaskModual"
                  >
                    Add Task
                  </a> */}
                  <a
                    href=""
                    className="btn btn-sm btn-primary-600"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#addModule"
                    aria-controls="addModule"
                  >
                    <i className="ri-add-line" /> Add Module
                  </a>
                </div>
              </div>
              <div className={`table-body-scrollable topctumsetTask ${isScrollable ? 'scrollable' : ''}`}>
              <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr className="dnd-moved"> 
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th className="">Module </th>
                      <th className="text-center">Task Count </th>
                      {/* <th className="text-center">Item </th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {module?.map((task, index) => (
                      <tr className="dnd-moved"> 
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>
                          <div className="text-primary-600 fw-bold">{task.moduleName}</div>
                        </td>
                        <td
                          className="text-center"
                          onClick={() => handleTaskCountClick(task.taskIds, task?._id)}
                        >
                          {task?.taskIds?.length}
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#EditModule"
                            aria-controls="EditModule"
                            className="btn mx-auto rounded-pill btn-primary-900 radius-8 px-20 py-2 d-flex align-items-center gap-2"
                            onClick={() => setSelectedTaskId(task)}
                          >
                            Edit{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-pencil"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                            </svg>{" "}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupVisible && (
        <>
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            zIndex: 999,
            backdropFilter: 'blur(1px)'
          }}></div>

          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            zIndex: 1000,
            width: '300px',
            maxHeight: '400px',
            overflowY: 'auto'
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <h4 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }}>Task</h4>
              <button onClick={closePopup} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '18px' }}>&times;</button>
            </div>
            <ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: '10px' }}>
              {taskNames.map((name, index) => (
                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                  <span>{name}</span>
                  <button
                    onClick={() => removeTaskName(taskNamesToRemove[index])}
                    style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer', fontSize: '18px' }}
                  >
                    &times;
                  </button>
                </li>
              ))}
            </ul>
            {/* <a href="#" style={{ color: '#0073ea', textDecoration: 'none', fontWeight: 'bold' }}>Add Task</a> */}
          </div>
        </>
      )}

      <UpsertPopup onTaskSaved={searchModule} projectId={projectId} />
      <FilterPopup />
      <ManageColumn />
      <EditModule moduleId={selectedTaskId} onTaskSaved={searchModule} projectId={projectId} />
    </>
  )
}

export default ModuleContainer