import { useState, useEffect } from 'react';

const SummaryInvoiceFilter = ({ item, onFilterChange }) => {
    const [filters, setFilters] = useState({
        projectCode: '',
        clientName: '',
        netStartAmount: '',
        netEndAmount: '',
    });

    const getUniqueClientsAndProjects = (data) => {
        if (!Array.isArray(data)) {
            throw new Error("Invalid input: data must be an array");
        }

        const clients = [];
        const projects = [];
        const seenClients = new Set();
        const seenProjects = new Set();

        data.forEach((item) => {
            const { clientId, ClientRegisteredName, projectId, projectCode } = item;

            // Add unique client
            if (!seenClients.has(clientId)) {
                seenClients.add(clientId);
                clients.push({
                    value: clientId,
                    label: ClientRegisteredName,
                });
            }

            // Add unique project
            if (!seenProjects.has(projectId)) {
                seenProjects.add(projectId);
                projects.push({
                    value: projectId,
                    label: projectCode,
                });
            }
        });

        // Sort clients by ClientRegisteredName (ascending)
        clients.sort((a, b) => a.label.localeCompare(b.label));

        // Sort projects by projectCode (ascending)
        projects.sort((a, b) => a.label.localeCompare(b.label));

        return { client: clients, project: projects };
    };

    // const options = []
    const options = getUniqueClientsAndProjects(item || [])

    const handleClear = () => {
        setFilters({
            projectCode: '',
            clientName: '',
            netStartAmount: '',
            netEndAmount: '',
        });
    };
    useEffect(() => {
        handleApplyFilters()
    }, [handleClear]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevState => ({ ...prevState, [name]: value }));
    };

    // Call the parent callback to pass the updated filters
    const handleApplyFilters = () => {
        if (onFilterChange) {
            onFilterChange(filters); // Pass updated filters to the parent
        }
    };

    return (
        <div
            className="offcanvas offcanvas-end"
            id="SummaryInvoiceFilter"
            aria-labelledby="filterRightLayout"
        >
            <div className="offcanvas-header border-bottom d-flex justify-content-between align-items-center">
                <h5 className="offcanvas-title" id="filterRightLayout">
                    Filter
                </h5>
                <button
                    type="button"
                    className="btn-close d-none"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
                <button
                    className="btn rounded-pill text-primary-600 radius-8 px-20 py-11"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={handleClear}
                >
                    Clear
                </button>
            </div>
            <div className="offcanvas-body">
                <div className="assignPopoHeight">
                    <div className="mb-8">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Project Code
                        </label>
                        <select
                            name="projectCode"
                            value={filters.projectCode}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value="">Select Project Code</option>
                            {options?.project.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-8">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Client Name
                        </label>
                        <select
                            name="clientName"
                            value={filters.clientName}
                            onChange={handleChange}
                            className="form-select"
                        >
                            <option value="">Select Client Name</option>
                            {options?.client.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="selectSec pt-0">
                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                            Net Amount
                        </label>
                        <div className="costSec">
                            <div className="costinputSec">
                                <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">
                                    ₹
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    name="netStartAmount"
                                    value={filters.netStartAmount}
                                    onChange={handleChange}
                                />

                            </div>
                            <div className="costinputSec">
                                <input
                                    type="number"
                                    min={0}
                                    name="netEndAmount"
                                    value={filters.netEndAmount}
                                    onChange={handleChange}
                                />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas-footer px-16">
                <button onClick={handleApplyFilters}
                    type="button" data-bs-dismiss="offcanvas"
                    className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                >
                    Save Filter
                </button>
            </div>
        </div>
    );
};

export default SummaryInvoiceFilter;
