import React from 'react';
import { costFormat } from 'src/utils/CommonUtils';

const StatusCard = ({ amount, status, color, className = "col-xxl-2 col-sm-6 pe-0" }) => {
  return (
    <div className={className}>
      <div className="card-body p-10 bg-base border h-100 d-flex flex-column justify-content-center radius-10">
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-1 mb-8">
          <div>
            <div className="fw-semibold text-primary-light mb-1" style={{fontSize: '16px'}}>{costFormat(amount)}</div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-1">
          <span className={`w-8-px h-8-px bg-${color}-600 rounded-circle`}></span>
          <span className="text-neutral-800 fw-medium" style={{fontSize: '12px'}}>{status}</span>
        </div>
      </div>
    </div>
  );
};

export default StatusCard;
