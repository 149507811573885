export const CLIENT_PAYMENT_DETAILS_ACTIONS = {
    CLIENT_PAYMENT_DETAILS_SEARCH: 'CLIENT_PAYMENT_DETAILS_SEARCH',
    CLIENT_PAYMENT_DETAILS_SEARCH_SUCCESS: 'CLIENT_PAYMENT_DETAILS_SEARCH_SUCCESS',
    CLIENT_PAYMENT_DETAILS_SEARCH_ERROR: 'CLIENT_PAYMENT_DETAILS_SEARCH_ERROR',

    CLIENT_PAYMENT_DETAILS_UPSERT: 'CLIENT_PAYMENT_DETAILS_UPSERT',
    CLIENT_PAYMENT_DETAILS_UPSERT_SUCCESS: 'CLIENT_PAYMENT_DETAILS_UPSERT_SUCCESS',
    CLIENT_PAYMENT_DETAILS_UPSERT_ERROR: 'CLIENT_PAYMENT_DETAILS_UPSERT_ERROR',



    CLIENT_PAYMENT_DETAILS_UPLOAD: 'CLIENT_PAYMENT_DETAILS_UPLOAD',
    CLIENT_PAYMENT_DETAILS_UPLOAD_SUCCESS: 'CLIENT_PAYMENT_DETAILS_UPLOAD_SUCCESS',
    CLIENT_PAYMENT_DETAILS_UPLOAD_ERROR: 'CLIENT_PAYMENT_DETAILS_UPLOAD_ERROR',

    CLIENT_PAYMENT_DETAILS_EDIT: 'CLIENT_PAYMENT_DETAILS_EDIT',

    CLIENT_PAYMENT_DETAILS_RESET: 'CLIENT_PAYMENT_DETAILS_RESET',
};

export const clientPaymentDetailsResetAction = () => ({
    type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_RESET,
});

export const clientPaymentDetailsSearchAction = (payload) => ({
    type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH,
    ...payload,
});

export const clientPaymentDetailsEditAction = (payload) => ({
    type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_EDIT,
    ...payload,
});

export const clientPaymentDetailsUpsertAction = (payload) => ({
    type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPSERT,
    ...payload,
});
export const clientPaymentDetailsUploadAction = (payload) => ({
    type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPLOAD,
    ...payload,
});

export default {
    CLIENT_PAYMENT_DETAILS_ACTIONS,
    clientPaymentDetailsSearchAction,
    clientPaymentDetailsUpsertAction,
    clientPaymentDetailsEditAction,
    clientPaymentDetailsUploadAction,
};
