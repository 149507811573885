import React, { useRef, useEffect, useState } from 'react';
import InfoCard from './InfoCard';
import InvoiceTable from './InvoiceTable';
import SummaryInvoiceTable from './SummaryInvoiceTable';

const getColumns = (status) => {
  const baseColumns = [
    { id: 'srNo', label: 'Sr. No.', visible: true },
    { id: 'clientInvoiceNo', label: 'Invoice Number', visible: true },
    { id: 'clientName', label: 'Customer Name', visible: true },
    { id: 'taxableAmount', label: 'Taxable Amount', visible: true },
    { id: 'gstAmount', label: 'GST Amount', visible: true },
    { id: 'totalCostInr', label: 'Total Amount', visible: true },
    { id: 'invoiceDate', label: 'Invoice Date', visible: true },
    { id: 'raisedBy', label: 'Raised By', visible: false },
    { id: 'action', label: 'Action', visible: true },
    { id: 'notes', label: 'Notes', visible: false },
  ];

  // Define additional columns for specific statuses
  const additionalColumnsByStatus = {
    Pending: [
      { id: 'paymentStatus', label: 'Payment Status', visible: true },
      { id: 'dueDays', label: 'Due Days', visible: false },
      { id: 'rollbacked', label: 'Rollbacked', visible: false },
    ],
    Paid: [
      { id: 'paidDate', label: 'Paid Date', visible: true },
      { id: 'rollbacked', label: 'Rollbacked', visible: false },
    ],
  };

  // Access the columns for a specific status
  const storedData = JSON.parse(localStorage.getItem('invoiceTableColumn')) || {};
  const columnsForStatus = storedData[status] || [];
  if (columnsForStatus.length) {
    return columnsForStatus
  }

  // Append additional columns based on the status
  const additionalColumns = additionalColumnsByStatus[status] || [];
  return [
    ...baseColumns.slice(0, 7),
    ...additionalColumns,
    ...baseColumns.slice(7),
  ];
};

const InvoiceComponent = ({ item, actionClick }) => {
  // Initialize activeTab with 0 to make the first tab active by default
  const [activeTab, setActiveTab] = useState(0);

  const handleCardClick = (index) => {
    setActiveTab(index === activeTab ? null : index); // Toggle the same tab or set a new one
  };

  const [showSummaryTable, setShowSummaryTable] = useState(false);

  // Function to handle showing the summary table
  const handleSummaryClick = () => {
    setShowSummaryTable(true);
  };


  // Function to handle hiding the summary table
  const handleSummaryClick2 = () => {
    setShowSummaryTable(false);
  };

  const calculateDueNondue = (data) => {
    const currentDate = new Date();

    let dueSum = 0.0;
    let nonDueSum = 0.0;
    let dueTotal = 0;
    let nonDueTotal = 0;

    data.forEach((invoice) => {
      const dueDate = new Date(invoice.dueDate);
      const totalCost = parseFloat(invoice.totalCostInr.$numberDecimal);

      if (currentDate > dueDate) {
        dueSum += totalCost;
        dueTotal += 1;
      } else {
        nonDueSum += totalCost;
        nonDueTotal += 1;
      }
    });

    return { dueSum, nonDueSum, dueTotal, nonDueTotal };
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 despset" style={{ display: showSummaryTable ? 'none' : 'block' }}>
          {/* Rendering InfoCards with click behavior to toggle the accordion */}
          <div className={`d-flex align-items-center justify-content-between gap-4 setbreaddrs ${item?.status.toLowerCase()}Invoice`}>
            {item?.groupOrg.map((card, index) => {
              const { dueSum, nonDueSum, dueTotal, nonDueTotal } = calculateDueNondue(card.data);
              return (
                <InfoCard
                  key={index}
                  title={card.organizationType}
                  totalAmount={card.total}
                  dueAmount={dueSum}
                  nonDueAmount={nonDueSum}
                  totalTransactions={card.data.length}
                  dueTransactions={dueTotal}
                  nonDueTransactions={nonDueTotal}
                  onClick={() => handleCardClick(index)}
                  isActive={activeTab === index} // Pass active state to style it differently
                />
              )
            })}
          </div>

          {/* Conditionally rendering the content (InvoiceTable) based on active tab */}
          {item?.groupOrg.map((row, index) => {
            return activeTab === index && (
              <div key={index} className={`col-md-12 mt-24 ${row?.organizationType}-invoice`}>
                <InvoiceTable
                  status={item?.status}
                  data={row?.data}
                  tableTitle={row?.organizationType}
                  initialColumns={getColumns(item?.status)}
                  onClick={handleSummaryClick}
                  actionClick={actionClick} />
              </div>
            );
          })}
        </div>

        <div className={`col-md-12 SummaryInvoiceTable ${showSummaryTable ? '' : 'd-none'}`}>
          <SummaryInvoiceTable onClick={handleSummaryClick2} item={item} />
        </div>
      </div>
    </>
  );
};

export default InvoiceComponent;
