import React, { useState, useEffect } from "react";
import SearchInput from "src/components/SearchInput";
import MonthPicker from "src/components/MonthPicker";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { clientInvoiceSearchAction, clientInvoiceUpsertAction } from './actions/ClientInvoiceActions';
import InvoiceComponent from "./InvoiceComponent";
import { toast } from 'react-toastify';
import InvoiceActionModal from "./InvoiceActionModal";
import { Link } from "react-router-dom";

const PendingPaidCancelled = ({ total = {} }) => {
    const [activeTab, setActiveTab] = useState("pending-invoice"); // Default active tab
    const [searchQuery, setSearchTerm] = useState('');

    const dispatch = useDispatch();
    const {
        search,
    } = useSelector((state) => state.ClientInvoiceReducer, shallowEqual);
    const [pageNo, setPageNo] = useState(0);
    const [data, setData] = useState([]);
    const [filterdData, setFilterdData] = useState([]);

    const clientInvoiceSearch = () => {
        const payload = {
            where,
            currentPage: pageNo,
            autopopulate: true,
            fullTextSearch: true,
        }
        dispatch(clientInvoiceSearchAction(payload));
    }

    const [where, setWhere] = useState({

    });
    useEffect(() => {
        clientInvoiceSearch()
    }, [where, pageNo])

    useEffect(() => {
        setData(search?.data?.data)
    }, [search?.data?.data])

    const handleChangeDate = (date) => {
        setWhere(prevState => ({
            ...prevState, // Spread the existing state
            $expr: {
                $and: [
                    { $eq: [{ $year: "$invoiceDate" }, date.getFullYear()] }, // Match year
                    { $eq: [{ $month: "$invoiceDate" }, (date.getMonth() + 1)] }, // Match month
                ]
            }
        }));
    }

    const predefinedStatuses = [
        { status: "Pending", totalInvoice: 0, groupOrg: [] },
        { status: "Paid", totalInvoice: 0, groupOrg: [] },
        { status: "Cancelled", totalInvoice: 0, groupOrg: [] },
    ];

    const groupByStatusAndOrgType = (data) => {

        // Group data by status, then by organizationType
        const grouped = data.reduce((acc, item) => {
            const { status, organizationType, totalCostInr } = item;

            if (!acc[status]) {
                acc[status] = { orgGroups: {}, total: 0, totalInvoice: 0 };
            }

            const itemCost = parseFloat(totalCostInr.$numberDecimal || totalCostInr);

            // Increment total invoices for the status
            acc[status].totalInvoice += 1;

            // Add to total for the status
            acc[status].total += itemCost;

            // Add to organization type within the status
            if (!acc[status].orgGroups[organizationType]) {
                acc[status].orgGroups[organizationType] = { total: 0, data: [] };
            }

            acc[status].orgGroups[organizationType].total += itemCost;
            acc[status].orgGroups[organizationType].data.push(item);

            return acc;
        }, {});
        for (let key in grouped) {
            if (grouped.hasOwnProperty(key)) {
                const statusExists = predefinedStatuses.some(statusObj => statusObj.status === key);

                // If the status doesn't exist in predefinedStatuses, add it
                if (!statusExists) {
                    predefinedStatuses.push({ status: key, totalInvoice: 0, groupOrg: [] });
                }
            }
        }

        // Start with the predefined statuses
        const result = predefinedStatuses.map((statusObj) => ({ ...statusObj }));


        // Populate the result array with grouped data
        result.forEach((statusObj) => {
            const groupedStatus = grouped[statusObj.status];
            if (groupedStatus) {
                const { orgGroups, total, totalInvoice } = groupedStatus;

                statusObj.totalInvoice = totalInvoice;
                statusObj.groupOrg = [
                    {
                        organizationType: "Total",
                        total: total.toFixed(2),
                        data: Object.values(orgGroups).flatMap((group) => group.data),
                    },
                    ...Object.entries(orgGroups).map(([organizationType, { total, data }]) => ({
                        organizationType,
                        total: total.toFixed(2),
                        data,
                    })),
                ];
            }
        });

        return result;
    };

    useEffect(() => {
        const dataStore = data || []
        const trimmedQuery = (typeof searchQuery === 'string' ? searchQuery : '').trim().toLowerCase();
        const filterdData = trimmedQuery
            ? dataStore.filter((item) =>
                // Safely check if the properties are defined before calling .toLowerCase()
                (item.clientInvoiceNo && item.clientInvoiceNo.toLowerCase().includes(trimmedQuery)) ||
                (item.clientName && item.clientName.toLowerCase().includes(trimmedQuery))
            )
            : dataStore; // Return all data if the searchQuery is empty
        setFilterdData(groupByStatusAndOrgType(filterdData))
    }, [data, searchQuery])

    // Handle click and update the active state
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const [editId, setEditID] = useState(null);
    const [editData, setEditData] = useState(null);
    const actionClick = async (data, id) => {
        // const _id=id
        setEditData(data)
        setEditID(id)
        if (data?.status === "Paid" && !data?.rollback) {
            handleActionSubmitWrapper(data, id)
        }
    }
    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleActionSubmitWrapper = async (data, _id) => {
        try {
            dispatch(clientInvoiceUpsertAction({
                _id,
                data
            }));

        } catch (error) {
            toast.error('Failed to submit form.');
        }
    }

    const handleActionSubmit = async (data) => {
        if (editId) {
            // setMessage(data)
            if (editData?.status === "Cancelled") {
                editData.cancelledReason = data?.message
            } else if (editData?.rollback === true) {
                editData.rollbackReason = data?.message
            }
            handleActionSubmitWrapper(editData, editId)
        }
    };

    return (
        <div>
            <div className="card h-100">
                <div className="card-body p-24">
                    <div className="border-bottom mb-24 pb-16">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="">
                                <h2 className="fw-semibold mb-16 font-24">
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                            <g data-name="20-Arrow Left">
                                                <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0z" />
                                                <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                                            </g>
                                        </svg>
                                    </button>{" "}
                                    Invoice
                                </h2>
                                <ul className="d-flex align-items-center gap-2">
                                    <li className="fw-medium">
                                        <a
                                            href="/"
                                            className="d-flex align-items-center gap-1 hover-text-primary"
                                        >
                                            <iconify-icon
                                                icon="solar:home-smile-angle-outline"
                                                className="icon text-lg"
                                            ></iconify-icon>
                                            Home
                                        </a>
                                    </li>
                                    <li>-</li>
                                    <li className="fw-medium">Invoice</li>
                                </ul>
                            </div>
                         
                        </div>
                        <div className="col-md-12 d-flex justify-content-between align-items-center pt-24">

                            {/* <InvoiceButtons total={totalCount} /> */}
                            <div className="d-flex flex-wrap align-items-center gap-4 justify-content-end my-0 py-0 pt100">
                                <MonthPicker handleChangeDate={handleChangeDate} />
                            </div>
                            <div className="d-flex align-items-center gap-8"> <SearchInput handleSearchInputChange={handleSearchInputChange} />
                             <Link to="/invoice/create" className="btn btn-primary-600 radius-8 px-20 py-9">
                                                <i className="mdi mdi-plus"></i> Add
                                            </Link></div>
                        </div>
                    </div>
                    {/* Tabs Buttons */}
                    <div className="d-flex flex-wrap align-items-center gap-3 btnActiveLight">
                        {filterdData.map((item, index) => (
                            <button key={index}
                                type="button"
                                onClick={() => handleTabClick(`${item.status.toLowerCase()}-invoice`)}
                                className={`badge text-sm fw-semibold px-16 py-9 radius-4 text-white d-flex align-items-center gap-2 text-primary-light ${activeTab === `${item.status.toLowerCase()}-invoice` ? "active" : ""
                                    }`}
                            >
                                {item.status} Invoice
                                <span className="badge bg-neutral-900 text-base radius-5 text-xs">
                                    {item.totalInvoice}
                                </span>
                            </button>
                        ))}
                    </div>
                    {/* Tab Content */}
                    <div className="mt-24">
                        {filterdData.map((item, index) => {
                            return activeTab === `${item.status.toLowerCase()}-invoice` && (
                                <div key={index}>
                                    <InvoiceComponent item={item} actionClick={actionClick} />
                                    {/* Your Pending Invoice content goes here */}
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
            <InvoiceActionModal handleActionSubmit={handleActionSubmit} editData={editData} />
        </div >
    );
};

export default PendingPaidCancelled;
