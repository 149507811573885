import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import config from "src/config";
import { toast } from 'react-toastify';
import { postRequest } from 'src/utils/RequestUtil';
import FormikInput from 'src/components/formik/FormikInput';

// Validation Schema using Yup
const validationSchema = Yup.object({
    remark: Yup.string()
        .min(5, 'Remark must be at least 5 characters')
        .max(500, 'Remark must be less than or equal to 500 characters')
        .required('Remark is required'),
});

const RemarkForm = ({ projectId, refreshRemark }) => {
    const defaultValues = {
        remark: "",
    }
    const [initialValues, setInitialValues] = useState(defaultValues);
    const handleSubmit = async (values, { resetForm, setFieldValue }) => {
        // Log the form data after updating the values
        values.projectId = projectId
        const save_url = `${config.baseUrl}client/saveRemark`;
        try {
            const result = await postRequest({
                url: save_url,
                data: values,
            });
            resetForm();
            setInitialValues((prevState) => ({
                ...prevState,
                ...defaultValues,
            }))
            refreshRemark(result.data.data._id)
            toast.success(result.data.message);
        } catch (error) {
            toast.error('Failed to submit form.');
        }
    };
    return (
        <div>
            <h6>Submit a Remark</h6>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema} // Add Yup validation schema
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <div className=''>
                            <div className="remrnon01 d-flex flex-column">
                                <FormikInput
                                    id="remark"
                                    name="remark" className="border"
                                    placeholder="Type your Remark here"
                                />
                                <button
                                    type="submit"
                                    className="btn btn-primary-600 radius-6 px-14 py-6 text-sm remrnonBtn d-flex align-items-center gap-2"
                                >
                                    Send
                                    <svg
                                        width={20}
                                        height={20}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M22.1012 10.5616L2.75289 1.12336C2.58649 1.04219 2.40377 1 2.21862 1C1.5456 1 1 1.5456 1 2.21862V2.25379C1 2.41731 1.02005 2.58022 1.05971 2.73886L2.91568 10.1627C2.96637 10.3655 3.13782 10.5153 3.34554 10.5384L11.5031 11.4448C11.786 11.4762 12 11.7153 12 12C12 12.2847 11.786 12.5238 11.5031 12.5552L3.34554 13.4616C3.13782 13.4847 2.96637 13.6345 2.91568 13.8373L1.05971 21.2611C1.02005 21.4198 1 21.5827 1 21.7462V21.7814C1 22.4544 1.5456 23 2.21862 23C2.40377 23 2.58649 22.9578 2.75289 22.8766L22.1012 13.4384C22.6511 13.1702 23 12.6119 23 12C23 11.3881 22.6511 10.8298 22.1012 10.5616Z"
                                            fill="#F8F8F8"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RemarkForm;
