import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const vendorDeliverySearchAPI = function (data) {
    const url = `${config.baseUrl}vendorDelivery/search`;
    return postRequest({
        url,
        data,
    });
}; 
export const vendorDeliveryCreateAPI = function (data) {
    const url = `${config.baseUrl}vendorDelivery/save`;
    return postRequest({
        url,
        data,
    });
};
export const vendorDeliveryInvoiceCreateAPI = function (data) {
    const url = `${config.baseUrl}vendorInvoice/save`;
    return postRequest({
        url,
        data,
    });
};
export const vendorInvoicePaymentCreateAPI = function (data) {
    const url = `${config.baseUrl}vendorInvoice/payment`;
    return postRequest({
        url,
        data,
    });
};
export const vendorDeliveryInvoiceGetAPI = function ({_id}) {
    const url = `${config.baseUrl}vendorInvoice/get/${_id}`;
    return postRequest({
        url
    });
};

export const vendorDeliveryUpdateAPI = function ({
    vendorDeliveryId,
    data,
}) {
    const url = `${config.baseUrl}vendorDelivery/update/${vendorDeliveryId}`;
    return putRequest({
        url,
        data,
    });
};
export const vendorInvoiceUpdateAPI = function ({
    invoiceId,
    data,
}) {
    const url = `${config.baseUrl}vendorInvoice/update/${invoiceId}`;
    return putRequest({
        url,
        data,
    });
};
