import React, { useState, useEffect } from 'react';
import TopTabs from "../TopTabs"
import PAndLFilter from "./FIlter"
import ProjectProductionFilter from "./FIlter"
import ManagePAndLColum from "./ManageColoum"
import { useParams } from 'react-router-dom';
import { expendituresProjectSearchAPI, profitandlossProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';
import { shallowEqual, useSelector } from 'react-redux';
import { costFormat } from 'src/utils/CommonUtils';


const PAndLContainer = () => {

  const { projectId } = useParams();
  const [profitloss, setProfitLoss] = useState([])
  const [totalcost, setTotalCost] = useState()
  const [expenditureCost, setExpenditureCost] = useState(0)
  const [totalDuration, setTotalDuration] = useState()
  const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)
  const projectCode = (currentRecord?.RefClientId?.ClientProjectCode) ? `${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}` : ''


  const getProjectProfitLossData = async () => {
    let body = {
      pageLength: 100
    };
    try {
      const resp = await profitandlossProjectSearchAPI({ projectId, body });
      setProfitLoss(resp?.data?.data)
      const expenditures = await expendituresProjectSearchAPI({ projectId });
      // setExpenditureCost(expenditures?.data?.data?.[0]?.expenditureActuals
      //   ?.reduce((sum, entry) => sum + parseFloat(entry?.amount?.$numberDecimal || 0), 0))
      setExpenditureCost(
        expenditures?.data?.data
          ?.flatMap(expenditure => expenditure?.expenditureActuals || [])
          ?.reduce((sum, entry) => sum + parseFloat(entry?.amount?.$numberDecimal || 0), 0)
      );

    } catch (error) {
      console.error('error from profit and loss', error);
    }
  };
  useEffect(() => {
    getProjectProfitLossData();
  }, []);

  const netDelivered = profitloss?.clientDelivery?.reduce((sum, item) => sum + item?.totalSum, 0)
  // const netDelivered = profitloss?.ClientCost || 0
  const netCost = profitloss?.inHouseCost + profitloss?.inVendorCost + expenditureCost
  const profitInRupee = (netDelivered - netCost).toFixed(2)
  const profitandlosspercentage = netDelivered > 0 ? (((netDelivered - netCost) / netCost) * 100).toFixed(2) : '-∞'


  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">

              <BackProjectButton />

              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">P&amp;L </li>
              </ul>
              <TopTabs />
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  {projectCode}
                </h6>
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th>Details</th>
                      <th className="text-center">Count </th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    <tr className="dnd-moved">
                      <td draggable="false">
                        <input className="form-check-input" type="checkbox" />
                      </td>
                      <td>Net Delivered </td>
                      <td className="text-center">
                        <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                          {costFormat(netDelivered)}
                        </span>
                      </td>
                    </tr>
                    <tr className="dnd-moved">
                      <td draggable="false">
                        <input className="form-check-input" type="checkbox" />
                      </td>
                      <td>Inhouse Cost </td>
                      <td className="text-center">
                        <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                          {costFormat(profitloss?.inHouseCost)}
                        </span>
                      </td>
                    </tr>
                    <tr className="dnd-moved">
                      <td draggable="false">
                        <input className="form-check-input" type="checkbox" />
                      </td>
                      <td>Expenditure Cost </td>
                      <td className="text-center">
                        <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                          {costFormat(expenditureCost)}
                        </span>
                      </td>
                    </tr>
                    <tr className="dnd-moved">
                      <td draggable="false">
                        <input className="form-check-input" type="checkbox" />
                      </td>
                      <td>Vendor Cost </td>
                      <td className="text-center">
                        <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                          {costFormat(profitloss?.inVendorCost)}
                        </span>
                      </td>
                    </tr>
                    <tr className="dnd-moved">
                      <td draggable="false">
                        <input className="form-check-input" type="checkbox" />
                      </td>
                      <td>Net Cost</td>
                      <td className="text-center">
                        <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                          {costFormat(profitloss?.inHouseCost + profitloss?.inVendorCost + expenditureCost)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={1} />
                      <td colSpan={1} className="fw-bold">
                        Profit &amp; Loss
                      </td>
                      <td className="text-center text-success-500 fw-bold">{costFormat((netDelivered || 0) - ((profitloss?.inHouseCost || 0) + (profitloss?.inVendorCost || 0) + expenditureCost))}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} />
                      <td colSpan={1} className="fw-bold">
                        P&amp;L %
                      </td>
                      <td className="text-center text-success-500 fw-bold">{profitandlosspercentage} %</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PAndLFilter />
      <ManagePAndLColum />
    </>
  )
}
export default PAndLContainer