import React, { useState } from 'react';
import { costFormat, getMonthLabel } from 'src/utils/CommonUtils';

// Utility function to get initials
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const ClientWiseMonths = ({ clientWiseSummary }) => {
  const { result, ClientCommercialName, totalAmount } = clientWiseSummary;
  const [expandedMonth, setExpandedMonth] = useState(null);

  const toggleMonth = (monthIndex) => {
    setExpandedMonth((prev) => (prev === monthIndex ? null : monthIndex));
  };

  return (
    <div>
      <div className="modal fade cusmodl" id="ClientWiseMonths" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex flex-column justify-content-between pb-0 border-0 gap-1">
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="name d-flex align-items-center gap-2">
                  <span
                    className="client-initials me-2 rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: stringToColor(ClientCommercialName),
                      width: '40px',
                      height: '40px',
                      color: '#fff',
                      fontWeight: 'bold'
                    }}
                  >
                    {getInitials(ClientCommercialName)}
                  </span>
                  {ClientCommercialName}
                </div>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button>
              </div>
              <div className="d-flex align-items-center justify-content-end w-100">
                <div className="TotalAmount text-dblue fw-bold text-lg">{totalAmount}</div>
              </div>
            </div>
            <div className="modal-body pt-0">
              {result
                ?.sort((a, b) => b.yearMonth.localeCompare(a.yearMonth))
                ?.map((row, index) => (
                  <div key={index}>
                    <div
                      className={`d-flex justify-content-between align-items-center my-8 cursor-pointer ${expandedMonth === index ? 'active-class' : ''
                        }`}
                      onClick={() => toggleMonth(index)}
                    >
                      <span className="badge text-md fw-semibold border border bg-transparent px-20 py-11 rounded-pill text-secondary-light clssrted">
                        {getMonthLabel(row.month)} {row.year} <i className="mdi mdi-chevron-down mdi-18px"></i>
                      </span>
                      <span className="badge text-md fw-semibold border-0 bg-transparent px-20 py-9 radius-4 text-secondary-light">
                        {costFormat(row?.projects?.reduce((acc, item) => acc + item.totalAmount, 0))}
                      </span>
                    </div>

                    {expandedMonth === index && (
                      row?.projects
                        ?.sort((a, b) => a.ProjectCode.localeCompare(b.ProjectCode))
                        ?.map((item, p_index) => (
                          <div key={p_index} className="d-flex justify-content-between align-items-center my-8">
                            <span className="px-20">
                              {item.ProjectCode}
                            </span>
                            <span className="badge text-md fw-semibold border-0 bg-transparent px-20 py-9 radius-4 text-secondary-light">
                              {costFormat(item?.totalAmount)}
                            </span>
                          </div>
                        ))
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientWiseMonths;
