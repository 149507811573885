import {useState, useEffect} from 'react';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';

export default function  Pagination({...props}) {
    const[pages, setPages] = useState(0);
    const perPage = props.perPage ||SEARCH_RECORD_PAGE_LIMIT
    console.log('props.perPage', props);
    useEffect(() => {
        setPages(Math.ceil(props.total / perPage));
        return () => {}
    },[props.total])

    const handleClick = (e) => {
        e.preventDefault();
        if(e.target.innerText != 0){
            props.setPageNo(e.target.innerText - 1);
        }
    }

    const handlePrev = (e) => {
        e.preventDefault();
        if(props.pageNo > 0){
            props.setPageNo(props.pageNo - 1)
        }
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(props.pageNo + 1 < pages){
            props.setPageNo(props.pageNo + 1)
        }
    }

    const getPaginationGroup = () => {
        // Total pages calculated based on total count and items per page
        const totalPages = Math.ceil(props.total / perPage); // 'pages' is the total number of items      
        // Calculate the starting page for the current group
        const start = Math.floor(props.pageNo / perPage) * perPage;      
        // Return only the pages within the total count
        return new Array(Math.min(perPage, totalPages - start))
          .fill()
          .map((_, idx) => start + idx + 1);
      };
    
    return( 
          <ul className="pagination cstmpagntion my-24 justify-content-end d-flex flex-wrap align-items-center gap-2 justify-content-center mt-24">
            <li className="paginate_button page-item previous" id="DataTables_Table_0_previous" key="Previous">
                <a style={{ cursor: 'pointer' }}   className="page-link" onClick={handlePrev}>Previous</a>
            </li> 
            {getPaginationGroup().map((e, i) => 
                e<=pages ? <li className={`paginate_button page-item ${props.pageNo+1 == e ? 'active':''}`} key={i}>
                              <a  style={{ cursor: 'pointer' }}  className={`page-link ${props.pageNo+1 == e ? 'active':''}`} onClick={handleClick}>{e}</a>
                         </li> : ''
                    )}
           
            <li className="paginate_button page-item next" id="DataTables_Table_0_next">
                <a href="#"  className="page-link" onClick={handleNext} key="Next">Next</a>
            </li>
        </ul>
       
    )
}