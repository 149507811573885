import React from 'react'
import { getOrdinalSuffix } from 'src/utils/CommonUtils';

const OrdinalNumberDisplay = ({ number }) => {
    const suffix = getOrdinalSuffix(number).replace(/^\d+/, ''); // Extract suffix
    const baseNumber = parseInt(number, 10);

    return (
        // <span
        //     dangerouslySetInnerHTML={{ __html: ordinalNumber }}
        // />
        <>
            {baseNumber}
            <sup>{suffix}</sup>
        </>
    );
}

export default OrdinalNumberDisplay