import React, { useEffect, useRef, useState } from "react";
import PaymentDetailsSummary from "./PaymentDetailsSummary";
import ClientWise from "./ClientWise";
import ClientWiseMonths from "./ClientWiseMonths";
import { costFormat, getMonthLabel } from "src/utils/CommonUtils";
import config from "src/config";
import { postRequest } from 'src/utils/RequestUtil';

const SummaryInvoicePayment = ({ onClick }) => {

    const [rawData, setRawData] = useState([]);

    const invoiceSummary = async () => {
        const apiUrl = `${config.baseUrl}clientPaymentDetails/invoiceSummaries`;

        try {
            const apiData = await postRequest({
                url: apiUrl,
            });
            setRawData(apiData?.data?.data);
        } catch (error) {
            console.error("Error in fetching data", error);
        }
    }

    useEffect(() => {
        invoiceSummary()
    }, [])

    // Group and sum the totalAmount by year and month, and count unique clients
    const groupedDataMonthWise = rawData.reduce((acc, curr) => {
        // Create a unique key based on year and month
        const key = `${curr.year}-${curr.month}`;

        // If the key already exists, add the totalAmount and count the unique clientId, else create a new entry
        if (acc[key]) {
            acc[key].totalAmount += parseFloat(curr.totalAmount["$numberDecimal"]);
            acc[key].countClient.add(curr.clientId); // Add clientId directly
        } else {
            acc[key] = {
                totalAmount: parseFloat(curr.totalAmount["$numberDecimal"]),
                year: curr.year,
                month: curr.month,
                countClient: new Set([curr.clientId]) // Initialize a Set with the first clientId
            };
        }

        return acc;
    }, {});

    const groupedByClient = rawData.reduce((acc, curr) => {
        const clientId = curr.clientId;
        const durationKey = `${curr.year}-${curr.month}`; // Create a unique key for the duration

        if (acc[clientId]) {
            // If the client already exists, add the totalAmount
            acc[clientId].totalAmount += parseFloat(curr.totalAmount["$numberDecimal"]);

            // Ensure the duration is unique before adding
            if (!acc[clientId].durationKeys.has(durationKey)) {
                acc[clientId].durations.push({ month: curr.month, year: curr.year });
                acc[clientId].durationKeys.add(durationKey); // Track the unique key
            }
        } else {
            // If the client is new, create an entry
            acc[clientId] = {
                clientId: curr.clientId,
                ClientCommercialName: curr.ClientCommercialName,
                ClientProjectCode: curr.ClientProjectCode,
                ClientRegisteredName: curr.ClientRegisteredName,
                totalAmount: parseFloat(curr.totalAmount["$numberDecimal"]),
                durations: [
                    {
                        month: curr.month,
                        year: curr.year
                    }
                ],
                durationKeys: new Set([durationKey]) // Track unique duration keys
            };
        }

        return acc;
    }, {});

    // Convert the object to an array
    const groupedByClientArray = Object.values(groupedByClient)
        .map(client => {
            delete client.durationKeys; // Clean up the helper field
            return client;
        })
        .sort((a, b) => a.ClientProjectCode.localeCompare(b.ClientProjectCode)); // Sort alphabetically by ClientProjectCode

    const [data, setData] = useState([
        {
            sNo: "01",
            month: "February 2024",
            numberOfClient: "15",
            amount: "₹23591.01",
        },
        {
            sNo: "02",
            month: "March 2024",
            numberOfClient: "50",
            amount: "₹33591.01",
        },
        {
            sNo: "03",
            month: "April 2024",
            numberOfClient: "20",
            amount: "₹13591.01",
        },
    ]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [activeTab, setActiveTab] = useState("MonthWise"); // State to track active tab

    const handleSelectRow = (sNo) => {
        setSelectedRows(selectedRows.includes(sNo)
            ? selectedRows.filter((row) => row !== sNo)
            : [...selectedRows, sNo]);
    };

    const handleSelectAll = () => {
        setSelectedRows(selectedRows.length === data.length ? [] : data.map((row) => row.sNo));
    };

    const [paymentDetailsSummary, setPaymentDetailsSummary] = useState({ paymentData: [], totalAmount: 0, month: '' });
    const clientInvoiceMonth = (month, year, totalAmount) => {
        const paymentData = rawData
            .filter(item => item.year === year && item.month === month) // Filter for specific year and month
            .map(item => ({
                projectId: item.projectId,
                ClientCommercialName: item.ClientCommercialName,
                ClientRegisteredName: item.ClientRegisteredName,
                ProjectCode: item.ProjectCode,
                totalAmount: parseFloat(item.totalAmount["$numberDecimal"])
            }))
            .sort((a, b) => a.ProjectCode.localeCompare(b.ProjectCode)); // Sort by ProjectCode (ascending)

        setPaymentDetailsSummary((prevState) => ({
            ...prevState,
            paymentData,
            totalAmount,
            month: getMonthLabel(month) + ' ' + year,
        }));
    }

    const [clientWiseSummary, setClientWiseSummary] = useState({ result: [], totalAmount: 0, ClientCommercialName: '' });
    const handleClientMonth = (client) => {
        const { clientId, ClientCommercialName, totalAmount } = client

        const groupedData = rawData.reduce((acc, curr) => {
            if (curr.clientId === clientId) {
                const key = `${curr.year}-${curr.month}`; // Group by year and month

                if (!acc[key]) {
                    // Create a new group if it doesn't exist
                    acc[key] = {
                        // ClientCommercialName: curr.ClientCommercialName,
                        year: curr.year,
                        month: curr.month,
                        yearMonth: key,
                        projects: []
                    };
                }

                // Add the project to the group
                acc[key].projects.push({
                    totalAmount: parseFloat(curr.totalAmount["$numberDecimal"]),
                    ProjectCode: curr.ProjectCode
                });
            }
            return acc;
        }, {});

        // Convert the grouped object to an array
        setClientWiseSummary((prevState) => ({
            ...prevState,
            totalAmount,
            result: Object.values(groupedData),
            ClientCommercialName,
        }));


    }
    
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <button className="d-flex align-items-center gap-2 btn rounded-pill text-primary-light radius-8 pe-20 text-xl py-11" onClick={onClick}>
                    <i className="mdi mdi-arrow-left mdi-24px"></i> Summary
                </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="nav focus-tab nav-pills mb-16" id="pills-tab-two" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10 active"
                            id="pills-focus-MonthWise-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-focus-MonthWise"
                            type="button"
                            role="tab"
                            aria-controls="pills-focus-MonthWise"
                            aria-selected="true"
                            onClick={() => setActiveTab("MonthWise")} // Update active tab
                        >
                            Month Wise
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10"
                            id="pills-focus-ClientWise-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-focus-ClientWise"
                            type="button"
                            role="tab"
                            aria-controls="pills-focus-ClientWise"
                            aria-selected="false"
                            tabIndex={-1}
                            onClick={() => setActiveTab("ClientWise")} // Update active tab
                        >
                            Client Wise
                        </button>
                    </li>
                </ul>

                <div className="d-flex gap-4">
                   
                    <div className="card-body p-20 bg-base border h-100 d-flex flex-column justify-content-center radius-10 text-center">
                        <h6 className="fw-semibold text-primary-light mb-1">{costFormat(rawData?.reduce((acc, curr) => acc + parseFloat(curr.totalAmount["$numberDecimal"]), 0))}</h6>
                        <div className="d-flex align-items-center gap-2">
                            <span className="w-8-px h-8-px bg-lilac-600 rounded-circle"></span>
                            <span className="text-neutral-800 fw-medium">Total Amount</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab-content" id="pills-tab-twoContent">
                <div
                    className="tab-pane fade active show"
                    id="pills-focus-MonthWise"
                    role="tabpanel"
                    aria-labelledby="pills-focus-MonthWise-tab"
                    tabIndex={0}
                >
                    <div className="d-flex justify-content-between align-items-center pt-16 pb-16">
                        <h3 className="card-title">Payment Details Summary</h3>
                    </div>

                    <div className={`table-body-scrollable `}>
                        <table className="table basic-border-table mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Month</th>
                                    <th>Number of Clients</th>
                                    <th className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(groupedDataMonthWise).map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{getMonthLabel(row.month)} {row.year}</td>
                                        <td className="d-flex align-items-center gap-3">
                                            <button className="text-dblue" onClick={() => clientInvoiceMonth(row.month, row.year, costFormat(row.totalAmount))} data-bs-toggle="modal" data-bs-target="#PaymentDetailsSummary">
                                                <i className="mdi mdi-plus-circle-outline mdi-36px"></i>
                                            </button>
                                            {row.countClient.size}
                                        </td>
                                        <td className={`text-end ${parseFloat(row.totalAmount) < 0 ? 'text-red' : 'text-green'}`}>
                                            {costFormat(row.totalAmount)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    className="tab-pane fade"
                    id="pills-focus-ClientWise"
                    role="tabpanel"
                    aria-labelledby="pills-focus-ClientWise-tab"
                    tabIndex={0}
                >
                    {/* Pass the totalMonths to the ClientWise component */}
                    <ClientWise groupedByClientArray={groupedByClientArray} onClient={handleClientMonth} />
                </div>
            </div>

            {/* // monthwise modal */}
            <PaymentDetailsSummary paymentDetailsSummary={paymentDetailsSummary} />

            {/* clientwise modal */}
            <ClientWiseMonths clientWiseSummary={clientWiseSummary} />
        </>
    );
};

export default SummaryInvoicePayment;
