import React, { useEffect, useState } from "react";
import RemarkItem from "./RemarkItem";
import * as Yup from 'yup';
import RemarkForm from "./RemarkForm";
import { remarkProjectSearchAPI } from "src/api/ReportsAPI";
// Validation Schema using Yup
const validationSchema = Yup.object({
    remark: Yup.string()
        .min(5, 'Remark must be at least 5 characters')
        .max(200, 'Remark must be less than or equal to 200 characters')
        .required('Remark is required'),
});

const RemarkContainer = ({ projectId }) => {
    const [remarkList, setRemarkList] = useState([])
    const [refreshRemarkList, setRefreshRemarkList] = useState('')
    const getProjectRemarks = async () => {
        try {
            const resp = await remarkProjectSearchAPI({ projectId });
            setRemarkList(resp?.data?.data)
        } catch (error) {
            console.error('error from remark', error);
        }
    };

    useEffect(() => {
        getProjectRemarks();
    }, [projectId, refreshRemarkList]);

    const refreshRemark = (data) => {
        // console.log("refreshRemark", data);
        setRefreshRemarkList(data)

    }
    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n.remarkSearchSec {\n    border: 1px solid #D0D4E4;\n    border-radius: 8px;\n    padding: 12px 16px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.posiStyRemark {\n    position: sticky;\n    top: 0;\n    background-color: #ffff;\n    border-bottom: 1px solid #D0D4E4;\n    padding-bottom: 20px;\n    margin-bottom: 20px;\n z-index: 9; \n}\n\n.remarkSearchSec input {\n    border-right: 1px solid #D0D4E4;\n}\n\n.remarkSearchSec button svg {\n    margin-left: 10px;\n}\n\n.remarkContenProfile {\n    /* border-top: 1px solid #D0D4E4; */\n    /* margin-top: 20px; */\n}\n\n.remarkItemSec {\n    display: flex;\n    gap: 10px;\n    margin: 20px auto;\n}\n\n.remarkItemSec h6 {\n    font-size: 16px !important;\n    margin: 0;\n    display: flex;\n    justify-content: space-between;\n}\n\n\n.remarkItemSec h6 span {\n    font-size: 12px;\n    color: #737273;\n}\n\n.remarkItemSec p {\n    font-size: 14px;\n    background-color: #F5F5F5;\n    padding: 12px;\n    border-radius: 0 10px 10px 10px;\n    margin: 4px auto 0;\n}\n"
                }}
            />
            <div
                className="offcanvas offcanvas-end filterSecPopup"
                id="remarksec"
                aria-labelledby="remarkcontentsec"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="remarkcontentsec">
                        Remarks
                    </h5>
                    <button
                        type="button"
                        className="btn-close d-none"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body pt-0">
                    <div className="assignPopoHeight">
                        <div className="posiStyRemark">
                            <RemarkForm projectId={projectId} refreshRemark={refreshRemark} />
                        </div>
                        <div className="remarkContenProfile">
                            {remarkList.map((remark) => (
                                <RemarkItem
                                    key={remark?._id}
                                    profilePhoto={remark?.userId?.profilePicture}
                                    username={remark?.userId?.firstName + ' ' + remark?.userId?.lastName}
                                    date={remark?.ts}
                                    remark={remark?.remark}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RemarkContainer;
