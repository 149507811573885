import React, { useEffect, useRef, useState } from 'react';
import { formatDate, getInitials } from 'src/utils/utils';
import PaymentView from '../project-container/View/VendorDelivery/PaymentView';
import GeneratePaymentPopup from './GeneratePaymentPopup';
import ViewInvoiceDetailsModal from './ViewInvoiceDetailsModal';
import { vendorInvoiceUpdateAPI } from 'src/api/VendorDeliveryApi';


const getStatusIcon = (status) => {
  switch (status) {
    case "Payment Pending" || "Invoice Pending":
      return <i className="mdi mdi-clock mdi-24px"></i>;
    case "Unpaid":
      return <i className="mdi mdi-close-circle mdi-24px"></i>;
    case "Fully Paid":
      return <i className="mdi mdi-check-circle mdi-24px"></i>;
    case "Partially Paid":
      return <i className="mdi mdi-check-circle mdi-24px"></i>;
    default:
      return null;
  }
};

const VendorInvoiceTable = ({ data, total, selectedDelivery, setSelectedDelivery, setInvoicePayload,handleSearch }) => {  // States for multiple filters
  const [uploadedByFilter, setUploadedByFilter] = useState([]);
  const [vendorNameFilter, setVendorNameFilter] = useState([]);
  const [clientPathFilter, setClientPathFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  // Extract unique values for each filter dropdown
  const uniqueUploadedBy = [];//...new Set(data.map(item => item.userId.firstName))
  const uniqueVendorName = [];//...new Set(data.map(item => item.vendorId.firstName))
  const uniqueClientPath = [];//...new Set(data.map(item => item.productionSlug))
  const uniqueCategory = [];//...new Set(data.map(item => item.moduleId.moduleName))
  const uniqueStatus = ["Pending Payment", "Fully Paid", "Partially Paid", "Unpaid"];



  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  const handleInvoiceModal = async () => { };



  // Generic handler for checkbox selection in filters
  const handleCheckboxChange = (filterState, setFilterState, value) => {
    if (filterState.includes(value)) {
      setFilterState(filterState.filter(item => item !== value));
    } else {
      setFilterState([...filterState, value]);
    }
  };

  // Filter data based on selected filters
  useEffect(() => {
    const filtered = data?.filter(row => {
      const matchUploadedBy = uploadedByFilter.length ? uploadedByFilter.includes(row.invoiceMain.userId) : true;
      const matchVendorName = vendorNameFilter.length ? vendorNameFilter.includes(row.invoiceMain.vendorId) : true;
      const matchClientPath = clientPathFilter.length ? clientPathFilter.includes(row.productionSlug) : true;
      const matchCategory = categoryFilter.length ? categoryFilter.includes(row.moduleId.moduleName) : true;
      const matchStatus = statusFilter.length ? statusFilter.includes(row.active) : true;
  
      return matchUploadedBy && matchVendorName && matchClientPath && matchCategory && matchStatus;
    });
    setFilteredData(filtered);
  }, [data, uploadedByFilter, vendorNameFilter, clientPathFilter, categoryFilter, statusFilter]);


  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code End
  const handleCheckboxClick = (e, row) => {
    if (e.target.checked) {
      setSelectedDelivery([...selectedDelivery, row]);
    } else {
      setSelectedDelivery(selectedDelivery.filter(item => item !== row));
    }
  }
  const handleRowClick = (row) => {
    setSelectedDelivery([row]);
    var checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach(function (checkbox) {
      checkbox.checked = false;
    });
  }
  const handlePaymentStatus = async(row, status) => {
    setSelectedInvoice(row);
    setSelectedStatus(status);
    if(status!="Partially Paid" && status!="Fully Paid"){
      await vendorInvoiceUpdateAPI({invoiceId:row._id,data:{paymentStatus:status}});
      handleSearch();
    }
    // Update the row's payment status dynamically
    // const updatedData = filteredData.map((item) =>
    // item === row ? { ...item, paymentStatus: status } : item
    // );
    // setFilteredData(updatedData);
  }
  return (
    <>
      <div className={`table-body-scrollable mt-24 ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              <th className="align-middle">S.No.</th>
              <th className="align-middle">Invoice No</th>
              <th className="align-middle">Date</th>
              <th className="align-middle">Invoice Type</th>
              {/* Uploaded by Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Uploaded by
                  <div className="filter-container mb-0">
                    <div className="dropdown">
                      <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-filter-variant mdi-24px"></i>
                      </button>
                      <ul className="dropdown-menu border  dropdown-menu-end">
                        {uniqueUploadedBy.map((name, index) => (
                          <li key={index} className="dropdown-item">
                            <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={name}
                                id={`uploadedBy-${index}`}
                                checked={uploadedByFilter.includes(name)}
                                onChange={() => handleCheckboxChange(uploadedByFilter, setUploadedByFilter, name)}
                              />
                              <label className="form-check-label" htmlFor={`uploadedBy-${index}`}>
                                {name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </th>
              {/* Vendor Name Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Vendor Name
                  <div className="dropdown">
                    <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-filter-variant mdi-24px"></i>
                    </button>
                    <ul className="dropdown-menu border dropdown-menu-end">
                      {uniqueVendorName.map((name, index) => (
                        <li key={index} className="dropdown-item">
                          <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={name}
                              id={`vendorName-${index}`}
                              checked={vendorNameFilter.includes(name)}
                              onChange={() => handleCheckboxChange(vendorNameFilter, setVendorNameFilter, name)}
                            />
                            <label className="form-check-label" htmlFor={`vendorName-${index}`}>
                              {name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </th> 
              <th className="align-middle">Quantity</th> 
              <th className="align-middle">Total Amount</th>
              <th className="align-middle">Paid Amount</th>
              <th className="align-middle">Pending Amount</th>
              <th className="align-middle">View Details</th>
              {/* Status Dropdown */}
              <th className="align-middle">
                <div className="d-flex justify-content-between align-items-center">
                  Status
                  <div className="dropdown">
                    <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="mdi mdi-filter-variant mdi-24px"></i>
                    </button>
                    <ul className="dropdown-menu border  dropdown-menu-end">
                      {uniqueStatus.map((status, index) => (
                        <li key={index} className="dropdown-item">
                          <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={status}
                              id={`status-${index}`}
                              checked={statusFilter.includes(status)}
                              onChange={() => handleCheckboxChange(statusFilter, setStatusFilter, status)}
                            />
                            <label className="form-check-label" htmlFor={`status-${index}`}>
                              {status}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </th>
              {/* <th className="align-middle">Payment</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((row, index) => {
              const srNo = index + 1;
              // row.paymentStatus = "Pending Payment"
              row.statusClass =row?.paymentStatus?.toLowerCase().replace(/\s+/g, '-'); //"invoice-under-process";              
              const totalPaymentAmount = row.paymentDetails.reduce((total, item) => total + (item.amount || 0), 0); 
              const totalAmount  = (row.totalAmount || 0) - (row.taxAmount || 0); 
              row.pendingAmount=totalAmount - totalPaymentAmount;
              return (
                <tr key={index}>
                  <td>{srNo}</td>
                  <td>{row?.invoiceNo || "NA"}</td>
                  <td>{formatDate(row?.date)}</td>
                  <td>{(row.invoiceDetails.length > 1)?"Combined Invoice":"Single Invoice"}</td>
                  <td>
                    <div className="user-cell">
                      <span className="user-initials">{getInitials(`${row?.userId.firstName} ${row?.userId.lastName}`)}</span>
                      <span>{`${row?.userId.firstName} ${row?.userId.lastName}`}</span>
                    </div>
                  </td>
                  <td>
                    <div className="user-cell">
                      <span className="user-initials">{getInitials(`${row?.vendorId.firstName} ${row?.vendorId.lastName}`)}</span>
                      <span>{`${row?.vendorId.firstName} ${row?.vendorId.lastName}`}</span>
                    </div>
                  </td>                   
                  <td>{row.totalUnits}</td> 
                  <td>{totalAmount}</td>  
                  <td>
                  <div className="payment-cell">
                    <span>₹ {totalPaymentAmount}</span>
                    <button 
                    className="view-button px-10" 
                    data-bs-toggle="modal" 
                    data-bs-target="#PaymentView"
                    onClick={() => setSelectedInvoice(row)}
                    >
                      <i className="mdi mdi-eye mdi-24px text-dblue"></i></button>
                  </div>
                </td>
                  <td>{row.pendingAmount}</td>  
                  <td> 
                    <button 
                        className="view-button px-10 text-decoration-underline text-dblue"  
                        data-bs-toggle="modal" 
                        data-bs-target="#viewInvoiceListModal"
                        onClick={() => setSelectedInvoice(row)}
                        >
                        View Invoice
                   </button> 
                </td>
                  <td className={`bg ${row.statusClass}`}>
                    <div className="d-flex justify-content-between align-items-center">
                    <span className={`status-badge d-flex align-items-center gap-2 ${row.statusClass}`}>
                    {getStatusIcon(row.paymentStatus)}  {row.paymentStatus}
                  </span>
                      <div className="dropdown">
                        <button className="btn px-18 py-4 text-primary-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="mdi mdi-filter-variant mdi-24px"></i>
                        </button>
                        <ul className="dropdown-menu border  dropdown-menu-end">
                          {uniqueStatus.map((status, idx) => {
                            return(
                            <li key={idx} className="dropdown-item">
                              <div className="form-check d-flex align-items-center gap-2 cttbcheck">
                                <input
                                  className="form-check-input"
                                  name={`status-${index}`} // Use unique names for each row
                                  type="radio"
                                  value={status}
                                  id={`status-${index}-${idx}`}
                                  // defaultChecked={row.paymentStatus?.trim() === "Pending Payment"}
                                  // checked={row.paymentStatus.trim() === status.trim()}
                                  // onClick={() => handlePaymentStatus(row, status)} // Update state

                                  onClick={(e) => {
                                    if (e.target.value === "Fully Paid" || e.target.value === "Partially Paid") {
                                      handlePaymentStatus(row, e.target.value); 
                                          // Show modal manually
                                          const modalElement = document.getElementById('GeneratePaymentPopup');
                                          if (modalElement) {
                                          modalElement.classList.add('show');
                                          modalElement.style.display = 'block';
                                          modalElement.setAttribute('aria-hidden', 'false');
                                          document.body.classList.add('modal-open');
                                          }
                                    } else {
                                      handlePaymentStatus(row, e.target.value);
                                    }
                                  }}
                                  data-bs-toggle={status === "Fully Paid" || status === "Partially Paid" ? "modal" : ""}
                                  data-bs-target={status === "Fully Paid" || status === "Partially Paid" ? "#GeneratePaymentPopup" : ""} 
                                />
                                <label className="form-check-label" htmlFor={`status-${idx}`}>
                                  {status}
                                </label>
                              </div>
                            </li>
                          )})}
                        </ul>
                      </div>
                    </div>
                  </td>
                 
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <GeneratePaymentPopup 
      invoicePayload={selectedInvoice} 
      selectedStatus={selectedStatus} 
      handleSearch={handleSearch}
      />      
    <ViewInvoiceDetailsModal invoiceData={selectedInvoice}/>
    <PaymentView paymentDetails={selectedInvoice?.paymentDetails||[]} />

    </>
  );
};

export default VendorInvoiceTable;
