import React from 'react'
import TextEditor from './TextEditor'
import TopTabs from '../TopTabs'
import ProjectScopeForm from './ProjectScopeForm'
import BackProjectButton from 'src/components/BackProjectButton'
// import BackProjectButton from '../../../../components/BackProjectButton'
// import TopTabs from 'src/containers/project-container/View/TopTabs'
// import TextEditor from './TextEditor'

const ProjectScope = () => {
    return (
        <div>
            <div className="row">
                <div className="col-xxl-12 col-xl-12">
                    <div className="card h-100">
                        <div className="card-body p-24">
                            
                                <BackProjectButton />
                                
                            
                            <ul className="d-flex align-items-center gap-2">
                                <li className="fw-medium">
                                    <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                        Special Access
                                    </a>
                                </li>
                                <li>-</li>
                                <li className="fw-medium">Project Scope</li>
                            </ul>
                            <TopTabs />

                            {/* <TextEditor /> */}
                            <ProjectScopeForm />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProjectScope
