import React, { useState, useEffect, useRef } from 'react';
import TopTabs from "../TopTabs"
import SkillFilter from "./Filter"
import ManageSkillColum from "./ManageColum"
import { skillsSearchAPI } from 'src/api/ReportsAPI';
import { useParams } from 'react-router-dom';
import BackProjectButton from 'src/components/BackProjectButton';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import { shallowEqual, useSelector } from 'react-redux';
import ProjectPermissionHOC from 'src/hoc/ProjectPermissionHOC';
import { costFormat } from 'src/utils/CommonUtils';



const SkillWiseContainer = () => {

  const { projectId } = useParams();
  const [skills, setSkills] = useState([])
  const [totalcost, setTotalCost] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [search, setSearch] = useState('');
  const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
  const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)
  const projectCode = (currentRecord?.RefClientId?.ClientProjectCode) ? `${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}` : ''




  const getProjectSkillData = async () => {
    let body = {
      pageLength: 100
    };
    try {
      const resp = await skillsSearchAPI({ projectId, body });
      const skillsData = resp?.data?.data || [];
      // Filter skills based on departmentName
      const filterSkillsData = skillsData.filter(item =>
        item?.departmentName?.toLowerCase().includes(search.toLowerCase())
      );

      // Calculate total totalDuration and total cost for filtered data
      const totals = filterSkillsData.reduce((acc, item) => {
        acc.totalDuration += item.totalDuration || 0;
        acc.totalCost += item.totalFinalCost || 0;
        return acc;
      }, { totalDuration: 0, totalCost: 0 });

      // Set the filtered skills and totals in state
      setSkills(filterSkillsData);
      setTotalDuration(totals.totalDuration);
      setTotalCost(totals.totalCost);

    } catch (error) {
      console.log('error from project skill', error);
    }
  };

  useEffect(() => {
    getProjectSkillData();
  }, [search]);



  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setIsFlatPikerReset(false)
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });
      setSearch({ ...search, ts: { $gte: startDate, $lte: endDate } })
    }
  }
  const handleSearchInputChange = (event) => {
    setSearch(event.target.value);
  }

  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code End

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              
                <BackProjectButton />
               
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Department</li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16">
                {/* <SearchInput handleSearchInputChange={handleSearchInputChange}/> 
                <DatePickerComponent isFlatPikerReset={isFlatPikerReset} onDateRangeChange={handleDateRangeChange} />  */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  {projectCode}
                </h6>
              </div>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
                <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr className="dnd-moved">
                      {/* <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th> */}
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th className="">Department </th>
                      {/* <th className="">Cadre </th> */}
                      <th className="text-center">No. of Units </th>
                      <ProjectPermissionHOC scope="view_report.skill.cost">
                        <th className="text-center">Cost </th>
                      </ProjectPermissionHOC>
                    </tr>
                  </thead>
                  {skills?.map((skill, index) => (
                    <tbody id="table-body">
                      <tr className="dnd-moved">
                        {/* <td draggable="false">
                          <input className="form-check-input" type="checkbox" />
                        </td> */}
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td className="position-relative">

                          <div className="d-flex gap-2 align-items-center"><button
                            className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                            draggable="false"
                            data-bs-toggle="collapse"
                            data-bs-target={`#demo1${index}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                            </svg>
                          </button>
                            <a className="text-primary-600" href="javascript:void(0);">
                              {skill?.departmentName}
                            </a>
                          </div>
                          {/* <div className="text-primary-900 ps-5 fw-bold">
                            <span className="imgAbst bg-primary-900">
                              <img src="/assets/images/left-sidebar/department-icon.svg" />
                            </span>
                            {skill?.departmentName}
                          </div> */}
                        </td>
                        <td>
                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#02BC7D"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                              />
                            </svg>{" "}
                            {skill?.totalDuration}
                          </div>
                        </td>
                        <ProjectPermissionHOC scope="view_report.skill.cost">
                          <td className="text-center">
                            <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                              {costFormat(skill?.totalFinalCost)}
                            </span>
                          </td>
                        </ProjectPermissionHOC>
                      </tr>

                      {skill?.userList
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sorting by name
                        ?.map((user, idx) => (
                          <tr id={`demo1${index}`} className="bgliggry accordion-collapse collapse">
                            {/* <td draggable="false">
                          <input className="form-check-input" type="checkbox" />
                        </td> */}
                            <td draggable="false">
                              {/* <label className="form-check-label">{index + 1}</label> */}
                            </td>
                            <td className="position-relative">
                              <div className="text-primary-900 ps-5 fw-bold">

                                {user?.name}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  fill="currentColor"
                                  className="bi bi-check-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill="#02BC7D"
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                  />
                                </svg>{" "}
                                {user?.totalDuration}
                              </div>
                            </td>
                            <ProjectPermissionHOC scope="view_report.skill.cost">
                              <td className="text-center">
                                <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                                  {costFormat(user?.totalFinalCost)}
                                </span>
                              </td>
                            </ProjectPermissionHOC>
                          </tr>

                        ))}

                    </tbody>
                  ))}

                  <tfoot>
                    <tr>
                      <td colSpan={1} />
                      <td colSpan={1} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration}</span> Total Units
                      </td>
                      <ProjectPermissionHOC scope="view_report.skill.cost">
                        <td className="text-center">
                          <span className="d-block fw-bold">{costFormat(totalcost)}</span> Total Cost
                        </td>
                      </ProjectPermissionHOC>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SkillFilter />
      <ManageSkillColum />
    </>
  )
}
export default SkillWiseContainer