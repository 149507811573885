import React from 'react';
import moment from 'moment'

const PaymentView = ({ paymentDetails }) => {
    // Calculate total from sampleData
    const totalAmount = paymentDetails?.reduce((total, item) => {
        // Convert amount string to a number and sum it
        return total + parseFloat(item.amount);
    }, 0);

    return (
        <div
            className="modal fade cusmodl"
            id="PaymentView"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <table className="table basic-border-table mb-0 table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col" className="text-center">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentDetails?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                                        {/* <td className={`text-center fw-semibold ${item.amount.startsWith('+') ? 'text-green' : 'text-red'}`}> */}
                                        <td className={`text-center fw-semibold text-green`}>
                                            {item.amount}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total Paid Amount</td>
                                    <th className="text-center">₹{totalAmount?.toFixed(2)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentView;
