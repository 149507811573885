import React, { useEffect } from 'react';
import { costFormat } from 'src/utils/CommonUtils';

// Utility function to get initials from name
const getInitials = (name) => {
  if (!name) return '';
  const words = name.trim().split(' ');
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ''}`;
};

// Utility function to generate color from a string (e.g., client name)
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // Ensure it's a valid hex color
};

const ClientWise = ({ groupedByClientArray, onClient }) => {

  const clientWiseButtonClick = (clientId, ClientCommercialName, totalAmount) => {
    onClient({ clientId, ClientCommercialName, totalAmount });
  }

  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );

    // Cleanup tooltips when component unmounts or data changes
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, [groupedByClientArray]); // Reinitialize tooltips when data or columns change

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pt-16 pb-16">
        <h3 className="card-title">Payment Details Summary</h3>
      </div>
      <div className={`table-body-scrollable }`}>
        <table className="table basic-border-table mb-0 table-hover tfontset">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Project</th>
              <th>Client Name 1</th>
              <th>Client Name 2</th>
              <th>Month</th>
              {/* <th className="text-center">View</th> */}
              <th className="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            {groupedByClientArray.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row?.ClientProjectCode}</td>
                <td>
                  <div className="d-flex align-items-center gap-2 tableflex-mw200px">
                    <span
                      className="client-initials img imgsSet me-0"
                      style={{
                        backgroundColor: stringToColor(row?.ClientCommercialName),
                        color: '#fff',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        minWidth: '32px',
                        maxWidth: '32px',
                        height: '32px',
                      }}
                    >
                      {getInitials(row?.ClientCommercialName)}
                    </span>
                    {row?.ClientCommercialName}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2 tableflex-mw200px">
                    <span
                      className="client-initials img imgsSet me-0"
                      style={{
                        backgroundColor: stringToColor(row?.ClientRegisteredName),
                        color: '#fff',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        minWidth: '32px',
                        maxWidth: '32px',
                        height: '32px',
                      }}
                    >
                      {getInitials(row?.ClientRegisteredName)}
                    </span>
                    {row?.ClientRegisteredName}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <span className="monthCount imgsSet bg-danger-200 text-danger-500 me-0">{row?.durations.length}</span>
                    <button className="text-dblue" onClick={() => clientWiseButtonClick(row?.clientId, row?.ClientCommercialName, costFormat(row?.totalAmount))} data-bs-toggle="modal" data-bs-target="#ClientWiseMonths">
                    <i className="mdi mdi-eye mdi-36px"></i>
                  </button>
                  </div>
                </td>
                <td className={`text-end ${parseFloat(row?.totalAmount) < 0 ? 'text-red' : 'text-green'}`}>
                  {costFormat(row?.totalAmount)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientWise
