import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { userSearchAPI } from 'src/api/UserApi';

/**
 * The options array should contain objects.
 * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
 */


const option = [
    { label: 'Swedish', value: 'sv' },
    { label: 'English', value: 'en' },

];

const UserMultiSelect = (props) => {
    const [allData, setAllData] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState(option)
    // useEffect(()=>{
    //     if(options.length > 0){
    //         let option = options.map((item) => ({value:item.subject,id:item._id,label:item.subject}))
    //         setDropdownOptions(option)
    //     }
    // },[options])
    useEffect(() => {
        // Fetch data once
        const getOptions = async () => {
            const { data: { data } } = await userSearchAPI({});
            setAllData(data); // Store the fetched data locally
        };
        getOptions();
    }, []); // Run only once on component mount

    useEffect(() => {
        // Filter the data whenever props change
        const filteredData = props?.minPrice
            ? allData.filter(
                (item) =>
                    item.monthlySalary >= props?.minPrice * 1000 &&
                    item.monthlySalary <= props?.maxPrice * 1000
            )
            : allData;

        if (filteredData.length > 0) {
            // Sort filtered data by firstName and lastName (ascending order)
            const sortedData = filteredData.sort((a, b) => {
                const firstNameComparison = a.firstName.localeCompare(b.firstName);
                if (firstNameComparison !== 0) return firstNameComparison;
                return a.lastName.localeCompare(b.lastName);
            });
            const options = sortedData.map((item) => ({
                value: `${item.firstName} ${item.lastName} (${item.employeeID})`,
                label: `${item.firstName} ${item.lastName} (${item.employeeID})`,
                id: item._id,
            }));
            setDropdownOptions(options);
        } else {
            setDropdownOptions([]); // Clear options if no data matches
        }
    }, [props?.minPrice, props?.maxPrice, allData]); // Run whenever props or allData changes


    // useEffect(() => {
    //     const getOptions = async () => {
    //         const { data: { data } } = await userSearchAPI({});

    //         // Filter the data based on monthlySalary
    //         const filteredData = props?.minPrice ? data.filter(
    //             (item) => item.monthlySalary >= props?.minPrice * 1000 && item.monthlySalary <= props?.maxPrice * 1000
    //         ) : data;
    //         if (filteredData.length > 0) {
    //             let option = filteredData?.map((item) => ({
    //                 value: `${item.firstName} ${item.lastName} (${item.employeeID})`,
    //                 label: `${item.firstName} ${item.lastName} (${item.employeeID})`,
    //                 id: item._id,
    //             }))
    //             setDropdownOptions(option)
    //         }
    //         // setDropdownOptions(option);
    //     }
    //     getOptions();
    // }, [props?.minPrice, props?.maxPrice])

    return (
        /* Simple example */
        <Select className="p-0"
            // classNamePrefix="react-select" 
            // value={getValue()}
            options={dropdownOptions}
            isMulti={true}
            {...props}
        />
    );
}

export default UserMultiSelect;
