import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importing Quill's default theme

const TextEditor = ({ value, onChange, readOnly = false }) => {
    const [editorHtml, setEditorHtml] = useState(value || '');

    const handleChange = (html) => {
        setEditorHtml(html);
        if (onChange) {
            onChange(html);
        }
    };

    return (
        <div className="editor-container">
            <ReactQuill
                className="border"
                value={editorHtml}
                onChange={handleChange}
                modules={TextEditor.modules}
                placeholder="Write Project Scope here"
                readOnly={readOnly}
            />
        </div>
    );
};

// Configure Quill toolbar options
TextEditor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false], font: [] }],
        [{ size: ['small', 'medium', 'large', 'huge'] }],
        [{ align: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        ['link', 'image', 'video'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['clean'],
        ['undo', 'redo'],
    ],
};

export default TextEditor;
