import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { projectOADSaveAPI } from "src/api/ProjectApi";
import { costFormat, getMonthNumber } from "src/utils/CommonUtils";
import { toast } from 'react-toastify';

const OADSavePopup = ({ OADDetails, onTotalChangeOAD }) => {
    const { projectId, OADAction, currentOAD } = OADDetails;

    const [months, setMonths] = useState([
        { month: dayjs().format("MMMM"), year: dayjs().format("YYYY"), value: "" },
    ]);

    const validationSchema = Yup.object({
        costs: Yup.array().of(
            Yup.object({
                value: Yup.number()
                    .typeError("Cost must be a number")
                    .min(0, "Cost cannot be negative")
                    .required("Cost is required"),
            })
        ),
    });

    useEffect(() => {
        // Reset values when the modal is closed
        setMonths([
            { month: dayjs().format("MMMM"), year: dayjs().format("YYYY"), value: "" },
        ])
    }, [projectId, OADAction]);

    const handleAddMonth2 = (values, setValues) => {
        const lastMonth = months[months.length - 1];
        const nextMonth = dayjs(`${lastMonth.month} ${lastMonth.year}`)
            .add(1, "month")
            .format("MMMM YYYY")
            .split(" ");

        const newMonth = { month: nextMonth[0], year: nextMonth[1], value: "" };

        // Update the months state
        setMonths((prevMonths) => [...prevMonths, newMonth]);

        // Update Formik's values
        setValues({ costs: [...values.costs, newMonth] });
    };
    const handleAddMonth = (values, setValues) => {
        const lastMonth = values.costs[values.costs.length - 1];  // Get the last month
        const nextMonth = dayjs(`${lastMonth.month} ${lastMonth.year}`)
            .add(1, "month")
            .format("MMMM YYYY")
            .split(" ");  // Get the next month (split into month and year)

        const newMonth = { month: nextMonth[0], year: nextMonth[1], value: "" };


        // Update Formik values (keep previous values and append the new one)
        setValues({
            costs: [...values.costs, newMonth],  // Append new month
        });
        // console.log("months 1", [...values.costs, newMonth]);
        // Append new month to months state
        setMonths([...values.costs, newMonth]);
    };


    const handleSubmit = async (values, { resetForm }) => {
        const storeData = values.costs
            ?.filter((item) => item.value > 0)
            ?.map((item) => ({
                ...item,
                type: OADAction,
                ts: new Date(`${item.year}-${getMonthNumber(item.month)}-01T00:00:00.000Z`),
                projectId,
            }));

        if (storeData.length) {
            try {
                const response = await projectOADSaveAPI(storeData);
                resetForm();
                toast.success("OAD successfully updated!");
                setMonths([
                    { month: dayjs().format("MMMM"), year: dayjs().format("YYYY"), value: "" },
                ])
                // Uncomment and implement if needed
                onTotalChangeOAD({ changeOAD: response?.data?.data, projectId });
            } catch (error) {
                console.error("Submission Error:", error);
            }
        }
    };

    return (
        <>
            {/* Trigger Button */}
            {/* <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#oad-save-popup"
            >
                Add OAD Cost
            </button> */}

            {/* Modal */}
            <div
                className="modal fade"
                id="oad-save-popup"
                tabIndex={-1}
                aria-labelledby="oad-save-popupLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <h1 className="modal-title fs-4" id="oad-save-popupLabel">
                                {OADAction === "add" ? "Add" : "Remove"} OAD Cost
                            </h1>
                        </div>
                        <div className="modal-body pt-0">
                            <Formik
                                key={JSON.stringify(months)}
                                initialValues={{ costs: months }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, setValues, resetForm }) => (
                                    <Form>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        <div className="cruentAmSec">
                                            <p>Current OAD Cost</p>
                                            <h6>{costFormat(currentOAD)}</h6>
                                        </div>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>

                                        {values.costs.map((item, index) => (
                                            <div className="costAmouSec" key={index}>
                                                <div className="costAmHead">
                                                    <span className="costNumber">{index + 1}</span>
                                                    <h6>
                                                        {item.month}
                                                        <span>{item.year}</span>
                                                    </h6>
                                                </div>
                                                <div className="costInput">
                                                    <label htmlFor={`costs.${index}.value`}>₹</label>
                                                    <Field
                                                        type="text"
                                                        name={`costs.${index}.value`}
                                                        placeholder="0"
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            ); // Allow only digits
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name={`costs.${index}.value`}
                                                        component="div"
                                                        className="text-danger"
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                        {/* Add More Section */}
                                        <div className="addCostSec">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleAddMonth(values, setValues)
                                                }
                                                className="btn btn-link"
                                            >
                                                Add More
                                            </button>
                                        </div>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        <div className="totalNumbercost">
                                            <h6>Total {OADAction === "add" ? "Added" : "Removed"} OAD</h6>
                                            <h5>
                                                {costFormat(
                                                    values.costs.reduce(
                                                        (sum, item) => sum + (Number(item.value) || 0),
                                                        0
                                                    )
                                                )}
                                            </h5>
                                        </div>
                                        <button type="submit" className="costBtnSave" data-bs-dismiss="modal" aria-label="Close">
                                            Save
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OADSavePopup;
