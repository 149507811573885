import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import OrdinalNumberDisplay from 'src/components/OrdinalNumberDisplay';
import { projectCMDSaveAPI } from 'src/api/ProjectApi';
import { costFormat } from 'src/utils/CommonUtils';
import { toast } from 'react-toastify';

const CMDSavePopup = ({ CMDDetails, onTotalChangeCMD }) => {
    const { projectId, milestoneType, currentCMD } = CMDDetails
    const initialValues = {
        milestone1: 0.0,
        milestone2: 0.0,
        milestone3: 0.0,
    };

    const validationSchema = Yup.object({
        milestone1: Yup.number()
            .min(0, "Value cannot be negative")
            .required("Required"),
        milestone2: Yup.number()
            .min(0, "Value cannot be negative")
            .required("Required"),
        milestone3: Yup.number()
            .min(0, "Value cannot be negative")
            .required("Required"),
    });

    const handleSubmit = async (values, { resetForm }) => {
        const currentYear = new Date().getFullYear(); // Get the current year
        let currentMonth = new Date().getMonth() + 1; // Get the current month (0-11)
        currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
        const milestones = [
            {
                ts: new Date(`${currentYear}-${currentMonth}-01T00:00:00.000Z`),
                value: parseFloat(values.milestone1),
            },
            {
                ts: new Date(`${currentYear}-${currentMonth}-11T00:00:00.000Z`),
                value: parseFloat(values.milestone2),
            },
            {
                ts: new Date(`${currentYear}-${currentMonth}-21T00:00:00.000Z`),
                value: parseFloat(values.milestone3),
            },
        ].filter((milestone) => milestone.value > 0)
            .map((milestone) => ({
                ...milestone,
                type: milestoneType,
                projectId,
            }));

        if (milestones.length) {
            try {
                const response = await projectCMDSaveAPI(milestones);
                // Reset form values to initial values after submission
                resetForm();
                toast.success("CMD successfully updated!");
                onTotalChangeCMD({ changeCMD: response?.data?.data, projectId });
            } catch (error) {
                console.error("Submission Error:", error);
            }
        }
    };
    return (
        <div
            className="modal fade"
            id="addcmd-cost"
            tabIndex={-1}
            aria-labelledby="addcmd-costLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content cmdCostMain">
                    <div className="modal-header border-0 pb-0">
                        <h1 className="modal-title fs-4" id="addcmd-costLabel">
                            {milestoneType[0].toUpperCase() + milestoneType.slice(1)} CMD Cost{" "}
                        </h1>
                    </div>
                    <div className="modal-body pt-0">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values }) => {
                                const totalChangeCMD = Object.values(values).reduce(
                                    (sum, val) => sum + (parseFloat(val) || 0),
                                    0
                                );

                                return (
                                    <Form>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        <div className="cruentAmSec">
                                            <p>Current CMD Cost</p>
                                            <h6>{costFormat(currentCMD)}</h6>
                                        </div>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        {["1", "2", "3"].map((milestone, index) => (
                                            <div className="costAmouSec" key={milestone}>
                                                <div className="costAmHead">
                                                    <span className="costNumber">{milestone}</span>
                                                    <h6>
                                                        Milestone
                                                        <span>
                                                            <OrdinalNumberDisplay number={index * 10 + 1} /> - <OrdinalNumberDisplay number={index * 10 + 10} /> Days
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div className="costInput">
                                                    <label htmlFor={`milestone${milestone}`}>₹</label>
                                                    <Field
                                                        type="text"
                                                        name={`milestone${milestone}`}
                                                        placeholder="0"
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name={`milestone${milestone}`}
                                                        component="div"
                                                        className="error"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        <div className="totalNumbercost">
                                            <h6>Total {milestoneType === 'add' ? 'Added' : 'Removed'} CMD</h6>
                                            <h5>{costFormat(totalChangeCMD)}</h5>
                                        </div>
                                        <button type="submit" data-bs-dismiss="modal" aria-label="Close" className="costBtnSave">
                                            Save
                                        </button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CMDSavePopup