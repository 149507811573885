import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LeftNavBar from '../layout-container/LeftNavBar';
import HeaderNav from '../layout-container/HeaderNav';
import ProductionContainer from '../production-container/ProductionContainer';
import Login from '../login-container/Login';
import ClientContainer from '../client-container/ClientContainer';
import ProjectContainer from '../project-container/ProjectContainer';
import UserContainer from '../user-container/UserContainer';
import EmployeeDataContainer from '../employee-container/Data/EmployeeDataContainer';
import EmployeeProductionContainer from '../employee-container/Production/EmployeeProductionContainer';
import EmployeeProjectContainer from '../employee-container/Project/EmployeeProjectContainer';
import EmployeeSkillContainer from '../employee-container/Skill/EmployeeSkillContainer';
import ModuleContainer from '../project-container/Module/ModuleContainer';
import FeedBackLog from 'src/components/FeedBackLog';
import MTDContainer from '../MTD-container/MDTContainer';
import DeliveryDeviationContainer from '../delivery-daviation-container/DeliveryDeviationContainer';
import PermissionContainer from '../permission-container/PermissionContainer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchUserScopesAction } from '../home-container/actions/authAction';
import ViewPerformance from 'src/components/ViewPerformance';
import TaskPendingContainer from '../pending-container/TaskPendingContainer';
import ProductionPendingContainer from '../pending-container/ProductionPendingContainer';
import DateRangePickerWithAll from 'src/components/DateRangePickerWithAll';
import Documentation from 'src/components/Documentation';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import FeedbackLMS from 'src/components/FeedbackLMS';
import FeedbackLog2 from 'src/components/FeedbackLog2';
import FeedbackContainer from '../feedback-container/FeedbackContainer';
import MyAudit from 'src/components/MyAudit';
import UCLTopScorersChart from 'src/components/UCLTopScorersChart';
import ProductionListContainer from '../pending-container/ProductionContainer';
import AuditReport from 'src/components/AuditReport';
import EmployeePerfomanceContainer from '../employee-container/Perfomance/EmployeePerfomanceContainer';
import VendorDelivery from 'src/components/VendorDelivery';
import PddStats from 'src/containers/project-container/pdd/PddStats';
import VendorList from 'src/components/VendorList';
import PaymentDetails from 'src/containers/client-invoice-container/PaymentDetails';
import CreateInvoice from 'src/containers/client-invoice-container/CreateInvoice';
import VendorContainer from '../vendor-container/index';
import HrEmployeeManageSalary from '../salary-container/HrEmployeeManageSalary';
import AddEmployeeSalary from '../salary-container/AddEmployeeSalary';
import AddSalarySlip from '../salary-container/AddSalarySlip';
import EmployeeSalarySlip from 'src/components/Salary/EmployeeSalarySlip';
import EmployeeSalarySlip2 from 'src/components/Salary/EmployeeSalarySlip2';
import SalarySlip from '../salary-container/SalarySlip';
import TaxInvoiceDownload from 'src/containers/client-invoice-container/InvoiceDownloadTemplate';
import ClientInvoiceDownload from 'src/components/InvoiceDownload/ClientInvoiceDownload';
import FreelancerInvoice from 'src/components/VendorInvoicePdf/FreelancerInvoice';
import VendorInvoicePdf from 'src/components/VendorInvoicePdf/VendorInvoicePdf';
import SalaryContainer from '../employee-container/salary/Index';
import TimeStamp from 'src/components/TimeStamp/TimeStamp';
import DeliveryPlanCMD from 'src/components/DeliveryPlan/DeliveryPlanCMD';
import PendingPaidCancelled from '../client-invoice-container/PendingPaidCancelled';
import PermissionHOC from 'src/hoc/PermissionHOC';
import VendorInvoiceList from '../vendor-container/VendorInvoiceList';
import AccountsReportContainer from '../report-container/AccountsReportContainer';
import SalesReportContainer from '../report-container/SalesReportContainer/Index';
import OperationsReportContainer from '../report-container/OperationsReportContainer/Index';
import VendorInvoice from 'src/components/vendor-invoice/VendorInvoice';


// import Permission from 'src/components/Permission';

// import Home from '../home-container/Home';
function MainContainer() {
  const dispatch = useDispatch();
  const userScopes = useSelector((state) => state.auth.user.userScopes, shallowEqual);
  if (userScopes && userScopes.length === 0) {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));
    setDefaultHeaders({
      Authorization: token,
    });
    dispatch(fetchUserScopesAction({ userId: user?._id, roleId: user?.roleDetails?._id }));
  }
  return (
    <>
      <LeftNavBar />
      <main className="dashboard-main">
        <HeaderNav />
        <div className="dashboard-main-body pt-0">
          <Routes>

            <Route index
              element={
                <PermissionHOC scope="dashboard">
                  <ProductionContainer />
                </PermissionHOC>
              } />
            <Route path="productions" element={
              <PermissionHOC scope="production">
                <ProductionContainer />
              </PermissionHOC>
            } />
            <Route path="projects/*" element={
              <PermissionHOC scope="special_access.project">
                <ProjectContainer />
              </PermissionHOC>
            } />
            <Route path="clients" element={
              <PermissionHOC scope="92">
                <ClientContainer />
              </PermissionHOC>
            } />
            <Route path="employee-data" element={
              <PermissionHOC scope="employee_access.employee_date">
                <EmployeeDataContainer />
              </PermissionHOC>
            } />
            <Route path="manage-salary" element={
              <PermissionHOC scope="employee_access.manage_salary">
                <HrEmployeeManageSalary />
              </PermissionHOC>
            } />
            <Route path="salary" element={
              <PermissionHOC scope="production">
                <SalaryContainer />
              </PermissionHOC>
            } />
            <Route path="view-production/:userId" element={<EmployeeProductionContainer />} />
            <Route path="view-perfomance/:employeeID/:userId" element={
              <PermissionHOC scope="employee_access.employee_date">
                <EmployeePerfomanceContainer />
              </PermissionHOC>
            } />
            <Route path="view-project" element={
              <PermissionHOC scope="employee_access.view_project">
                <EmployeeProjectContainer />
              </PermissionHOC>
            } />
            <Route path="feedback-log" element={<FeedBackLog />} />
            <Route path="mtd-delivery" element={
              <PermissionHOC scope="special_access.mtd_delivery">
                <MTDContainer />
              </PermissionHOC>
            } />
            <Route path="delivery-deviation" element={
              <PermissionHOC scope="special_access.delivery_deviation">
                <DeliveryDeviationContainer />
              </PermissionHOC>
            } />
            <Route path="permission" element={
              <PermissionHOC scope="employee_access.Permissions">
                <PermissionContainer />
              </PermissionHOC>
            } />
            <Route path="pending-task-list" element={<TaskPendingContainer />} />
            <Route path="pending-production-list" element={<ProductionPendingContainer />} />
            <Route path="production-list" element={
              //  <PermissionHOC scope="employee_access.production_list">
              <ProductionListContainer />
              // </PermissionHOC>
            } />
            <Route path="documentation" element={<Documentation />} />
            <Route path="feedback-lms" element={<FeedbackLMS />} />
            <Route path="feedbacklog" element={<FeedbackLog2 />} />
            <Route path="manage-project-feedback" element={<FeedbackContainer />} />
            <Route path="myaudit" element={<MyAudit />} />
            <Route path="scorerschart" element={<UCLTopScorersChart />} />
            <Route path="auditreport" element={<AuditReport />} />
            <Route path="vendor-delivery" element={<VendorDelivery />} />
            <Route path="vendor-list" element={<VendorList />} />
            <Route path="vendor-invoice" element={<VendorInvoiceList />} />
            <Route path="pdd-stats" element={
              <PermissionHOC scope="special_access.pdd_stats">
                <PddStats />
              </PermissionHOC>
            } />
            <Route path="vendor-list" element={<VendorContainer />} />


            {/* client report route start */}
            <Route path="report/operations" element={<OperationsReportContainer />} />
            <Route path="report/sales" element={<SalesReportContainer />} />
            <Route path="report/accounts" element={<AccountsReportContainer />} />
            {/* client invoice route end */}
            {/* client invoice route start */}
            {/* <Route path="invoice/payment-details" element={<PaymentDetails />} /> */}
            {/* <Route path="invoice/create" element={<CreateInvoice />} /> */}
            {/* <Route path="invoice/edit/:invoiceId" element={<CreateInvoice />} />
            <Route path="invoice/edit/:invoiceId/d" element={<CreateInvoice />} /> */}
            {/* <Route path="invoice/pending" element={<PendingPaidCancelled />} /> */}
            <Route path="invoice/payment-details" element={
              <PermissionHOC scope="93">
                <PaymentDetails />
              </PermissionHOC>
            } />
            <Route path="invoice/pending" element={
              <PermissionHOC scope="94">
                <PendingPaidCancelled />
              </PermissionHOC>
            } />
            <Route path="invoice/create" element={
              <PermissionHOC scope="94"><CreateInvoice /></PermissionHOC>
            } />
            <Route path="invoice/edit/:invoiceId" element={
              <PermissionHOC scope="94"><CreateInvoice /></PermissionHOC>
            } />
            <Route path="invoice/edit/:invoiceId/d" element={
              <PermissionHOC scope="94"><CreateInvoice /></PermissionHOC>
            } />
            {/* client invoice route end */}

            <Route path="add-salary-slip/:salaryId" element={
              <PermissionHOC scope="employee_access.manage_salary">
                <AddSalarySlip />
              </PermissionHOC>
            } />
            <Route path="pending-salary" element={<AddEmployeeSalary />} />
            <Route path="TaxInvoiceDownload" element={<TaxInvoiceDownload />} />
            <Route path="ClientInvoiceDownload" element={<ClientInvoiceDownload />} />
            <Route path="FreelancerInvoicedownload" element={<FreelancerInvoice />} />
            <Route path="VendorInvoicedownload" element={<VendorInvoicePdf />} />
            <Route path="timestamp" element={<TimeStamp />} />
            <Route path="DeliveryPlanCMD" element={<DeliveryPlanCMD />} />
            <Route path="VendorInvoiceNew" element={<VendorInvoice />} />
          </Routes>
        </div>
      </main>

      {/**/}
    </>
  );
}

export default MainContainer;
