import { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FilterPopup from './FilterPopup';
import ProjectListCart from './ProjectListCart';
import ProjectGridCart from './ProjectGridCart';
import { projectEditAction, projectSearchAction, projectUpsertAction } from './actions/ProjectActions';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";
import { productionProjectAssigneeSearchAction } from '../production-container/actions/ProductionActions';
import { getProjectForClientDeliveryFromAPI } from 'src/api/ClientProductionApi';
import SearchInput from 'src/components/SearchInput';
import MonthPicker from 'src/components/MonthPicker';
import AssignTask from '../client-container/AssignTask';
import TotalProjectMemberModal from './View/TotalProjectMemberModal';
import OADAndCMDSavePopup from './View/OADAndCMDSavePopup';
import OADAndCMDList from './View/OADAndCMDList';
import ClientStatusCounter from '../client-container/ClientStatusCounter';
import PermissionContainer from '../permission-container/PermissionContainer';
import Pagination from 'src/components/Pagination';
import ProjectPermissionPopup from './ProjectPermissionPopup';
import { getProjectPermissionsByUserAPI } from 'src/api/ProjectApi';
import CMDSavePopup from './CMDSavePopup';
import OADSavePopup from './OADSavePopup';

const SearchFilter = ({ where, setWhere, whereOadCmd, setWhereOadCmd, reset, setDateRange }) => {
  const handleChange = debounce((e) => {
    setWhere({ ...where, [e.target.name]: e.target.value });
  }, 1000)
  const handleChangeDate = (date) => {
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    // setWhere({
    //   ...where,
    //   $expr: {
    //     $and: [
    //       { $eq: [{ $year: "$ts" },year] },
    //       { $eq: [{ $month: "$ts" }, month] } 
    //     ]
    //   }
    // })
    setWhereOadCmd({
      ...whereOadCmd,
      $expr: {
        $and: [
          { $eq: [{ $year: "$ts" }, year] },
          { $eq: [{ $month: "$ts" }, month] }
        ]
      }
    })
    setDateRange({ selectedMonth: month, selectedYear: year })
  }
  const handleTextSearch = useCallback(
    debounce((searchKey, setWhere) => {
      if (searchKey === '') {
        reset();
      }
      else {
        setWhere({ ...where, searchTerm: searchKey });
      }
    }, 1000),
    [where]
  );
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    handleTextSearch(searchKey, setWhere);
    // setWhere({ ...where, searchTerm: searchKey });
  };
  return (
    <>
      <div className="d-flex gap-2 align-items-center py-16">
        {/* Search start */}
        <SearchInput handleSearchInputChange={handleSearchInputChange} />
        <MonthPicker handleChangeDate={handleChangeDate} />
        {/* Date Range piker end  */}
        {/* Filter btn start */}
        <div className="FilterBtn d-flex gap-2">
          <button
            type="button"
            className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
            data-bs-toggle="offcanvas"
            data-bs-target="#special-access-fil"
            aria-controls="special-access-fil"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="1.5em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
              />
            </svg>{" "}
            Filter
          </button>
          <button type="reset"
            onClick={reset}
            className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
          >Reset</button>
        </div>
        {/* Filter btn end */}
      </div>
    </>
  )
}

const ProjectSearchContainer = () => {
  const {
    search,
  } = useSelector((state) => state.ProjectReducer, shallowEqual);

  const { _id: userId, roleDetails } = useSelector(state => state.auth?.user, shallowEqual);
  const role = roleDetails?.roleName || "Employee";

  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const { currentSelectedProjectId } = useSelector(state => state.ClientReducer, shallowEqual);

  const [where, setWhere] = useState({
    role,
    searchTerm: null,
    // participants: {
    //   $elemMatch: {
    //     userId: userId,
    //     status: "Active"
    //   }
    // }
    $and: [
      {
        participants: {
          $elemMatch: {
            userId: userId,
            status: "Active"
          }
        }
      }
    ]
  });
  const [whereOadCmd, setWhereOadCmd] = useState({
    $expr: {
      $and: [
        { $eq: [{ $year: "$ts" }, selectedYear] },
        { $eq: [{ $month: "$ts" }, selectedMonth] }
      ]
    }
  })
  const [dateRange, setDateRange] = useState({ selectedMonth, selectedYear });
  const [pageNo, setPageNo] = useState(0);
  const [groupCounter, setGroupCounter] = useState({ clientCount: 0, projectCount: 0, ProfitAndLoss: 0 });
  const [projectStatus, setProjectStatus] = useState([]);
  const [project, setProject] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [userSelectedProjectPermission, setUserSelectedProjectPermission] = useState([]);
  const dispatch = useDispatch();
  const navigation = useNavigate();


  const projectSearch = () => {
    const payload = {
      where,
      whereOadCmd,
      dateRange,
      currentPage: pageNo,
      autopopulate: true,
      fullTextSearch: true,
    }
    dispatch(projectSearchAction(payload));
  }

  useEffect(() => {
    projectSearch()
  }, [where, whereOadCmd, pageNo])
  const editClick = useCallback((type, projectId) => {
    const currentProject = search.data.find((project) => project._id === projectId);
    dispatch(projectEditAction({
      _id: currentProject._id,
      userId: userId
    }));
    localStorage.setItem("projectId", projectId);
    if (type === "view") {
      // navigation(`/projects/view/skill/${projectId}`);
      window.open(`/projects/view/department/${projectId}`, '_blank');
    }
    else {
      navigation(`/projects/task/${projectId}`);
    }
  }, [
    navigation,
    search.data
  ]);

  useEffect(() => {
    const projectStatusCount = Object.entries(
      search.data.reduce((acc, project) => {
        acc[project.ProjectStatus] = (acc[project.ProjectStatus] || 0) + 1;
        return acc;
      }, {})
    ).map(([key, value]) => ({
      _id: key,
      count: value
    }));
    setProjectStatus(projectStatusCount);
    const clientIdCount = search.data.reduce((acc, project) => {
      acc[project.RefClientId?._id] = (acc[project.RefClientId?._id] || 0) + 1;
      return acc;
    }, {});
    const projectIdCount = search.data.reduce((acc, project) => {
      acc[project._id] = (acc[project._id] || 0) + 1;
      return acc;
    }, {});
    // Get the total number of unique clients and projects
    const totalClientCount = Object.keys(clientIdCount).length;
    const totalProjectCount = Object.keys(projectIdCount).length;
    setGroupCounter({
      clientCount: totalClientCount,
      projectCount: totalProjectCount,
      ProfitAndLoss: 0
    })
    setProject(search.data)
  }, [search.data]);
  const reset = useCallback(() => {
    setWhere({
      role,
      searchTerm: null,
      participants: {
        $elemMatch: {
          userId: userId,
          status: "Active"
        }
      }
    });
    document.getElementById('search').value = '';
  }, [userId])

  const handleLoadPermissions = useCallback(async (user) => {
    setSelectedUser(user);
    const response = await getProjectPermissionsByUserAPI(user._id, currentSelectedProjectId);
    if (response && response.data) {
      const permissions = response.data.data[0]?.permissions || [];
      setUserSelectedProjectPermission(permissions);
    }
    else {
      setUserSelectedProjectPermission([]);
    }
  }, [currentSelectedProjectId])
  const [OADDetails, setOADDetails] = useState({ projectId: 0, OADAction: 'add', currentOAD: 0 });
  const [CMDDetails, setCMDDetails] = useState({ projectId: 0, milestoneType: 'add', currentCMD: 0 });
  const handleCMDDetails = (details) => {
    setCMDDetails((prevDetails) => ({
      ...prevDetails,
      ...details,
    }));
  }
  const handleOADDetails = (details) => {
    setOADDetails((prevDetails) => ({
      ...prevDetails,
      ...details,
    }));
  }

  const handleTotalChangeOAD = (newOAD) => {
    console.log("newOAD");

    projectSearch()
  }
  const handleTotalChangeCMD = (newCMD) => {
    // Merge changeCMD into cmd of the project with matching projectId
    const updateProject = project.map(project => {
      if (project._id === newCMD.projectId) {
        return {
          ...project,
          cmd: [...project.cmd, ...newCMD.changeCMD]
        };
      }
      return project;
    });
    setProject(updateProject)
  }

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <div className="border-bottom  pb-16">
                <h2 className="fw-semibold mb-16 font-24">
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <g data-name="20-Arrow Left">
                        <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                        <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                      </g>
                    </svg>
                  </button>{" "}
                  My Projects
                </h2>
                <ul className="d-flex align-items-center gap-2">
                  <li className="fw-medium">
                    <a
                      href="#"
                      className="d-flex align-items-center gap-1 hover-text-primary"
                    >
                      <iconify-icon
                        icon="solar:home-smile-angle-outline"
                        className="icon text-lg"
                      ></iconify-icon>
                      Special Access
                    </a>
                  </li>
                </ul>
              </div>

              <div className="py-16">
                <ClientStatusCounter
                  groupCounter={groupCounter}
                  projectStatus={projectStatus}
                />
              </div>

              <SearchFilter
                where={where}
                setWhere={setWhere}
                setWhereOadCmd={setWhereOadCmd}
                whereOadCmd={whereOadCmd}
                editClick={editClick}
                reset={reset}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-info-600">
                  Total Project: {search.count}
                </h6>
                <ul
                  className="nav border-gradient-tab nav-pills mb-0"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link d-flex align-items-center active"
                      id="pills-to-do-list-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-to-do-list"
                      type="button"
                      role="tab"
                      aria-controls="pills-to-do-list"
                      aria-selected="false"
                    >
                      <img
                        src="/assets/images/left-sidebar/grid-view.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link d-flex align-items-center"
                      id="pills-recent-leads-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-recent-leads"
                      type="button"
                      role="tab"
                      aria-controls="pills-recent-leads"
                      aria-selected="true"
                      tabIndex={-1}
                    >
                      <img
                        src="/assets/images/left-sidebar/view-list.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-to-do-list"
                  role="tabpanel"
                  aria-labelledby="pills-to-do-list-tab"
                  tabIndex={0}
                >
                  <div className="row gy-4">
                    {
                      project.map((project, index) => {
                        const { currentMonthDeliveryCost, grossDeliveryCost } = search.delivery.filter(delivery => (project._id === delivery._id))[0] || { currentMonthDeliveryCost: 0, grossDeliveryCost: 0 };
                        const permissions = project?.permissions[0]?.permissions || [];
                        const isFullAccess = project?.participants?.some(participant => participant.userId._id === userId && participant.role === "Manager");
                        const isAdmin = (role === 'Admin');
                        // Conditional rendering based on permissions or isFullAccess
                        if (permissions.length > 0 || isFullAccess || isAdmin) {
                          return (
                            <ProjectGridCart
                              project={project}
                              editClick={editClick}
                              currentMonthDeliveryCost={currentMonthDeliveryCost}
                              grossDeliveryCost={grossDeliveryCost}
                              key={index}
                              onClickCMDDetails={handleCMDDetails}
                              onClickOADDetails={handleOADDetails}
                            />
                          );
                        }
                        return null; // Skip rendering if conditions are not met
                      })
                    }
                  </div>
                </div>
                <div
                  className="tab-pane fade "
                  id="pills-recent-leads"
                  role="tabpanel"
                  aria-labelledby="pills-recent-leads-tab"
                  tabIndex={0}
                >
                  <div className="row gy-4">
                    {
                      project.map((project, index) => {
                        const { currentMonthDeliveryCost, grossDeliveryCost } = search.delivery.filter(delivery => (project._id === delivery._id))[0] || { currentMonthDeliveryCost: 0, grossDeliveryCost: 0 };
                        const permissions = project?.permissions[0]?.permissions || [];
                        const isFullAccess = project?.participants?.some(participant => participant.userId._id === userId && participant.role === "Manager");
                        const isAdmin = (role === 'Admin');
                        // Conditional rendering based on permissions or isFullAccess
                        if (permissions.length > 0 || isFullAccess || isAdmin) {
                          return (
                            <ProjectListCart
                              project={project}
                              editClick={editClick}
                              currentMonthDeliveryCost={currentMonthDeliveryCost}
                              grossDeliveryCost={grossDeliveryCost}
                              key={index}
                              onClickCMDDetails={handleCMDDetails}
                              onClickOADDetails={handleOADDetails}
                            />
                          )
                        }
                        return null; // Skip rendering if conditions are not met
                      })
                    }
                  </div>
                </div>
                <Pagination
                  pageNo={pageNo}
                  total={search.count}
                  setPageNo={setPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssignTask getClientList={projectSearch} handleLoadPermissions={handleLoadPermissions} />
      <FilterPopup where={where} setWhere={setWhere} data={search?.managerList} />
      <TotalProjectMemberModal />
      <OADAndCMDSavePopup projectSearch={projectSearch} />

      {/* remove */}
      <CMDSavePopup CMDDetails={CMDDetails} onTotalChangeCMD={handleTotalChangeCMD} />
      <OADSavePopup OADDetails={OADDetails} onTotalChangeOAD={handleTotalChangeOAD} />
      <OADAndCMDList />

      <ProjectPermissionPopup
        selectedUser={selectedUser}
        userSelectedProjectPermission={userSelectedProjectPermission}
      />
    </>
  )
}
export default ProjectSearchContainer