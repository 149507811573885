import React from 'react';
import { formatDate } from 'src/utils/CommonUtils';

// Function to get initials from manager name
const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part[0]).join('');
    return initials;
};

// Function to determine background color based on manager's name
const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
    return color;
};

// Function to determine text color based on background color (light or dark)
const getTextColor = (bgColor) => {
    const rgb = bgColor.match(/\d+/g).map(Number);
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
};

const RemarkItem = ({ profilePhoto, username, date, remark }) => {
    const bgColor = getBackgroundColor(username);
    const textColor = getTextColor(bgColor);
    return (
        <div className="remarkItemSec">
            <span>
                {profilePhoto ? (
                    <img
                        src={profilePhoto}
                        alt={username}
                        className="img-fluid"
                        style={{ minWidth: '35px', maxWidth: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                    />
                ) : (
                    <span
                        className="initials-circle UsIMgName"
                        style={{ backgroundColor: bgColor, color: textColor }}>
                        {getInitials(username)}
                    </span>
                )}
            </span>
            <div className="w-100">
                <h6 className='w-100'>
                    {username} <span>{formatDate(date)}</span>
                </h6>
                <p style={{ textAlign: 'justify', }}>{remark}</p>
            </div>
        </div>
    );
}

export default RemarkItem;
