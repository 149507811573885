import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

const NoWorkAvailable = ({ chartId, month, title, data, onChartClick }) => {
  useEffect(() => {
    // Highcharts initialization for pie chart
    const chartOptions = {
      chart: {
        type: 'pie',
        renderTo: chartId,
      },
      colors: ['#02BC7D', '#EE0B0B', '#FE6A35', '#2CAFFE'],
      title: {
        verticalAlign: 'middle',
        floating: true,
        align: 'center',
        text: title,
        style: {
          fontSize: '14px',
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        backgroundColor: '#FFFFFF',
      },
      tooltip: {
        shared: false,
        valueSuffix: ' hrs',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          innerSize: '50%',
          center: ['50%', '50%'],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#000000',
            },
            formatter: function () {
              const pointColor = this.point.color;
              return (
                this.point.name +
                '<br><span style="background-color: #F0F0F0; font-size: 10px; padding: 2px 5px; color: ' +
                pointColor +
                ';">' +
                this.point.y +
                ' hrs (' +
                Highcharts.numberFormat(this.percentage, 1) +
                '%)' +
                '</span>'
              );
            },
            distance: 5,
          },
          cursor: 'pointer', // Change cursor to indicate clickable
          point: {
            events: {
              click: function () {
                console.log("thisthis", this.percentage, month);

                // Trigger the onChartClick function with clicked data
                onChartClick({
                  name: this.name,
                  value: this.y,
                  percentage: this.percentage.toFixed(1),
                  month: month,
                });
              },
            },
          },
        },
      },
      series: [
        {
          data: data,
          innerSize: '75%',
        },
      ],
    };

    Highcharts.chart(chartOptions);
  }, [chartId, title, onChartClick, data]);

  return (
    <div className="setFlexPieChart allchartsCss">
      <div className="text-center">
        <div
          id={chartId}
          className="text-center d-flex flex-column justify-content-center align-items-center"

        ></div>
        <div className="monthHeading">
          <h6>{month}</h6>
        </div>
      </div>
    </div>
  );
};

export default NoWorkAvailable