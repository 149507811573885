import React, { useState, useEffect } from 'react';
import StatusCard from './StatusCard'; // Import the StatusCard component
import Breadcrumb from './Breadcrumb'; // Import the Breadcrumb component (if needed)
import SearchInput from '../../components/SearchInput';
import DatePickerComponent from '../../components/DatePickerComponent';
import PaymentInvoiceTable from './PaymentInvoiceTable';
import SummaryInvoicePayment from './SummaryInvoicePayment';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { clientPaymentDetailsSearchAction } from './actions/ClientPaymentDetailsActions';

const PaymentDetails = () => {
  const [searchQuery, setSearchTerm] = useState('');
  const [dateQuery, setDateTerm] = useState([]);
  const [searchEnable, setSearchEnable] = useState(false);
  const dispatch = useDispatch();
  // const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
  const {
    search,
  } = useSelector((state) => state.ClientPaymentDetailsReducer, shallowEqual);
  const [pageNo, setPageNo] = useState(0);
  const [data, setData] = useState([]);

  const clientPaymentDetailsSearch = () => {
    const payload = {
      where,
      // currentPage: pageNo,
      // autopopulate: true,
      // fullTextSearch: true,
    }
    dispatch(clientPaymentDetailsSearchAction(payload));
  }

  const [where, setWhere] = useState({
    searchQuery,
    dateQuery
  });
  useEffect(() => {
    clientPaymentDetailsSearch()
  }, [where, pageNo])

  useEffect(() => {
    setData(search?.data?.data)
  }, [search?.data?.data])


  const invoiceTotalCostByStatus = (invoices, status) => {
    return invoices
      .filter((invoice) => status === "all" || invoice.status === status) // Filter by status
      .reduce((total, invoice) => total + parseFloat(invoice.totalCostInr.$numberDecimal), 0); // Sum the grandTotal
  };

  const groupedInvoices = data?.reduce((acc, invoice) => {
    const cientId = invoice.cientId; // Get the cientId from each invoice
    const projectId = invoice.projectId;
    const categoryId = invoice.subCategoryId ? invoice.categoryId : invoice.categoryId;
    if (!acc[cientId]) {
      acc[cientId] = {
        invoices: [],
        ClientCommercialName: '',
        ClientProjectCode: '',
        ClientRegisteredName: '',
        client_invoices: '',
        totalDeliveryCost: 0,
        projects: {} // Initialize projects grouping
      }; // Initialize if not exists
    }
    acc[cientId].invoices.push(invoice); // Add invoice to the group
    acc[cientId].totalDeliveryCost += invoice?.totalCost ? parseFloat(invoice.totalCost) : 0; // Add the total cost
    acc[cientId].ClientCommercialName = invoice?.ClientCommercialName
    acc[cientId].cientId = invoice?.cientId
    acc[cientId].ClientProjectCode = invoice?.ClientProjectCode
    acc[cientId].ClientRegisteredName = invoice?.ClientRegisteredName
    acc[cientId].RaisedInvoice = invoiceTotalCostByStatus(invoice?.client_invoices, "all")
    acc[cientId].PaidInvoice = invoiceTotalCostByStatus(invoice?.client_invoices, "Paid")
    acc[cientId].CancelledInvoice = invoiceTotalCostByStatus(invoice?.client_invoices, "Cancelled")
    acc[cientId].PendingInvoice = invoiceTotalCostByStatus(invoice?.client_invoices, "Pending")
    acc[cientId].client_invoices = invoice?.client_invoices

    // Add invoice grouping by projectId within each client
    if (!acc[cientId].projects[projectId]) {
      acc[cientId].projects[projectId] = {}; // Initialize project grouping if not exists
    }
    if (!acc[cientId].projects[projectId][categoryId]) {
      acc[cientId].projects[projectId][categoryId] = [];
    }
    acc[cientId].projects[projectId][categoryId].push(invoice);
    return acc;
  }, {}) || {}


  const getGroupedTotals = (groupedInvoices) => {
    // Extract the grouped invoices as an array and calculate totals
    const totals = Object.values(groupedInvoices).reduce(
      (totals, group) => {
        totals.totalRaisedInvoice += group.RaisedInvoice || 0;
        totals.totalCancelledInvoice += group.CancelledInvoice || 0;
        totals.totalPaidInvoice += group.PaidInvoice || 0;
        totals.totalPendingInvoice += group.PendingInvoice || 0;
        totals.totalDeliveryCost += group.totalDeliveryCost || 0;
        return totals;
      },
      {
        totalRaisedInvoice: 0,
        totalCancelledInvoice: 0,
        totalPaidInvoice: 0,
        totalPendingInvoice: 0,
        totalDeliveryCost: 0,
      }
    );

    // Map the totals to an array of objects
    return [
      { amount: totals.totalDeliveryCost, status: 'Total Delivered', color: 'success' },
      { amount: totals.totalRaisedInvoice, status: 'Total Invoice Raised', color: 'lilac' },
      { amount: totals.totalPaidInvoice, status: 'Total Paid', color: 'success' },
      { amount: totals.totalPendingInvoice, status: 'Total Pending', color: 'warning' },
      { amount: totals.totalCancelledInvoice, status: 'Total Cancelled', color: 'danger' },
      { amount: (totals.totalDeliveryCost - totals.totalRaisedInvoice), status: 'Total Balance', color: 'info' },
    ];
  };

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setDateTerm(selectedDates)
    } else {
      setDateTerm([])
    }
  }

  const [showSummaryTable, setShowSummaryTable] = useState(false);

  // Function to handle showing the summary table
  const onClick = () => {
    setShowSummaryTable(true);
  };

  // Function to handle hiding the summary table
  const handleSummaryClick2 = () => {
    setShowSummaryTable(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const onClickFilter = (e) => {
    setWhere(prevState => ({
      ...prevState, // Spread the existing state
      searchQuery: searchQuery,
      dateQuery: dateQuery
    }));

  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="border-bottom mb-24 pb-16">
                <h2 className="fw-semibold mb-16 font-24">
                  <button>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                      <g data-name="20-Arrow Left">
                        <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0z" />
                        <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                      </g>
                    </svg>
                  </button>{" "}
                  Payment Details
                </h2>
                <Breadcrumb />
              </div>
            </div>
          </div>
          <div className="row despset" style={{ display: showSummaryTable ? 'none' : 'flex' }}>
            {getGroupedTotals(groupedInvoices).map((card, index) => (
              <StatusCard key={index} amount={card.amount} status={card.status} color={card.color} className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 pe-2" />
            ))}

            <div className="col-md-12 pt-24 postionTop-0 bg-white">
              <div className="mb-3 d-flex align-items-center gap-3">
                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9" onClick={onClick}>
                  <i className="mdi mdi-file-document-outline"></i> Summary
                </button>
                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-9"
                  // disabled={!searchEnable} 
                  onClick={onClickFilter}>
                  Search
                </button>
              </div>
            </div>

            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading fw-bold text-dblue mb-16">Payment Details List</div>
                <div className="panel-body">
                  <PaymentInvoiceTable groupedInvoices={groupedInvoices} />
                </div>
              </div>
            </div>
          </div>

          <div className={`row SummaryInvoiceTable ${showSummaryTable ? '' : 'd-none'}`}>
            <SummaryInvoicePayment onClick={handleSummaryClick2} />
          </div>

        </div>
      </div>


    </div>
  );
};

export default PaymentDetails;
