import React from 'react';

// FinancialYearDropdown Component
const FinancialYearDropdown = ({ selectedYear, isBlankOption = true, onYearChange }) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Get current month (0-indexed, 0 = January, 11 = December)

    // Function to generate Indian Financial Years from 2000 to the current financial year
    const generateFinancialYears = () => {
        const years = [];
        const startYear = 2017; // Starting financial year
        let endYear = currentYear;

        // Adjust endYear based on whether it's before or after April in the current year
        if (currentMonth <= 3) {
            endYear = currentYear - 1; // If it's Jan, Feb, or Mar, adjust to the previous financial year
        }

        // Generate financial years from 2000 to the current financial year
        for (let year = startYear; year <= endYear; year++) {
            const nextYear = year + 1;
            years.push(`${year}-${nextYear}`);
        }

        return years.reverse(); // Reverse to display latest years first
    };

    return (
        <select className="form-control" value={selectedYear} onChange={(e) => onYearChange(e.target.value)}>
            {isBlankOption && <option value="">Select</option>}
            {generateFinancialYears().map((year) => (
                <option key={year} value={year}>
                    {year}
                </option>
            ))}
        </select>
    );
};

export default FinancialYearDropdown;
