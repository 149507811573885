import React from 'react'
import ReportTab from './ReportTab'

const AccountsReportContainer = () => {
    return (
        <div className="dashboard-main-body">
            {/*gy-4 mt-1*/}
            <div className="row">
                <div className="card">
                    <div className="card-body p-24">
                        <ReportTab />
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Excepted VS Revenue
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="revenuChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="revenuChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="revenuChart"
                                        id="revenuChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="revenuChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="revenuChart"
                                        id="revenuChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="revenuChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="barChartRevenue" />
                        </div>
                        {/* End Chart  */}
                    </div>
                </div>
                <div className="card mt-12">
                    {/* ============== */}
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Excepted VS Received
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="receivedChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="receivedChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="receivedChart"
                                        id="receivedChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="receivedChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="receivedChart"
                                        id="receivedChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="receivedChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="barChartReceived" />
                        </div>
                        {/* End Chart  */}
                    </div>
                    {/* ================== */}
                </div>
                {/* Start Deliverd Chat one */}
                <div className="card mt-12">
                    {/* ============== */}
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Delivered Amount
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="deliveredChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="deliveredChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="deliveredChart"
                                        id="deliveredChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="deliveredChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="deliveredChart"
                                        id="deliveredChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="deliveredChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="dilverChatSecOne" />
                        </div>
                        {/* End Chart  */}
                    </div>
                    {/* ================== */}
                </div>
                {/* End Deliverd Chat one */}
                {/* Start Deliverd Chat Two */}
                <div className="card mt-12">
                    {/* ============== */}
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Delivered Amount
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="amountChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="amountChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="amountChart"
                                        id="amountChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="amountChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="amountChart"
                                        id="amountChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="amountChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="dilverChatSecTwo" />
                        </div>
                        {/* End Chart  */}
                    </div>
                    {/* ================== */}
                </div>
                {/* End Deliverd Chat Two */}
                {/* Start Profit & Loss Chat  */}
                <div className="card mt-12">
                    {/* ============== */}
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Profit &amp; Loss
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="profitChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="profitChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="profitChart"
                                        id="profitChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="profitChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="profitChart"
                                        id="profitChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="profitChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="profitlosschat" />
                        </div>
                        {/* End Chart  */}
                    </div>
                    {/* ================== */}
                </div>
                {/* End Profit & Loss Chat  */}
                {/* Start Salary Expenditure by Company  Chat Sec  */}
                <div className="card mt-12">
                    {/* ============== */}
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Salary Expenditure by Company
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="salaryChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="salaryChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="salaryChart"
                                        id="salaryChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="salaryChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="salaryChart"
                                        id="salaryChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="salaryChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="salaryExpenditureChat" />
                        </div>
                        {/* End Chart  */}
                    </div>
                    {/* ================== */}
                </div>
                {/* End Salary Expenditure by Company  Chat Sec  */}
                {/* Start Salary Department Chat sec    */}
                <div className="card mt-12">
                    {/* ============== */}
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Salary Expenditure by Department
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="departmentChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="departmentChart1"
                                    >
                                        {" "}
                                        All
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="departmentChart"
                                        id="departmentChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="departmentChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="departmentChart"
                                        id="departmentChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="departmentChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* start Chart  */}
                        <div className="mt-2 allchartsCss">
                            <div id="salaryDepartmentChat" />
                        </div>
                        {/* End Chart  */}
                    </div>
                    {/* ================== */}
                </div>
                {/* End Salary Department Chat sec    */}
            </div>
        </div>
    )
}

export default AccountsReportContainer