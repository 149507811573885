import React, { useEffect, useRef, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'; // Import Flatpickr styles

const DatePicker = () => {
  const [startDate, setStartDate] = useState('');
  const dateInputRef = useRef(null); // Create a ref for the input

  useEffect(() => {
    // Initialize flatpickr without time picker
    if (dateInputRef.current) {
      flatpickr(dateInputRef.current, {
        enableTime: false, // Disable time picker
        noCalendar: false, // Ensure the calendar is enabled
        dateFormat: 'm/d/Y', // Date format (without time)
        inline: true, // Show calendar inline
        onChange: (selectedDates, dateStr) => {
          setStartDate(dateStr); // Update the state with the selected date
        },
      });
    }

    // Cleanup to destroy flatpickr instance on unmount
    return () => {
      if (dateInputRef.current && dateInputRef.current._flatpickr) {
        dateInputRef.current._flatpickr.destroy();
      }
    };
  }, []);

  return (
    <div className="mb-20"> 
      <div className="position-relative vendorInvoicDatePkr text-center">
        <input style={{opacity: '0', display: 'none'}}
          className="form-control radius-8 bg-base"
          id="editstartDate"
          type="text"
          placeholder="MM/DD/YYYY"
          ref={dateInputRef} // Attach ref to the input
          value={startDate} // Bind the input value to state
          onChange={(e) => setStartDate(e.target.value)} // Optional for manual input
        /> 
      </div>
    </div>
  );
};

export default DatePicker;
