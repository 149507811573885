import { useState, useEffect, useRef } from "react";

const VendorFilterPopup = () => {
    const [isManagerListOpen, setIsManagerListOpen] = useState(false);
    const [managerSearchQuery, setManagerSearchQuery] = useState("");
    const [selectedManager, setSelectedManager] = useState("");

    const [isVendorListOpen, setIsVendorListOpen] = useState(false);
    const [vendorSearchQuery, setVendorSearchQuery] = useState("");
    const [selectedVendor, setSelectedVendor] = useState("");

    const [isCurrencyListOpen, setIsCurrencyListOpen] = useState(false);
    const [currencySearchQuery, setCurrencySearchQuery] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const managers = [
        { name: "Dianne Russell", role: "Product Manager", image: "/assets/images/users/user1.png" },
        { name: "Arjun Desai", role: "Project Manager", image: "/assets/images/users/user2.png" },
        { name: "Joydip Roy Chowdhury", role: "Director", image: "" },
        { name: "Sanjana Iyer", role: "Sr. Director", image: "" },
    ];
    
    const vendors = [
        { name: "Freelancer One", PanCard: "ABCDE1234F", image: "/assets/images/users/user4.png" },
        { name: "Vendor Two", PanCard: "FGHIJ5678K", image: "/assets/images/users/user3.png" },
        { name: "Freelancer Three", PanCard: "JKLMN9012P", image: "" },
    ];

    const currencies = [
        "USD", "EUR", "INR", "GBP", "JPY", "AUD", "CAD", "CHF", "CNY", "MXN", "BRL", "RUB"
    ];

    const filteredManagers = managers.filter((manager) =>
        manager.name.toLowerCase().includes(managerSearchQuery.toLowerCase())
    );

    const filteredVendors = vendors.filter(
        (vendor) =>
            vendor.name.toLowerCase().includes(vendorSearchQuery.toLowerCase()) ||
            vendor.PanCard.toLowerCase().includes(vendorSearchQuery.toLowerCase())
    );

    const filteredCurrencies = currencies.filter((currency) =>
        currency.toLowerCase().includes(currencySearchQuery.toLowerCase())
    );

    const managerRef = useRef(null);
    const vendorRef = useRef(null);
    const currencyRef = useRef(null);

    const handleManagerSelect = (manager) => {
        setSelectedManager(manager.name);
        setManagerSearchQuery(manager.name);
        setIsManagerListOpen(false);
    };

    const handleVendorSelect = (vendor) => {
        setSelectedVendor(vendor.name);
        setVendorSearchQuery(vendor.name);
        setIsVendorListOpen(false);
    };

    const handleCurrencySelect = (currency) => {
        setSelectedCurrency(currency);
        setCurrencySearchQuery(currency);
        setIsCurrencyListOpen(false);
    };

    const handleClickOutside = (event) => {
        if (
            managerRef.current &&
            !managerRef.current.contains(event.target)
        ) {
            setIsManagerListOpen(false);
        }
        if (
            vendorRef.current &&
            !vendorRef.current.contains(event.target)
        ) {
            setIsVendorListOpen(false);
        }
        if (
            currencyRef.current &&
            !currencyRef.current.contains(event.target)
        ) {
            setIsCurrencyListOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="offcanvas offcanvas-end" id="VendorFilterPopup" aria-labelledby="filterRightLayout">
            <div className="offcanvas-header border-bottom">
                <h5 className="modal-title">Filter</h5>
                <button type="button" className="btn-close d-none" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
                <div className="assignPopoHeight">
                    {/* Project Manager Section */}
                    <div className="col-md-12">
                        <label className="form-label">Project Manager</label>
                        <div className="ProjectManagersearch" ref={managerRef}>
                            <div className="selectManger">
                                <input
                                    type="text"
                                    className="form-select"
                                    placeholder="Select Manager"
                                    value={managerSearchQuery}
                                    onClick={() => setIsManagerListOpen(true)}
                                    onChange={(e) => {
                                        setManagerSearchQuery(e.target.value);
                                        setSelectedManager("");
                                    }}
                                />
                                {selectedManager && (
                                    <button
                                        type="button"
                                        className="clear-btn"
                                        onClick={() => {
                                            setSelectedManager(""); // Clear selected manager
                                            setManagerSearchQuery(""); // Clear input field
                                        }}
                                    >
                                        ✕
                                    </button>
                                )}
                            </div>
                            {isManagerListOpen && (
                                <div className="ProjectManagerList">
                                    {filteredManagers.length > 0 ? (
                                        filteredManagers.map((manager, index) => (
                                            <label key={index} className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 py-10" onClick={() => handleManagerSelect(manager)}>
                                            <div className="user-cell">
                                                <span className="user-initials">
                                                    {manager.image ? (
                                                        <img
                                                            src={manager.image} // Display manager's image
                                                            className="img-fluid"
                                                            alt={manager.name}
                                                        />
                                                    ) : (
                                                        // If image is not available, display initials
                                                        manager.name
                                                            .split(" ")
                                                            .map((word) => word.charAt(0).toUpperCase())
                                                            .join("")
                                                    )}
                                                </span>
                                                <div className="flex-grow-1">
                                                    <h6 className="text-sm mb-0 fw-medium">{manager.name}</h6>
                                                    <span className="text-sm text-secondary-light fw-normal">{manager.role}</span>
                                                </div>
                                            </div>
                                            <input type="radio" name="manager-radio" />
                                            <span className="checkmark rounded-pill" />
                                        </label>
                                        
                                        ))
                                    ) : (
                                        <div className="text-sm text-secondary-light p-20 text-center">
                                            No managers found
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Vendor/Freelancer Section */}
                    <div className="col-md-12 mt-24">
                        <label className="form-label">Vendor/Freelancer</label>
                        <div className="ProjectManagersearch" ref={vendorRef}>
                            <div className="selectManger">
                                <input
                                    type="text"
                                    className="form-select"
                                    placeholder="Select Vendor/Freelancer"
                                    value={vendorSearchQuery}
                                    onClick={() => setIsVendorListOpen(true)}
                                    onChange={(e) => {
                                        setVendorSearchQuery(e.target.value);
                                        setSelectedVendor("");
                                    }}
                                />
                                {selectedVendor && (
                                    <button
                                        type="button"
                                        className="clear-btn"
                                        onClick={() => {
                                            setSelectedVendor(""); // Clear selected vendor
                                            setVendorSearchQuery(""); // Clear input field
                                        }}
                                    >
                                        ✕
                                    </button>
                                )}
                            </div>
                            {isVendorListOpen && (
                                <div className="ProjectManagerList">
                                    {filteredVendors.length > 0 ? (
                                        filteredVendors.map((vendor, index) => (
                                            <label
                                                key={index}
                                                className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 py-10"
                                                onClick={() => handleVendorSelect(vendor)}
                                            >
                                                <div className="user-cell">
                                                <span className="user-initials">
    {vendor.image ? (
        <img
            src={vendor.image} // Display vendor's image
            className="img-fluid"
            alt={vendor.name}
        />
    ) : (
        // If image is not available, display initials
        vendor.name
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase())
            .join("")
    )}
</span>
                                                    <div className="flex-grow-1">
                                                        <h6 className="text-sm mb-0 fw-medium">{vendor.name}</h6>
                                                        <span className="text-sm text-secondary-light fw-normal">
                                                            Pan Card: {vendor.PanCard}
                                                        </span>
                                                    </div>
                                                </div>
                                                <input type="radio" name="vendor-radio" />
                                                <span className="checkmark rounded-pill" />
                                            </label>
                                        ))
                                    ) : (
                                        <div className="text-sm text-secondary-light p-20 text-center">
                                            No Vendor/Freelancer found
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Currency Section with Search */}
                    <div className="col-md-12 mt-24">
                        <label className="form-label">Currency</label>
                        <div className="position-relative" ref={currencyRef}>
                            <div className="selectManger selectedCurrency">
                                <input
                                    type="text"
                                    className="form-select"
                                    placeholder="Select Currency"
                                    value={currencySearchQuery}
                                    onClick={() => setIsCurrencyListOpen(true)}
                                    onChange={(e) => setCurrencySearchQuery(e.target.value)}
                                />
                                {selectedCurrency && (
                                    <button
                                        type="button"
                                        className="clear-btn"
                                        onClick={() => {
                                            setSelectedCurrency(""); // Clear selected currency
                                            setCurrencySearchQuery(""); // Clear input field
                                        }}
                                    >
                                        ✕
                                    </button>
                                )}
                            </div>
                            {isCurrencyListOpen && (
                                <div className="ProjectManagerList">
                                    {filteredCurrencies.length > 0 ? (
                                        filteredCurrencies.map((currency, index) => (
                                            <label
                                                key={index}
                                                className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 py-10"
                                                onClick={() => handleCurrencySelect(currency)}
                                            >
                                                <div className="flex-grow-1">
                                                    <h6 className="text-sm mb-0 fw-medium">{currency}</h6>
                                                </div>
                                                <input type="radio" name="currency-radio" />
                                                <span className="checkmark rounded-pill" style={{top: '8px'}} />
                                            </label>
                                        ))
                                    ) : (
                                        <div className="text-sm text-secondary-light p-20 text-center">
                                            No currencies found
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="offcanvas-footer px-16">
                <button
                    type="button"
                    className="btn btn-primary radius-6 px-14 py-12 text-md w-100 my-12" 
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                >
                    Save Filter
                </button>
            </div>
        </div>
    );
};

export default VendorFilterPopup