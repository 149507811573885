import React from 'react';

const Breadcrumb = () => {
  return (
    <ul className="d-flex align-items-center gap-2">
      <li className="fw-medium">
        <a href="/" className="d-flex align-items-center gap-1 hover-text-primary">
          <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg" />
          Special Access
        </a>
      </li>
      <li>-</li>
      <li className="fw-medium">Payment Details</li>
    </ul>
  );
};

export default Breadcrumb;
