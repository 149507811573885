import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { updateCostUnit } from "src/api/ProductionApi";

const MDTRow = (props) => {
    const { index, row,search } = props
            let unitCost;
            let unitCostType;
            let unitCostId;
             // Check which unitCost to use based on the condition
             if (row._id.subCategoryId === null && row._id.categoryId === null) {
               // Use projectDetails.unitCost if both subCategoryId and categoryId are null
               unitCost = row.projectDetails.unitCost;
               unitCostType = "project";
               unitCostId=row.projectDetails._id
             } else if (row._id.subCategoryId === null) {
               // Use categoryDetails.unitCost if subCategoryId is null but categoryId is not null
               unitCost = row.categoryDetails.unitCost;
               unitCostType = "category";
               unitCostId=row.categoryDetails._id
             } else {
               // Use subCategoryDetails.unitCost if both are not null
               unitCost = row?.subCategoryDetails?.unitCost;
               unitCostType = "subCategory";
               unitCostId=row?.subCategoryDetails?._id
             }       
             // Multiply the totalDuration with the appropriate unitCost
             const totalCost = (row.totalDurationSum * unitCost).toFixed(2);
      const [unitVal,setUnitVal] = useState(unitCost)


    const handleCostUnitChange = async(e) => {
        const value = e.target.value;
          if (isNaN(value) || parseFloat(value) <= 0) { 
            toast.error("Invalid cost unit value. Please enter a valid number.");
            return; // Prevent further processing if desired
          }
        setUnitVal(value)
        await updateCostUnit({unitCostType,unitCostId,value});
        // search();
    }
    useEffect(() => {
      setUnitVal(unitCost);
  }, [unitCost]);
    return (
        <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ul className="ProjeAttnc d-flex">
            {row.productionSlug}
            {/* {row.projectDetails.map((detail, i) => (
              <li key={i}>
                {detail} <i className="mdi mdi-chevron-right"></i>
              </li>
            ))} */}
          </ul>
        </td>
        <td className="text-center" data={row.categoryId}>{row.projectDetails.participants?.employeeDetails?.length>0?row.projectDetails?.participants?.employeeDetails[0]?.firstName + " " + row.projectDetails?.participants?.employeeDetails[0]?.lastName:"NA"}</td>
        <td className="text-center">{row.totalDurationSum?.toFixed(2)}</td>
        <td className="text-center">
          <div className="w-110-px mx-auto">
              <input
                key={`index-${index}`}
                type="text"
                className="form-control"
                value={unitVal}
                onChange={handleCostUnitChange}
                onBlur={search}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    search(); // Trigger the search function when Enter is pressed
                  }
                }}
              />
          </div>
        </td>              
        <td className="text-center">{totalCost}</td>
      </tr>
    )
}
export default MDTRow