import React, { useState, useEffect } from 'react';
import { assignUserByTask, taskUpdateAPI } from 'src/api/TaskApi';
import { toast } from 'react-toastify';
import UserMultiSelect from 'src/business-components/UserMultiSelect';
import { shallowEqual, useSelector } from 'react-redux';
import PriceRangeSlider from 'src/components/price-range-slider/PriceRangeSlider';

const EditTask = ({ taskId, onTaskSaved }) => {
    const { user } = useSelector((state) => state.auth, shallowEqual)
    const [userId, setUserId] = useState(null);
    const [taskname, setTaskName] = useState(taskId?.taskName || '');
    const [target, setTarget] = useState(taskId?.target || '');
    const [unit, setUnit] = useState('');
    const [item, setItem] = useState('');
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [minPrice, setMinPrice] = useState(taskId?.minSalaryMonth || null);
    const [maxPrice, setMaxPrice] = useState(taskId?.maxSalaryMonth || null);
    // console.log("taskId", taskId, minPrice, maxPrice);

    useEffect(() => {
        // Update the state with the new task's details whenever taskId changes
        if (taskId) {
            setTaskName(taskId.taskName || '');
            setTarget(taskId.target || '');
            setItem(taskId.item || '');
            setUnit(taskId.unit || '');
            const assignedUsersMap = taskId.assigneeIds.map((user) => ({
                id: user._id,
                value: `${user.firstName} ${user.lastName} (${user.employeeID})`,
                label: `${user.firstName} ${user.lastName} (${user.employeeID})`
            })) || [];
            setAssignedUsers(assignedUsersMap || []);
        }
        // setTaskName('');
        // setTarget('');
        // setItem('');
        // setUnit('');
        // setAssignedUsers([]);
    }, [taskId]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setUserId(storedUser?._id);
        }
    }, []);

    const updateTask = async () => {
        const assigneeIds = assignedUsers.map((user) => user.id);
        let data = {
            taskName: taskname,
            target: target,
            unit: unit,
            item: item,
            minSalaryMonth: minPrice,
            maxSalaryMonth: maxPrice,
            by: userId,
            assigneeIds: assigneeIds,
        }
        try {
            const resp = await taskUpdateAPI({ taskId: taskId?._id, data: data });
            if (onTaskSaved) {
                await assignUserByTask({ projectId: taskId?.projectId?._id, assigneeIds });
                onTaskSaved();
            }
        } catch (err) {
            toast.error('Failed to edit task. ' + err.message);
        }
    }

    const handleChangeItem = (e) => {
        const selectedItem = e.target.value;
        setItem(selectedItem);

        if (selectedItem === 'Daily Target') {
            setUnit('Per Day');
        } else if (selectedItem === 'Bound' || selectedItem === 'Open') {
            setUnit('min');
        } else {
            setUnit('');
        }
    };
    const onPriceChange = (min, max) => {
        setMinPrice(min);
        setMaxPrice(max);
    }

    return (
        <div
            className="offcanvas offcanvas-end filterSecPopup"
            id="EditTaskPopup"
            aria-labelledby="editTaskSec"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="addNewTaskSec">
                    Edit Task
                </h5>
                <button
                    type="button"
                    className="btn-close d-none"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body pt-0 pb-0">
                <div className="assignPopoHeight">
                    <div className="selectSec pt-0">
                        <label htmlFor="">Task</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Task Name"
                            value={taskname}
                            disabled={user?.roleDetails?.roleName === 'Admin' ? false : true}
                            onChange={(e) => { setTaskName(e.target.value) }}
                        />
                    </div>

                    <div id="addTaskMoudalCantainer" />
                    <div className="d-flex align-items-center gap-2">
                        <div className="selectSec pt-0 w-50">
                            <label htmlFor="">Target</label>
                            <div className="costSec">
                                <div className="costinputSec">
                                    <input
                                        type="number"
                                        min={0}
                                        defaultValue={0}
                                        className="w-100"
                                        value={target}
                                        disabled={user?.roleDetails?.roleName === 'Admin' ? false : true}
                                        onChange={(e) => { setTarget(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="selectSec filterStyleSec pt-0 w-50">
                            <label htmlFor="">Item</label>
                            <select className="form-select"
                                value={item}
                                disabled={user?.roleDetails?.roleName === 'Admin' ? false : true}
                                onChange={handleChangeItem}
                                style={{ width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ced4da' }}
                            >
                                <option value="">Select Item</option>
                                <option value={'Daily Target'}>Daily Target</option>
                                <option value={'Bound'}>Bound</option>
                                <option value={'Open'}>Open</option>
                            </select>
                        </div>
                    </div>
                    <div className="selectSec filterStyleSec pt-2">
                        <label htmlFor="">Unit</label>
                        <input
                            type="text"
                            className="form-control"
                            value={unit}
                            readOnly
                            style={{ backgroundColor: '#e9ecef', cursor: 'not-allowed' }}
                        />
                    </div>
                    <div className="selectSec filterStyleSec pt-2">
                        <label htmlFor="">Salary Bracket (Monthly) </label>

                        <PriceRangeSlider onPriceChange={onPriceChange} taskId={taskId} userRole={user?.roleDetails?.roleName} />
                    </div>
                    <div className="selectSec filterStyleSec pt-2">
                        <label htmlFor="">Assign to</label>
                        <UserMultiSelect
                            className="form-control p-0 border-0 heightsetAuto"
                            name="assignTo"
                            value={assignedUsers}
                            maxPrice={maxPrice}
                            minPrice={minPrice}
                            onChange={(value) => setAssignedUsers(value)}
                        />
                    </div>
                </div>
                <div className="psstyky">
                    <button
                        data-bs-dismiss="offcanvas" aria-label="Close"
                        type="button"
                        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                        onClick={updateTask}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditTask;
