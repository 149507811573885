import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextEditor from "src/components/TextEditor";
import FileUpload from "./FileUpload";
import { useParams } from 'react-router-dom';
import config from "src/config";
import { toast } from 'react-toastify';
import { postRequest } from 'src/utils/RequestUtil';
import { scopesProjectSearchAPI } from "src/api/ReportsAPI";
import ProjectScopeHistory from "./ProjectScopeHistory";
import { dateFormat } from "src/utils/utils";

const ProjectScopeForm = () => {
    const { projectId } = useParams();
    const [scopes, setScopes] = useState([]);
    const [initialScope, setInitialScope] = useState({ projectScope: "" });
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [showEditor, setShowEditor] = useState(false);
    const [showView, setShowView] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [updateLatest, setUpdateLatest] = useState(true);
    const [data, setData] = useState(null);

    // Validation schema for the form using Yup
    const validationSchema = Yup.object({
        projectScope: Yup.string().required("Project Scope is required"),
    });

    const getProjectScopes = async () => {
        try {
            const resp = await scopesProjectSearchAPI({ projectId });
            setScopes(resp?.data?.data);

            setInitialScope({
                projectScope: resp?.data?.data[0]?.projectScope || "", // Make sure it has a default value
            });
            setData(resp?.data?.data[0])

            if (resp?.data?.data.length) {
                setShowView(true)
            }


            setFiles(resp?.data?.data[0]?.files || []); // Ensure files have default empty array

            setIsLoading(false); // Data is loaded
        } catch (error) {
            console.error('Error fetching project scopes:', error);
            setIsLoading(false); // Data is loaded even if there's an error
        }
    };

    useEffect(() => {
        getProjectScopes();
    }, [projectId]);

    if (isLoading) {
        return <div>Loading...</div>; // Display loading state until data is fetched
    }
    const handleAddScopeClick = () => {
        setShowEditor(true); // Show the editor container on button click
        setSaveButton(true);
        setShowView(true)
    };
    const handleModifyClick = () => {
        setSaveButton(true);
    };
    // const handleItem = (item) => {
    //     setSaveButton(true)
    //     console.log("handleItemhandleItem", item);
    //     setInitialScope({
    //         projectScope: item?.projectScope || "", // Make sure it has a default value
    //     });

    //     setFiles(item?.files || [])
    // }
    const handleItem = (item) => {
        setUpdateLatest(item.index ? false : true);
        setSaveButton(false);
        setData(item)
        setInitialScope({
            projectScope: item?.projectScope || "", // Set projectScope
        });
        setFiles(item?.files || []); // Set files
    };


    return (
        <div>

            {!saveButton && scopes.length > 0 && (
                <div className="d-flex justify-content-between align-items-center py-16">
                    <div className="">
                        Created by
                        <div className="d-flex align-items-center gap-2 font-14 text-black fw-medium mt-6">
                            <span className="img imgsSet me-0">
                                <img src={data?.employees?.profilePicture} />
                            </span>{data?.employees.firstName + " " + data?.employees.lastName}
                        </div>
                    </div>
                    <div className="CreatedDate1">Created Date: {dateFormat(data?.ts)}</div>
                </div>
            )}
            {showView && (
                <Formik
                    key={JSON.stringify(initialScope)} // Unique key ensures reinitialization
                    enableReinitialize={true}
                    initialValues={initialScope}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        values.projectId = projectId;
                        values.files = files?.map((file) => file.url || file);
                        setSubmitting(false);

                        const save_url = `${config.baseUrl}report/saveProjectScope`;
                        try {
                            const result2 = await postRequest({
                                url: save_url,
                                data: values,
                            });
                            toast.success(result2.data.message);
                            getProjectScopes(); // Re-fetch to update scope
                            setSaveButton(false);
                        } catch (error) {
                            toast.error('Failed to submit form.');
                        }
                    }}
                >
                    {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                        <Form>
                            {/* Text Editor Field */}
                            <div className={`form-group mt-4 ${saveButton ? '' : 'Scopeactive'}`}>
                                <label htmlFor="projectScope">Project Scope</label>
                                <TextEditor
                                    value={values.projectScope}
                                    onChange={(value) => setFieldValue("projectScope", value)}
                                    readOnly={!saveButton}
                                />
                                {errors.projectScope && touched.projectScope && (
                                    <div className="text-danger mt-2">{errors.projectScope}</div>
                                )}
                            </div>

                            {/* File Upload Field */}
                            <div className={`form-group mt-4 ${saveButton ? '' : 'Scopeactive'}`}>
                                <FileUpload files={files} setFiles={setFiles} />
                            </div>

                            {/* Submit Button */}
                            {updateLatest && (saveButton ? (
                                <button
                                    type="submit"
                                    className="btn btn-primary mt-4"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </button>
                            ) : (
                                <div
                                    // type="button"
                                    className="btn btn-primary mt-4"
                                    onClick={handleModifyClick}
                                >
                                    Modify
                                </div>
                            ))}


                        </Form>
                    )}
                </Formik>
            )}

            {scopes.length === 0 ? (
                showEditor === false && (
                    <div className="text-center py-32 border-top AddProjectScope">
                        <button
                            type="button"
                            className="btn btn-primary-600 radius-8 px-20 py-11 mb-32"
                            onClick={handleAddScopeClick} // Handle button click
                        >
                            Add Project Scope
                        </button>
                        <p>No Project Scope Added</p>
                    </div>
                )
            ) : (

                <div className="ProjectScopeHistory">
                    <ProjectScopeHistory scopes={scopes} onItem={handleItem} />
                </div>
            )}
        </div>
    );
};

export default ProjectScopeForm;
