import React from "react";
import { dateFormat } from "src/utils/utils";

const ProjectScopeHistory = ({ scopes, onItem }) => {
  const data = [
    {
      id: 1,
      dateTime: "August 2025, 11:30:30",
      employees: { name: "Nidhi Sharma", image: "" }, // no image
      attachment: 2,
    },
    {
      id: 2,
      dateTime: "August 2025, 11:30:30",
      employees: { name: "John Doe", image: "/assets/images/avatar/avatar1.png" }, // with image
      attachment: 2,
    },
    {
      id: 3,
      dateTime: "August 2025, 11:30:30",
      employees: { name: "Nidhi Sharma", image: "" }, // no image
      attachment: 2,
    },
    {
      id: 4,
      dateTime: "August 2025, 11:30:30",
      employees: { name: "Emma Watson", image: "/assets/images/avatar/avatar2.png" }, // with image
      attachment: 2,
    },
  ];

  const getInitials = (name) => {
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0).toUpperCase()).join("");
    return initials;
  };

  return (
    <div style={{ padding: "20px" }}>
      <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2 mb-16">Project Scope History</h6>
      <div className="table-body-scrollable">
        <table className="table basic-border-table mb-0 table-hover">
          <thead>
            <tr style={{ background: "#f2f2f2" }}>
              <th>S.No.</th>
              <th>Date & Time</th>
              <th>Modified by</th>
              <th className="text-center">Attachment</th>
              <th className="text-center">View</th>
            </tr>
          </thead>
          <tbody>
            {scopes.map((row, index) => (
              <tr key={row?._id}>
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td>{dateFormat(row?.ts)}</td>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {row?.employees?.profilePicture ? (
                      <img
                        src={row?.employees?.profilePicture}
                        alt={row?.employees.firstName}
                        style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          backgroundColor: "#007bff",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "16px",
                        }}
                      >
                        {getInitials(row?.employees.firstName + " " + row?.employees.lastName)}
                      </div>
                    )}
                    <span style={{ marginLeft: "10px" }}>{row?.employees.firstName + " " + row?.employees.lastName}</span>
                  </div>
                </td>
                <td style={{ textAlign: "center" }}>{row?.files.length}</td>
                <td style={{ textAlign: "center" }}>
                  <button
                    onClick={() => onItem({ ...row, index })}
                    className="badge text-sm fw-semibold border border-primary-600 text-primary-600 bg-transparent px-20 py-9 radius-4 text-white">
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectScopeHistory;
