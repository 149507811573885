import { put, call } from 'redux-saga/effects';
import { clientPaymentDetailsSearchAPI } from 'src/api/ClientInvoiceApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { CLIENT_PAYMENT_DETAILS_ACTIONS } from '../actions/ClientPaymentDetailsActions';
import { toast } from 'react-toastify';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(clientPaymentDetailsSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(clientPaymentDetailsSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }
        delete data[0].password;

        yield put({
            type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPSERT_SUCCESS,
            currentRecord: data[0],
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

// function* insert(payload) {
//     try {
//         const {
//             data: {
//                 data,
//             },
//         } = yield call(clientPaymentDetailsCreateAPI, payload);
//         if (data?._id && payload.profilePicture) {
//             // yield call(clientPaymentDetailsProfilePicAPI, {
//             //     clientPaymentDetailsId: data?._id,
//             //     data: payload,
//             // });
//         }


//         yield put(headerShowAction({
//             data: 'Created',
//         }));
//         toast("clientPaymentDetails Created")

//         yield put({
//             type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPSERT_SUCCESS,
//             data: {
//                 ...payload,
//                 _id: data?._id,
//             },
//         });
//         yield put({
//             type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_RESET
//         });
//         yield put({
//             type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH
//         });
//     }
//     catch (error) {
//         toast.error(error?.message);
//         toast.error(error?.response?.data?.message);
//         yield put({
//             type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPSERT_ERROR,
//         });
//     }
// }

function* bulkUpload(payload) {
    try {






        // const { finalData } = yield call(clientPaymentDetailsUploadAPI, payload);

        // toast(`${finalData.length} clientPaymentDetails uploaded successfully`)

        yield put({
            type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPLOAD_SUCCESS,
            data: {
                ...payload,

            },
        });
        yield put({
            type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPLOAD_ERROR,
        });
    }
}

// function* update(clientPaymentDetailsId, payload) {
//     try {
//         yield call(clientPaymentDetailsUpdateAPI, {
//             clientPaymentDetailsId,
//             data: payload?.data,
//         });

//         yield put(headerShowAction({
//             data: 'Updated',
//         }));
//         if (payload.deleted === true) {
//             toast("clientPaymentDetails deleted")
//         }
//         else {
//             toast("clientPaymentDetails account updated")
//         }

//         yield put({
//             type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH
//         });
//     }
//     catch (error) {
//         console.error('LOG: upsert error', error);
//         yield put(headerShowAction({
//             data: error.response.data.data,
//         }));

//         yield put({
//             type: CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPSERT_ERROR,
//         });
//     }
// }

// function* upsert({
//     _id,
//     ...payload
// }) {
//     if (_id) {
//         yield update(_id, payload);
//     }
//     else {
//         yield insert(payload);
//     }
// }

export default [
    takeFirstSagaUtil(CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_SEARCH, search),
    // takeFirstSagaUtil(CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPSERT, upsert),
    takeFirstSagaUtil(CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_UPLOAD, bulkUpload),
    takeFirstSagaUtil(CLIENT_PAYMENT_DETAILS_ACTIONS.CLIENT_PAYMENT_DETAILS_EDIT, edit),
];
