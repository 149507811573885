import React, { useState, useEffect, useRef } from 'react';

const YearDropdown = () => {
  const [selectedYear, setSelectedYear] = useState('2024-2025'); // Default selected year
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility state
  const dropdownRef = useRef(null); // Reference to the dropdown component

  const years = [
    '2015-2016',
    '2016-2017',
    '2017-2018',
    '2018-2019',
    '2019-2020',
    '2020-2021',
    '2021-2022',
    '2022-2023',
    '2023-2024',
    '2024-2025',
  ];

  const handleIconClick = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // Add event listener on mount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup on unmount
    };
  }, []);

  return (
    <div className='d-flex position-relative align-items-center border radius-4'>
      {/* Selected Year */}
      <div
        style={{
          padding: '8px 12px',
          fontSize: '16px',
          cursor: 'pointer',
        }}
        onClick={handleIconClick}
      >
        {selectedYear}
      </div>

      {/* Calendar Icon */}
      <i
        className="mdi mdi-calendar-outline pe-12"
        style={{ fontSize: '20px', cursor: 'pointer', color: '#007BFF' }}
        onClick={handleIconClick} // Toggle dropdown on icon click
      ></i>

      {/* Dropdown */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef} // Attach the reference to the dropdown
          style={{
            position: 'absolute',
            top: '40px',
            left: '0',
            background: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            zIndex: 1000,
            width: '150px',
            maxHeight: '250px',
            overflow: 'auto',
          }}
        >
          {years.map((year, index) => (
            <div
              key={index}
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                background: selectedYear === year ? '#f0f0f0' : 'transparent',
              }}
              onClick={() => handleYearSelect(year)}
            >
              {year}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default YearDropdown;
