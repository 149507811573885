import React, { useState } from "react";

const ParcelAmount = () => {
    const [tableData, setTableData] = useState([
        { date: "09 Aug 2024", amount: 300 },
        { date: "10 Aug 2024", amount: 200 },
        { date: "11 Aug 2024", amount: 1500 },
        { date: "12 Aug 2024", amount: 900 },
      ]);
  return (
    <div>
      <div
        className="modal fade pcdModal producSecMAin cusmodl"
        id="ParcelAmount"
        tabIndex={-1}
        aria-labelledby="ParcelAmount"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div
                className="table-body-scrollable">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.date}</td>
                        <td
                          className={`text-end ${
                            row.amount > 0 ? "text-success-700" : "text-danger-700"
                          }`}
                        >
                          {row.amount > 0 ? `+${row.amount}` : row.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Net Amount</td>
                      <th className="text-end text-neutral-900">
                        ₹
                        {tableData.reduce((sum, row) => sum + row.amount, 0).toFixed(
                          2
                        )}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParcelAmount;
