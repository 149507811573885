import React, { useState, useEffect } from "react";
import { userSearchAPI } from "src/api/UserApi";
// import "./App.css";

const PriceRangeSlider = ({ onPriceChange, taskId, userRole }) => {
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(300);
    const minRange = 0;
    // const maxRange = 500;
    const [maxRange, setMaxRange] = useState(0);
    const priceGap = 1;

    useEffect(() => {
        // Fetch data once
        const getOptions = async () => {
            const { data: { data } } = await userSearchAPI({});
            // Get the max salary
            const maxSalary = Math.max(...data.map((item) => item.monthlySalary));
            setMaxRange(Math.round(maxSalary / 1000))
        };
        getOptions();
    }, []); // Run only once on component mount
    // console.log("maxPricemaxPrice", maxPrice, taskId);


    const handleInputChange = (e, type) => {
        const value = parseInt(e.target.textContent);

        if (type === "min" && maxPrice - value >= priceGap) {
            setMinPrice(value);
        } else if (type === "max" && value - minPrice >= priceGap) {
            setMaxPrice(value);
        }
    };

    useEffect(() => {
        onPriceChange(minPrice, maxPrice);
    }, [minPrice, maxPrice, onPriceChange]);

    useEffect(() => {
        setMinPrice(taskId?.minSalaryMonth || 0)
        setMaxPrice(taskId?.maxSalaryMonth || 300)
    }, [taskId?.minSalaryMonth, taskId?.maxSalaryMonth]);

    const handleRangeChange = (e, type) => {
        const value = parseInt(e.target.value);

        if (type === "min" && maxPrice - value >= priceGap) {
            setMinPrice(value);
        } else if (type === "max" && value - minPrice >= priceGap) {
            setMaxPrice(value);
        }
    };

    const progressStyle = {
        left: `${(minPrice / maxRange) * 100}%`,
        right: `${100 - (maxPrice / maxRange) * 100}%`,
    };

    return (
        <div className="wrapper">
            <div className="range-input mt-8">
                <div className="slider">
                    <div className="progress" style={progressStyle}></div>
                </div>
                <input
                    type="range"
                    className="range-min"
                    min={minRange}
                    max={maxRange}
                    value={minPrice}
                    step="1"
                    // onChange={(e) => handleRangeChange(e, "min")}
                    onChange={userRole === 'Admin' ? (e) => handleRangeChange(e, "min") : null}
                />
                <input
                    type="range"
                    className="range-max"
                    min={minRange}
                    max={maxRange}
                    value={maxPrice}
                    step="1"
                    // onChange={(e) => handleRangeChange(e, "max")}
                    onChange={userRole === 'Admin' ? (e) => handleRangeChange(e, "max") : null}
                />
            </div>
            <div className="price-input d-flex justify-content-between align-items-center">
                <div className="field">
                    <span>Min</span>
                    <div
                        className="input-min"
                        // contentEditable={userRole === 'Admin'}
                        contentEditable={userRole === 'Admin' ? true : false}
                        suppressContentEditableWarning
                        // onBlur={(e) => handleInputChange(e, "min")}
                    >
                        {minPrice}
                    </div>K
                </div>
                <div className="field">
                    <span>Max</span>
                    <div
                        className="input-max"
                        // contentEditable
                        contentEditable={userRole === 'Admin' ? true : false}
                        suppressContentEditableWarning
                        // onBlur={(e) => handleInputChange(e, "max")}
                    >
                        {maxPrice}
                    </div>K
                </div>
            </div>
        </div>
    );
};

export default PriceRangeSlider
