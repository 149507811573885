import { all } from 'redux-saga/effects'; 
import AppSaga from './containers/app-container/saga/AppSaga'; 
import HomeSaga from './containers/home-container/saga/HomeSaga';
import AuthSaga from './containers/home-container/saga/AuthSaga'; 
import ClientSaga from './containers/client-container/saga/ClientSaga'; 
import ProductionSaga from './containers/production-container/saga/ProductionSaga'; 
import ProjectSaga from './containers/project-container/saga/ProjectSaga'; 
import TaskSaga from './containers/project-container/Task/saga/TaskSaga'; 
import ModuleSaga from './containers/project-container/Module/saga/ModuleSaga'; 
import ClientProductionSaga from './containers/project-container/View/ClientDeliveryWise/saga/ClientProductionSaga';
import EmployeeDataSaga from './containers/employee-container/Data/saga/EmployeeDataSaga';
import UserSaga from './containers/user-container/saga/UserSaga';
import PermissionsMasterSaga from './containers/permission-container/saga/PermissionsMasterSaga';
import RolePermissionsSaga from './containers/permission-container/saga/RolePermissionsSaga';
import DashboardSaga from './containers/dashboard-container/saga/DashboardSaga';
import FeedbackSaga from './containers/feedback-container/saga/FeedbackSaga';
import VendorSaga from './containers/vendor-container/saga/VendorSaga';
import SalarySaga from './containers/salary-container/saga/SalarySaga';
import ClientInvoiceSaga from './containers/client-invoice-container/saga/ClientInvoiceSaga';
import VendorDeliverySaga from './containers/project-container/View/VendorDelivery/saga/VendorDeliverySaga';
import ClientPaymentDetailsSaga from './containers/client-invoice-container/saga/ClientPaymentDetailsSaga';
 

export default function* sagas() {
    yield all([
        ...HomeSaga,
        ...FeedbackSaga,
        ...ClientInvoiceSaga,
        ...ClientPaymentDetailsSaga,
        ...SalarySaga,
        ...AuthSaga, 
        ...UserSaga,
        ...EmployeeDataSaga,
        ...AppSaga,
        ...ProjectSaga,
        ...ClientSaga,
        ...TaskSaga,
        ...ModuleSaga,
        ...ProductionSaga,
        ...ClientProductionSaga,
        ...PermissionsMasterSaga,
        ...RolePermissionsSaga,
        ...DashboardSaga,
        ...VendorSaga,
        ...VendorDeliverySaga
    ]);
}
