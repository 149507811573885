import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";

const OADAndCMDList = () => {
  const { OAD_CMD_Details } = useSelector((state) => state.ProjectReducer, shallowEqual);
  const users = (OAD_CMD_Details.project) ? OAD_CMD_Details.project[OAD_CMD_Details.type.toLowerCase() === "oad" ? "oad" : "cmd"] || [] : [];
  const netTotal = (OAD_CMD_Details.project) ? OAD_CMD_Details.project[OAD_CMD_Details.type.toLowerCase()].reduce((total, item) => (item.type == "add") ? (total + item.value) : (total - item.value), 0) : 0;
  const user = useSelector((state) => state.auth.user);
  // console.log('users1=>',users1);
  // Table start modal popup
  //   const users = [
  //     {
  //       description: 'watchful and prompt to meet danger or emergency an alert guard trying to stay alert to possible',
  //       name: 'Nidhi Sharma',
  //       img: '', 
  //       date: '09 Aug 2024', 
  //       amount: '+900', 
  //       statusClass: 'text-success-800',
  //     },
  //     {
  //       description: 'Sep Allocation',
  //       name: 'Abhishek Kumar Shukla',
  //       img: '', 
  //       date: '09 Aug 2024', 
  //       amount: '-300', 
  //       statusClass: 'text-danger-700',
  //     },
  //     {
  //       description: 'watchful and prompt to meet',
  //       name: 'Sibanarayana Jena',
  //       img: '', 
  //       date: '09 Aug 2024', 
  //       amount: '+200', 
  //       statusClass: 'text-success-800',
  //     },
  //     {
  //       description: 'Sep Allocation',
  //       name: 'Aakash Saxena',
  //       img: 'assets/images/users/user1.png', 
  //       date: '09 Aug 2024', 
  //       amount: '-1500', 
  //       statusClass: 'text-danger-700',
  //     },
  //     {
  //       description: 'watchful and prompt to meet',
  //       name: 'Anupriya',
  //       img: '', 
  //       date: '09 Aug 2024', 
  //       amount: '+900', 
  //       statusClass: 'text-success-800',
  //     },
  //   ];
  // Function to get the initials from the name
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : '');
    return initials.toUpperCase();
  };

  // Function to generate a dynamic background color based on the user's name
  const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 60%, 70%)`; // Adjust saturation and lightness as needed
    return color;
  };

  return (
    <>
      <div className="modal fade cusmodl" id="ViewCmdCostModual" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">Ledger</h6><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table bordered-table mb-0 custm2nth">
                  <thead className="sticky-header">
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col">Updated by</th>
                      <th scope="col">Description</th>
                      <th scope="col">Date</th>
                      <th scope="col" className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((log, index) => {
                      const userDetails = log.userDetails?.[0] || user
                      const userName = `${userDetails?.firstName} ${userDetails?.lastName}`;
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td> {/* Sr. No. */}
                          <td>
                            <div className="d-flex align-items-center">
                              {/* <span className="UsIMgName"><img src={user.img} alt={user.name} className="flex-shrink-0 radius-8" /></span> */}
                              <span
                                className="UsIMgName"
                                style={{ backgroundColor: getBackgroundColor(userName) }}
                              >
                                {log.img ? (
                                  <img src={userDetails?.profilePicture} alt={userName} className="flex-shrink-0 radius-8" />
                                ) : (
                                  <span className="initials">{getInitials(userName)}</span>
                                )}
                              </span>

                              <span className="text-md text-secondary-light fw-semibold flex-grow-1">{userName}</span>
                            </div>
                          </td>
                          <td><div className="mw300px">{log.description}</div></td>
                          <td>{moment(log.ts).format("DD/MM/YYYY hh:mm A")}</td>
                          {/* <td className="text-end"><span className={`${log.statusClass} px-24 py-4 rounded-pill fw-medium text-lg fw-bold`}>{(log.type==="add")?`+${log.value}`:`-${log.value}`}</span></td>  */}
                          <td className="text-end">
                            <span
                              className={`
      ${log.statusClass} 
      px-24 py-4 rounded-pill fw-medium text-lg fw-bold 
      ${log.type === "add" ? "text-success-600" : "text-danger-600"}
    `}
                            >
                              {log.type === "add" ? `+${log.value.toFixed(2)}` : `-${log.value.toFixed(2)}`}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={(5)}><div className="d-flex align-items-center justify-content-end gap-3 fssetNAP px-24 py-10">Net Amount <span className="NAPrice">₹{netTotal.toFixed(2)}</span></div></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OADAndCMDList