import React from 'react'
import { formatDate } from 'src/utils/utils';
const ViewInvoiceDetailsModal = ({ invoiceData,selectedStatus }) => {

  return (
    <div>
      <div className="modal fade cusmodl LeaveDetailsModal" id="viewInvoiceListModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between border-0">
              <h6 className='modal-title'>Invoice Details</h6>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
            <div className="modal-body py-0">
              <div className="table-container1">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr>
                      <th className="w-32-px">S.No.</th>
                      <th>Path</th>
                      <th>Qty.</th>
                      <th>Rate.</th>
                      <th>Amount</th>
                      <th>Tax Amount</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.invoiceDetails?.map((invoiceDT, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td className="w-32-px">{invoiceDT.deliveryDetails[0].productionSlug}</td>
                        <td>{invoiceDT.paidUnit}</td>
                        <td>{invoiceDT.perUnitCost}</td>
                        <td>{(invoiceDT.amount-invoiceDT.taxAmount)}</td>
                        <td >{invoiceDT.taxAmount}</td> 
                        <td >{invoiceDT.amount}</td> 
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewInvoiceDetailsModal
