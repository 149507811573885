import { combineReducers } from "redux";
import HomeReducer from './containers/home-container/reducer/HomeReducer';
import authReducer from "./containers/home-container/reducer/AuthReducer"
// import AccountReducer  from "./containers/account-container/reducer/AccountReducer"
import ClientReducer from './containers/client-container/reducer/ClientReducer';
import ProductionReducer from './containers/production-container/reducer/ProductionReducer';
import ProjectReducer from './containers/project-container/reducer/ProjectReducer';
import TaskReducer from './containers/project-container/Task/reducer/TaskReducer';
import ModuleReducer from './containers/project-container/Module/reducer/ModuleReducer';
import ClientProductionReducer from "./containers/project-container/View/ClientDeliveryWise/reducer/ClientProductionReducer";
import PermissionsMasterReducer from "./containers/permission-container/reducer/PermissionsMasterReducer";
import RolePermissionsReducer from "./containers/permission-container/reducer/RolePermissionsReducer";
import EmployeeDataReducer from "./containers/employee-container/Data/reducer/EmployeeDataReducer";
import DashboardReducer from "./containers/dashboard-container/reducer/DashboardReducer";
import FeedbackReducer from "./containers/feedback-container/reducer/FeedbackReducer";
import PerfomanceReducer from "./containers/employee-container/Perfomance/reducer/PerfomanceReducer";
import VendorReducer from "./containers/vendor-container/reducer/VendorReducer";
import SalaryReducer from "./containers/salary-container/reducer/SalaryReducer";
import ClientInvoiceReducer from "./containers/client-invoice-container/reducer/ClientInvoiceReducer";
import VendorDeliveryReducer from "./containers/project-container/View/VendorDelivery/reducer/VendorDeliveryReducer";
import ClientPaymentDetailsReducer from "./containers/client-invoice-container/reducer/ClientPaymentDetailsReducer";


export default combineReducers({
    HomeReducer,
    auth: authReducer,
    EmployeeDataReducer,
    // AccountReducer,
    ClientReducer,
    ProjectReducer,
    TaskReducer,
    ModuleReducer,
    ProductionReducer,
    ClientProductionReducer,
    PermissionsMasterReducer,
    RolePermissionsReducer,
    FeedbackReducer,
    ClientInvoiceReducer,
    ClientPaymentDetailsReducer,
    DashboardReducer,
    PerfomanceReducer,
    VendorReducer,
    SalaryReducer,
    VendorDeliveryReducer
});
