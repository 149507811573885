import React from 'react';
import { Formik, Form, Field } from 'formik'; // Ensure these are correctly imported
import * as Yup from 'yup';
import Select from 'react-select'; // Ensure this is imported properly

// Custom Formik Select component
const CustomFormikSelect = ({ items, name, placeholder, onChange, ...props }) => {
    const CustomSelect = ({ field, form }) => {
        const handleChange = (selectedOption) => {
            const value = selectedOption ? selectedOption.value : null;
            form.setFieldValue(field.name, value);
            if (onChange) {
                onChange(selectedOption, form.setFieldValue);
            }
        };

        return (
            <Select
                options={items}
                name={name}
                placeholder={placeholder}
                value={items.find(option => option.value === field.value) || null}
                onChange={handleChange}
                isSearchable // Enable search
                {...props}
            />
        );
    };

    return <Field name={name} component={CustomSelect} />;
};

const SelectedProject = () => {
    // Options for the dropdown
    const projectOptions = [
        { value: 'project1', label: 'Project 1' },
        { value: 'project2', label: 'Project 2' },
        { value: 'project3', label: 'Project 3 ' },
        { value: 'project4', label: 'Project 4' },
        { value: 'project5', label: 'Project 5' },
        { value: 'project6', label: 'Project 6' },
        { value: 'project7', label: 'Project 7' },
        { value: 'project8', label: 'Project 8' },
        { value: 'project9', label: 'Project 9' },
    ];

    // Initial form values
    const initialValues = {
        projectId: '',
    };

    // Form validation schema
    const validationSchema = Yup.object({
        projectId: Yup.string().required('Please select a project'),
    });

    // Handle dropdown selection
    const handleProject = (selectedOption, setFieldValue) => {
        console.log('Selected Project:', selectedOption);
    };

    return (
        <div className="w-240-px">
           
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log('Form Submitted:', values);
                }}
            >
                {({ setFieldValue, errors, touched }) => (
                    <Form>
                        {/* Select Project Dropdown */}
                        <div className=""> 
                            <CustomFormikSelect
                                items={projectOptions}
                                name="projectId"
                                placeholder="Search Project"
                                onChange={(param) => {
                                    handleProject(param?.value || param, setFieldValue);
                                }}
                            />
                            {errors.projectId && touched.projectId && (
                                <div className="text-danger mt-2">{errors.projectId}</div>
                            )}
                        </div> 
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SelectedProject