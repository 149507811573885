import React, { useEffect, useRef, useState } from 'react';
import { getInitials, truncateNotes } from 'src/utils/CommonUtils';

const CombinedInvoice = ({ row }) => {
  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code End

  const data = [
    {
      description: 'Alt Text - easy Budget constraints limit scope.',
      unitCost: '₹61.00',
      qty: 30,
      baseAmount: '₹61740.00',
    },
    {
      description: 'Alt-Text complex project must adhere to the provided timeline.',
      unitCost: '₹61.00',
      qty: 30,
      baseAmount: '₹61740.00',
    },
    {
      description: 'Alt Text - easy',
      unitCost: '₹61.00',
      qty: 30,
      baseAmount: '₹61740.00',
    },
    {
      description: 'Alt-Text complex',
      unitCost: '₹61.00',
      qty: 30,
      baseAmount: '₹61740.00',
    },
  ];

  return (
    <div>
      <div
        className="modal fade pcdModal producSecMAin cusmodl"
        id="ViewDetails"
        tabIndex={-1}
        aria-labelledby="ViewDetails"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header pb-10 border-bottom-0">
              <div className="" id="addClientDeliverySec">
                <h6 className="modal-title">Vendor/Freelancer Invoice Details</h6>
                <div className="user-cell fw-bold pt-10">
                <span className="user-initials">{getInitials(row?.vendor)}</span>
                {truncateNotes(row?.vendor)}
              </div>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ✗
              </button>
            </div>

            <div className="modal-body pb-0"> 
              <h6 className="text-lg mb-0">PROJECT 352 A</h6>
              <div
                className={`table-body-scrollable mt-16 ${isScrollable ? 'scrollable' : ''}`}
                style={{ minHeight: '250px' }}
              >
                <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Description of Services</th>
                      <th>Currency</th>
                      <th>UOM</th>
                      <th>Unit Cost</th>
                      <th>Qty.</th>
                      <th>Base Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="mx-set-w200">{row.description}</td>
                        <td>USD</td>
                        <td>Per Page</td>
                        <td className="text-end">{row.unitCost}</td>
                        <td className="text-end">{row.qty}</td>
                        <td className="text-end text-primary-700">{row.baseAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={6} className="text-end text-neutral-700">
                        Total Base Amount
                      </td>
                      <th className="text-end text-neutral-700">₹61740.00</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedInvoice;
