import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const ReportTab = () => {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center border-bottom  pb-16 position-relative z-3 pt-24 bg-white">
                <div className="">
                    <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                        Reports
                    </h2>
                    <ul className="d-flex align-items-center gap-2">
                        <li className="fw-medium">
                            <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                                <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                                Special Access
                            </a>
                        </li>
                        <li>-</li>
                        <li className="fw-medium"> <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                            <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                            My Clients
                        </a> </li>
                        <li>-</li>
                        <li className="fw-medium">View Report </li>
                    </ul>
                </div>
            </div>
            <div className="tabsAllPages">
                <nav className="tabs">
                    <ul className="-primary nav my-16 justify-content-start">
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/report/accounts">Accounts</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/report/sales">Sales</NavLink>
                        </li>
                        <li>
                            <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/report/operations">Operations</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default ReportTab